import React, { Component } from "react";
import { connect } from "react-redux";

class PaymentSuccess extends Component {
  render() {
    return (
      <div className="main-wrapper xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="payment-success">
                <img
                  src={window.location.origin + "/assets/img/payment-success.svg"}
                  className="full-img"
                />
                <h3 className="no-result-found-txt">Payment Successfuly Done</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentSuccess;

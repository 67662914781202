import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./BillingAccount.css";
import {
  deleteBankAccountStart,
  makeDefaultBankAccountStart,
} from "../../../store/actions/BankAccountAction";
class BillingAccount extends Component {
  state = {
    account: {},
    loading: true,
  };

  componentDidMount() {
    if (this.props.location.state != null) {
      this.setState({ account: this.props.location.state, loading: false });
    } else {
      this.props.history.push("/bulling-account-list");
    }
  }

  deleteAccount = (event, account) => {
    event.preventDefault();
    this.props.dispatch(
      deleteBankAccountStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
  };

  makeDefaultAccount = (event, account) => {
    event.preventDefault();
    this.props.dispatch(
      makeDefaultBankAccountStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
  };

  render() {
    const { loading, account } = this.state;
    if (loading) {
      return "Loading...";
    } else {
      return (
        <div className="main-wrapper xs-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="main-title">Billing Account</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="billing-box">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="billing-accout-img-sec">
                        <img
                          src={
                            window.location.origin +
                            "/assets/img/billing-account.svg"
                          }
                          className="full-img billing-account-img"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="billing-action-menu-sec">
                        <ul className="list-unstyled billing-action-menu">
                          <li>
                            <Link
                              to={{
                                pathname: `/edit-billing-account/${account.user_billing_account_unique_id}`,
                                state: account,
                              }}
                            >
                              <i class="icofont-edit mr-2"></i> Edit
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              onClick={(event) =>
                                this.deleteAccount(event, account)
                              }
                            >
                              <i class="icofont-ui-delete mr-2"></i> Delete
                            </Link>
                          </li>
                          <li>
                            {account.is_default == 1 ? (
                              <>
                                <i class="icofont-check-circled mr-2"></i>
                                Default
                              </>
                            ) : (
                              <Link
                                to="#"
                                onClick={(event) =>
                                  this.makeDefaultAccount(event, account)
                                }
                              >
                                <i class="icofont-check-circled mr-2"></i>Make
                                Default
                              </Link>
                            )}
                          </li>
                        </ul>
                      </div>
                      <div class="billing-info">
                        <div class="row">
                          <div class="col-md-4">
                            <h5 class="">Name</h5>
                          </div>
                          <div class="col-md-8">
                            <h6>
                              <span class="p-dots">:</span>
                              {account.account_holder_name}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="billing-info">
                        <div class="row">
                          <div class="col-md-4">
                            <h5 class="">Account Number</h5>
                          </div>
                          <div class="col-md-8">
                            <h6>
                              <span class="p-dots">:</span>
                              {account.account_number}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="billing-info">
                        <div class="row">
                          <div class="col-md-4">
                            <h5 class="">Branch Name</h5>
                          </div>
                          <div class="col-md-8">
                            <h6>
                              <span class="p-dots">:</span>
                              {account.title}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="billing-info">
                        <div class="row">
                          <div class="col-md-4">
                            <h5 class="">IFSC Code</h5>
                          </div>
                          <div class="col-md-8">
                            <h6>
                              <span class="p-dots">:</span>
                              {account.ifsc_code}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-center"></div>
          </div>
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(BillingAccount);

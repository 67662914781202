import {
  FETCH_DISPUTES_START,
  FETCH_DISPUTES_SUCCESS,
  FETCH_DISPUTES_FAILURE,
  SEND_DISPUTE_REQUEST_START,
  SEND_DISPUTE_REQUEST_SUCCESS,
  SEND_DISPUTE_REQUEST_FAILURE,
  CANCEL_DISPUTE_REQUEST_START,
  CANCEL_DISPUTE_REQUEST_SUCCESS,
  CANCEL_DISPUTE_REQUEST_FAILURE,
  FETCH_SINGLE_DISPUTE_START,
  FETCH_SINGLE_DISPUTE_SUCCESS,
  FETCH_SINGLE_DISPUTE_FAILURE,
  FETCH_CLOSED_CASE_START,
  FETCH_CLOSED_CASE_SUCCESS,
  FETCH_CLOSED_CASE_FAILURE,
  FETCH_OPENED_CASE_START,
  FETCH_OPENED_CASE_SUCCESS,
  FETCH_OPENED_CASE_FAILURE,
  SEND_DISPUTE_MESSAGE_START,
  SEND_DISPUTE_MESSAGE_SUCCESS,
  SEND_DISPUTE_MESSAGE_FAILURE,
  GET_SINGLE_DISPUTE_MESSAGES_START,
  GET_SINGLE_DISPUTE_MESSAGES_SUCCESS,
  GET_SINGLE_DISPUTE_MESSAGES_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  disputes: {
    data: {},
    loading: true,
    error: false,
  },
  openedCases: {
    data: {},
    loading: true,
    error: false,
  },
  getMsges: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  sendMsg: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  sendDispute: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  cancelDispute: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  singleDispute: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  closedCase: {
    data: {},
    loading: true,
    error: false,
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const DisputeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DISPUTES_START:
      return {
        ...state,
        disputes: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_DISPUTES_SUCCESS:
      return {
        ...state,
        disputes: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_DISPUTES_FAILURE:
      return {
        ...state,
        disputes: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case SEND_DISPUTE_REQUEST_START:
      return {
        ...state,
        sendDispute: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case SEND_DISPUTE_REQUEST_SUCCESS:
      return {
        ...state,
        sendDispute: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case SEND_DISPUTE_REQUEST_FAILURE:
      return {
        ...state,
        sendDispute: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case CANCEL_DISPUTE_REQUEST_START:
      return {
        ...state,
        cancelDispute: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case CANCEL_DISPUTE_REQUEST_SUCCESS:
      return {
        ...state,
        cancelDispute: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case CANCEL_DISPUTE_REQUEST_FAILURE:
      return {
        ...state,
        cancelDispute: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case FETCH_SINGLE_DISPUTE_START:
      return {
        ...state,
        singleDispute: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_DISPUTE_SUCCESS:
      return {
        ...state,
        singleDispute: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_DISPUTE_FAILURE:
      return {
        ...state,
        singleDispute: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_CLOSED_CASE_START:
      return {
        ...state,
        closedCase: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CLOSED_CASE_SUCCESS:
      return {
        ...state,
        closedCase: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_CLOSED_CASE_FAILURE:
      return {
        ...state,
        closedCase: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_OPENED_CASE_START:
      return {
        ...state,
        openedCases: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_OPENED_CASE_SUCCESS:
      return {
        ...state,
        openedCases: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_OPENED_CASE_FAILURE:
      return {
        ...state,
        openedCases: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case SEND_DISPUTE_MESSAGE_START:
      return {
        ...state,
        sendMsg: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case SEND_DISPUTE_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMsg: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case SEND_DISPUTE_MESSAGE_FAILURE:
      return {
        ...state,
        sendMsg: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case GET_SINGLE_DISPUTE_MESSAGES_START:
      return {
        ...state,
        getMsges: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case GET_SINGLE_DISPUTE_MESSAGES_SUCCESS:
      return {
        ...state,
        getMsges: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_SINGLE_DISPUTE_MESSAGES_FAILURE:
      return {
        ...state,
        getMsges: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default DisputeReducer;

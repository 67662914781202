import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";
import {
  CREATE_PAYMENT_REQUEST_START,
  CREATE_PAYMENT_REQUEST_CALLBACK_START,
  FETCH_COUNTRY_LIST_START,
  FETCH_CURRENCY_LIST_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  createPaymentRequestSuccess,
  createPaymentRequestFailure,
  paymentCallbackSuccess,
  paymentCallbackFailure,
  fetchCountryDetailsSuccess,
  fetchCountryDetailsFailure,
  fetchCurrencyDetailsSuccess,
  fetchCurrencyDetailsFailure,
} from "../actions/HTPAction";


function* createPaymentRequestAPI() {
  try {
    const inputData = yield select((state) => state.htp.paymentData.data);

    const response = yield api.postMethod(
      "wallets_create_payment_request",
      inputData
    );
     
    if (response.data.success) {
      yield put(createPaymentRequestSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.data.data.create_payment.message
      );
      yield put(createNotification(notificationMessage));
    } 
  } catch (error) {

    yield put(createPaymentRequestFailure(error));
    const notificationMessage = getErrorNotificationMessage('response.message');
    yield put(createNotification(notificationMessage));
  }
}

function* paymentCallbackAPI(request) {
  try {

    console.log('iiii')
    const inputData = yield select((state) => state.htp.paymentData.data);

     const response = yield api.postMethod("create_payment_callback",inputData);
    console.log(response);

    //const inputData = yield select((state) => state.htp.paymentData.data);

    // const response = yield api.postMethod(
    //   "create_payment_callback",
    //   inputData
    // );
     
    if (response.data.success) {
      yield put(paymentCallbackSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.data.data.create_payment.message
      );
      yield put(createNotification(notificationMessage));
    } 
  } catch (error) {

    yield put(paymentCallbackFailure(error));
    const notificationMessage = getErrorNotificationMessage('response.message');
    yield put(createNotification(notificationMessage));
  }
}

function* getCountryDetailsAPI() {
  try {
    const response = yield api.getMethod("get_country_list");
    if (response) {
      yield put(fetchCountryDetailsSuccess(response));
    } else {
      yield put(fetchCountryDetailsFailure("Data Not Found."));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCountryDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
    
  }
}

export default function* HTPSaga() {
  yield all([
    yield takeLatest(CREATE_PAYMENT_REQUEST_START, createPaymentRequestAPI),
    yield takeLatest(CREATE_PAYMENT_REQUEST_CALLBACK_START, paymentCallbackAPI),
    yield takeLatest(FETCH_COUNTRY_LIST_START, getCountryDetailsAPI),
  ]);
}
import React from "react";
import { connect } from "react-redux";
import EmptyData from "../Handler/EmptyData";

const $ = window.$;

const WalletAccountSec = (props) => {
  const user_wallet  = props.wallet.walletData.data.user_wallet;
  return (
    <div className="col-md-12 full-mg-width res-md-mrg-btm text-align-left pb-5">
      <div className="w-box">
      <h5>My Wallet</h5>
      {props.wallet.walletData.loading ? (
          "Loading..."
        ) : user_wallet.length > 0 ? (
          <table className="table table-striped mb-5 tb_wallet_account">
            <tbody>
              {user_wallet.map((data) => (
                <tr>
                  <td className="col-md-4 vertical-align-middle"><span>{data.currency_name}<h3>{data.remaining}</h3></span></td>
                  <td className="col-md-4 vertical-align-middle text-align-center">
                    <div className="d-flex justify-content-center vertical-align-middle text-align-right" >
                      <a
                        href="#"
                        className="btn btn-teritory btn-sm mr-3 pl-3 pr-3 pb-2 pt-2"
                        data-toggle="modal"
                        data-target="#add-money-modal"
                        data-currency_code = {data.currency_code}
                        data-user_wallet_id={data.user_wallet_id}
                      >
                       <small>Add Money</small> 
                      </a>
                      <a
                        href="#"
                        className="btn btn-quaternary btn-sm pl-3 pr-3 pb-2 pt-2"
                        data-toggle="modal"
                        data-target="#withdraw-modal"
                        data-currency_code = {data.currency_code}
                        data-user_wallet_id={data.user_wallet_id}
                      >
                        <small>Withdraw Money</small>
                      </a>
                    </div>
                      
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ):(
          <EmptyData />
      )}
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletAccountSec);

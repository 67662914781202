import {
  SAVE_INVOICE_DETAILS_START,
  SAVE_INVOICE_DETAILS_SUCCESS,
  SAVE_INVOICE_DETAILS_FAILURE,
  FETCH_SINGLE_INVOICE_DETAILS_START,
  FETCH_SINGLE_INVOICE_DETAILS_SUCCESS,
  FETCH_SINGLE_INVOICE_DETAILS_FAILURE,
  SAVE_INVOICE_ITEMS_START,
  SAVE_INVOICE_ITEMS_SUCCESS,
  SAVE_INVOICE_ITEMS_FAILURE,
  DELETE_INVOICE_ITEMS_START,
  DELETE_INVOICE_ITEMS_SUCCESS,
  DELETE_INVOICE_ITEMS_FAILURE,
  SEND_INVOICE_START,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_FAILURE,
  FETCH_INVOICE_ITEMS_START,
  FETCH_INVOICE_ITEMS_SUCCESS,
  FETCH_INVOICE_ITEMS_FAILURE,
  UPDATE_INVOICE_ITEM,
  SAVE_INVOICE_ID,
} from "../actions/ActionConstant";

const initialState = {
  invoiceInput: {
    data: {},
    loading: true,
    error: false,
  },
  invoiceDetails: {
    data: {},
    loading: true,
    error: false,
  },
  singleInvoiceDetails: {
    data: {},
    invoiceId: null,
    loading: true,
    error: false,
    buttonDisable: false,
    buttonLoadingContent: null,
  },
  invoiceItemInput: {
    data: {},
    loading: true,
    error: false,
    success: {},
  },
  invoiceItemList: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  invoiceItemsArray: [],
  sendInvoice: {
    data: {},
    loading: true,
    error: false,
    success: {},
    invoiceId: null,
    buttonDisable: false,
    buttonLoadingContent: null,
  },
  deleteInvoiceItem: {
    data: {},
    loading: true,
    error: false,
    success: {},
    deleteInput: null,
  },
  buttonDisable: false,
  loadingButtonContent: null,
  generatedInvoiceId: null,
};

const CreateInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_INVOICE_DETAILS_START:
      return {
        ...state,
        invoiceInput: {
          data: action.data,
          loading: true,
          error: false,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case SAVE_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        invoiceInput: {
          data: action.data,
          loading: false,
          error: false,
        },
        invoiceDetails: {
          data: action.data,
          loading: false,
          error: false,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case SAVE_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        invoiceInput: {
          data: {},
          loading: true,
          error: action.error,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case FETCH_SINGLE_INVOICE_DETAILS_START:
      return {
        ...state,
        singleInvoiceDetails: {
          invoiceId: action.data,
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          buttonLoadingContent: "Loading...",
        },
      };
    case FETCH_SINGLE_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        singleInvoiceDetails: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          buttonLoadingContent: null,
        },
      };
    case FETCH_SINGLE_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        singleInvoiceDetails: {
          invoiceId: null,
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          buttonLoadingContent: null,
        },
      };
    case UPDATE_INVOICE_ITEM:
      return {
        ...state,
        invoiceItemsArray: [
          (state.invoiceItemsArray[action.index] = action.data),
        ],
      };
    case SAVE_INVOICE_ITEMS_START:
      return {
        ...state,
        invoiceItemInput: {
          data: action.data,
          loading: true,
          error: false,
        },
      };
    case SAVE_INVOICE_ITEMS_SUCCESS:
      let array = [...state.invoiceItemsArray]; // make a separate copy of the array
      let index = array.indexOf(action.data.data);
      if (index !== -1) {
        array.splice(index, 1);
        return {
          ...state,
          invoiceItemsArray: [array, action.data.data],
          invoiceItemInput: {
            data: {},
            loading: false,
            error: false,
            success: action.data,
          },
        };
      } else {
        return {
          ...state,
          invoiceItemsArray: [...state.invoiceItemsArray, action.data.data],
          invoiceItemInput: {
            data: {},
            loading: false,
            error: false,
            success: action.data,
          },
        };
      }
    case SAVE_INVOICE_ITEMS_FAILURE:
      return {
        ...state,
        invoiceItemInput: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case DELETE_INVOICE_ITEMS_START:
      return {
        ...state,
        deleteInvoiceItem: {
          deleteInput: action.data,
          data: {},
          loading: true,
          error: false,
        },
      };
    case DELETE_INVOICE_ITEMS_SUCCESS:
      return {
        ...state,
        deleteInvoiceItem: {
          data: action.data,
          loading: false,
          error: false,
          deleteInput: null,
        },
      };
    case DELETE_INVOICE_ITEMS_FAILURE:
      return {
        ...state,
        deleteInvoiceItem: {
          deleteInput: null,
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_INVOICE_ITEMS_START:
      return {
        ...state,
        invoiceItemList: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
        },
        itemTotal: 0,
      };
    case FETCH_INVOICE_ITEMS_SUCCESS:
      return {
        ...state,
        invoiceItemList: {
          data: action.data,
          loading: false,
          error: false,
          inputData: null,
        },
      };
    case FETCH_INVOICE_ITEMS_FAILURE:
      return {
        ...state,
        invoiceItemList: {
          inputData: null,
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case SEND_INVOICE_START:
      return {
        ...state,
        sendInvoice: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          buttonLoadingContent: "Loading...",
          success: {},
        },
      };
    case SEND_INVOICE_SUCCESS:
      return {
        ...state,
        sendInvoice: {
          data: {},
          loading: false,
          error: false,
          success: action.data,
          buttonDisable: false,
          buttonLoadingContent: null,
        },
      };
    case SEND_INVOICE_FAILURE:
      return {
        ...state,
        sendInvoice: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          buttonLoadingContent: null,
          success: {},
        },
      };
    case SAVE_INVOICE_ID:
      return {
        ...state,
        generatedInvoiceId: action.data,
      };
    default:
      return state;
  }
};

export default CreateInvoiceReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteAccountStart } from "../../../store/actions/UserAction";

class DeleteAccountIndex extends Component {
  state = {
    password: "",
  };
  handleChange = ({ currentTarget: input }) => {
    this.setState({ password: input.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(deleteAccountStart({ password: this.state.password }));
  };
  render() {
    return (
      <div className="main-wrapper delete-account xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Delete Account</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="delete-account-box">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <form className="theme-form dl-box" autoComplete="off">
                          <h2 className="sub-title">Sorry to see you go</h2>

                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label>Enter Your Password</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={this.state.password}
                                className="form-control"
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-8 resp-align-center">
                              <button
                                type="submit"
                                className="btn btn-quaternary"
                                onClick={this.handleSubmit}
                                disabled={this.props.users.buttonDisable}
                              >
                                {this.props.users.loadingButtonContent != null
                                  ? this.props.users.loadingButtonContent
                                  : "Delete Account"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      src={
                        window.location.origin + "/assets/img/send-money.svg"
                      }
                      className="full-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-center space-center-1"></div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  users: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteAccountIndex);

import React from "react";
import ContentLoader from "react-content-loader";

const MainLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1320}
    height={1320}
    viewBox="0 0 1000 1000"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="300" y="96" rx="10" ry="10" width="320" height="186"></rect>
    <rect x="680" y="96" rx="10" ry="10" width="320" height="186"></rect>
    <rect x="300" y="0" rx="10" ry="10" width="1000" height="50"></rect>
    <rect x="300" y="330" rx="10" ry="10" width="1000" height="60"></rect>
    <rect x="300" y="430" rx="10" ry="10" width="1000" height="77"></rect>
    <rect x="300" y="550" rx="10" ry="10" width="1000" height="77"></rect>
    <rect x="0" y="0" rx="10" ry="10" width="280" height="750"></rect>
  </ContentLoader>
);

export default MainLoader;

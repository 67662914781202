import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { addMoneyViaHTPStart} from "../../store/actions/WalletAction";
import { createPaymentRequestStart } from "../../store/actions/HTPAction";


const $ = window.$;

const AddMoneyModel = (props) => {


  useEffect(() => {
    if (props.htp.loading) props.dispatch(createPaymentRequestStart());
      $('#add-money-modal').on('show.bs.modal', function(event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        var currency_code = button.data('currency_code')
        setInputData((inputData) => ({
          ...inputData,
          ['currency_code']: currency_code,
        }));
      })
  }, []);

  const [amount, setAmount] = useState(0);

  const [inputData, setInputData, paymentData] = useState({});

  const handleChange = (event) => {
    event.persist();
    setAmount(event.target.value);
    setInputData((inputData) => ({
      ...inputData,
      [event.target.name]: event.target.value,
    }));
  };

  const [show, setShow] = useState(true)

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(createPaymentRequestStart(inputData));
    $("#add-money-modal").modal("hide");
  };

  return (
    <div
      className="modal fade"
      id="add-money-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addmoney-modaltitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addmoney-modaltitle">
              Add Money
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            className="theme-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="modal-body">
              <p className="text-muted">
                Min add money limit is:{" "}
                <b>
                  {configuration.get("configData.user_withdrawals_min_amount") + ' ' + inputData.currency_code}
                </b>
              </p>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">{inputData.currency_code}</span>
                </div>
                <input
                  type="text"
                  className="form-control input-form-1"
                  placeholder="0.00"
                  aria-label="Enter Amount"
                  name="amount"
                  value={inputData.amount}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-quaternary width-120"
                disabled={props.wallet.addMoneyInput.buttonDisable}
                onClick={handleSubmit}
              >
                {props.wallet.addMoneyInput.loadingButtonContent != null
                  ? props.wallet.addMoneyInput.loadingButtonContent
                  : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet,
  htp: state.htp,
  userData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddMoneyModel);

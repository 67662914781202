import {
  FETCH_INVOICE_HISTORY_START,
  FETCH_INVOICE_HISTORY_SUCCESS,
  FETCH_INVOICE_HISTORY_FAILURE,
  FETCH_INVOICE_UNPAID_START,
  FETCH_INVOICE_UNPAID_SUCCESS,
  FETCH_INVOICE_UNPAID_FAILURE,
  FETCH_INVOICE_PAID_START,
  FETCH_INVOICE_PAID_SUCCESS,
  FETCH_INVOICE_PAID_FAILURE,
  FETCH_INVOICE_DRAFT_START,
  FETCH_INVOICE_DRAFT_SUCCESS,
  FETCH_INVOICE_DRAFT_FAILURE,
  FETCH_INVOICE_RECEIVED_START,
  FETCH_INVOICE_RECEIVED_SUCCESS,
  FETCH_INVOICE_RECEIVED_FAILURE,
  INVOICE_PAYNOW_START,
  INVOICE_PAYNOW_SUCCESS,
  INVOICE_PAYNOW_FAILURE,
} from "./ActionConstant";

// Get Wallet Details actions.

export function fetchInvoiceHistoryStart(data) {
  return {
    type: FETCH_INVOICE_HISTORY_START,
    data,
  };
}

export function fetchInvoiceHistorySuccess(data) {
  return {
    type: FETCH_INVOICE_HISTORY_SUCCESS,
    data,
  };
}

export function fetchInvoiceHistoryFailure(error) {
  return {
    type: FETCH_INVOICE_HISTORY_FAILURE,
    error,
  };
}
export function fetchInvoiceDraftStart(data) {
  return {
    type: FETCH_INVOICE_DRAFT_START,
    data,
  };
}

export function fetchInvoiceDraftSuccess(data) {
  return {
    type: FETCH_INVOICE_DRAFT_SUCCESS,
    data,
  };
}

export function fetchInvoiceDraftFailure(error) {
  return {
    type: FETCH_INVOICE_DRAFT_FAILURE,
    error,
  };
}
export function fetchInvoiceUnpaidStart(data) {
  return {
    type: FETCH_INVOICE_UNPAID_START,
    data,
  };
}

export function fetchInvoiceUnpaidSuccess(data) {
  return {
    type: FETCH_INVOICE_UNPAID_SUCCESS,
    data,
  };
}

export function fetchInvoiceUnpaidFailure(error) {
  return {
    type: FETCH_INVOICE_UNPAID_FAILURE,
    error,
  };
}
export function fetchInvoicePaidStart(data) {
  return {
    type: FETCH_INVOICE_PAID_START,
    data,
  };
}

export function fetchInvoicePaidSuccess(data) {
  return {
    type: FETCH_INVOICE_PAID_SUCCESS,
    data,
  };
}

export function fetchInvoicePaidFailure(error) {
  return {
    type: FETCH_INVOICE_PAID_FAILURE,
    error,
  };
}
export function fetchInvoiceReceivedStart(data) {
  return {
    type: FETCH_INVOICE_RECEIVED_START,
    data,
  };
}

export function fetchInvoiceReceivedSuccess(data) {
  return {
    type: FETCH_INVOICE_RECEIVED_SUCCESS,
    data,
  };
}

export function fetchInvoiceReceivedFailure(error) {
  return {
    type: FETCH_INVOICE_RECEIVED_FAILURE,
    error,
  };
}

export function invoicePayNowStart(data) {
  return {
    type: INVOICE_PAYNOW_START,
    data,
  };
}

export function invoicePayNowSuccess(data) {
  return {
    type: INVOICE_PAYNOW_SUCCESS,
    data,
  };
}

export function invoicePayNowFailure(error) {
  return {
    type: INVOICE_PAYNOW_FAILURE,
    error,
  };
}

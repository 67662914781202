import React, { Component } from "react";
import BusinessDetailsModel from "../CreateInvoice/BusinessDetailsModal";
import { connect } from "react-redux";
import CreateInvoiceTableSec from "../CreateInvoice/CreateInvoiceTableSec";
import { saveBusinessDetailsStart } from "../../../store/actions/BusinessDetailsAction";
import CreateInvoiceRightSec from "../CreateInvoice/CreateInvoiceRightSec";
import {
  saveInvoiceDetailsStart,
  saveInvoiceItemsStart,
  updateInvoiceItemsArray,
  deleteInvoiceItemsStart,
  fetchSingleInvoiceDetailsStart,
} from "../../../store/actions/CreateInvoiceAction";
import { Prompt } from "react-router";
import { Beforeunload } from "react-beforeunload";
import { Link } from "react-router-dom";

const $ = window.$;

class EditInvoiceIndex extends Component {
  state = {
    businessDetailsInput: {},
    invoiceInput: {},
    imagePreviewUrl: null,
    invoiceItemsArray: {},
    invoiceItemInput: {
      name: "",
      amount: "",
      quantity: "",
      generated_invoice_id: "",
    },
    tableLength: 1,
    updatedItemInput: {},
    selectedItem: null,
    selectedItemInput: {
      name: "",
      amount: "",
      quantity: "",
      generated_invoice_item_id: "",
    },
  };

  componentDidMount() {
    this.props.dispatch(
      fetchSingleInvoiceDetailsStart({
        generated_invoice_id: this.props.match.params.id,
      })
    );
  }

  getBusinessDetailsData = ({ currentTarget: input }) => {
    const businessDetailsInput = { ...this.state.businessDetailsInput };
    businessDetailsInput[input.name] = input.value;
    this.setState({ businessDetailsInput });
  };

  saveBusinessDetails = (event) => {
    event.preventDefault();
    const businessDetailsInput = { ...this.state.businessDetailsInput };
    if (this.props.createInvoice.generatedInvoiceId != null) {
      businessDetailsInput[
        "generated_invoice_id"
      ] = this.props.createInvoice.generatedInvoiceId;
      this.setState({ businessDetailsInput });
    }
    setTimeout(() => {
      this.props.dispatch(
        saveBusinessDetailsStart(this.state.businessDetailsInput)
      );
    }, 500);

    $(".edit-business-modal").modal("hide");
  };

  getInvoiceInput = ({ currentTarget: input }) => {
    const invoiceInput = { ...this.state.invoiceInput };
    invoiceInput[input.name] = input.value;
    this.setState({ invoiceInput });
  };

  handleChangeImage = ({ currentTarget: input }) => {
    const invoiceInput = { ...this.state.invoiceInput };
    if (input.type === "file") {
      invoiceInput[input.name] = input.files[0];
      this.setState({ invoiceInput });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "logo")
        this.setState({
          imagePreviewUrl: reader.result,
        });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  saveInvoiceDetails = (event) => {
    event.preventDefault();

    if (this.props.createInvoice.generatedInvoiceId != null) {
      const invoiceInput = { ...this.state.invoiceInput };
      invoiceInput[
        "generated_invoice_id"
      ] = this.props.createInvoice.generatedInvoiceId;
      this.setState({
        invoiceInput,
      });
    }

    setTimeout(() => {
      this.props.dispatch(saveInvoiceDetailsStart(this.state.invoiceInput));
    }, 500);
  };

  increaseTableLength = (event) => {
    event.preventDefault();

    this.props.dispatch(saveInvoiceItemsStart(this.state.invoiceItemInput));

    setTimeout(() => {
      this.setState({
        tableLength: this.state.tableLength + 1,
        invoiceItemInput: {
          quantity: "",
          name: "",
          amount: "",
          generated_invoice_id: "",
        },
      });
    }, 1000);
  };

  getItemsInput = ({ currentTarget: input }) => {
    const invoiceItemInput = { ...this.state.invoiceItemInput };
    console.log("invopice", this.props.createInvoice.generatedInvoiceId);
    if (
      this.props.createInvoice.generatedInvoiceId != null &&
      this.state.invoiceItemInput.generated_invoice_id == ""
    ) {
      console.log("terewrwewerwerewr");
      invoiceItemInput[
        "generated_invoice_id"
      ] = this.props.createInvoice.generatedInvoiceId;
    }
    invoiceItemInput[input.name] = input.value;
    this.setState({ invoiceItemInput });
  };

  editSelectedItem = (event, item) => {
    event.preventDefault();
    this.setState({
      selectedItem: item.generated_invoice_item_id,
      selectedItemInput: {
        name: item.name,
        amount: item.amount,
        quantity: item.quantity,
        generated_invoice_item_id: item.generated_invoice_item_id,
        generated_invoice_id: item.generated_invoice_id,
      },
    });
  };

  handleChangeSelectedItem = ({ currentTarget: input }) => {
    const selectedItemInput = { ...this.state.selectedItemInput };
    selectedItemInput[input.name] = input.value;
    this.setState({ selectedItemInput });
  };

  saveSelectedItem = (event) => {
    event.preventDefault();
    this.props.dispatch(saveInvoiceItemsStart(this.state.selectedItemInput));

    this.setState({
      selectedItem: null,
      selectedItemInput: {
        name: "",
        amount: "",
        quantity: "",
        generated_invoice_item_id: "",
        generated_invoice_id: "",
      },
    });
  };

  deleteSelectedItem = (event, item) => {
    event.preventDefault();
    this.props.dispatch(
      deleteInvoiceItemsStart({
        generated_invoice_item_id: item.generated_invoice_item_id,
      })
    );
  };

  render() {
    return (
      <Beforeunload onBeforeunload={() => "You'll lose your data!"}>
        <div class="main-wrapper invoice xs-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2 class="main-title">Invoices</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="invoice-box">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <a
                        class="nav-item nav-link active"
                        id="nav-create-invoice-tab"
                        data-toggle="tab"
                        href="#nav-create-invoice"
                        role="tab"
                        aria-controls="nav-create-invoice"
                        aria-selected="false"
                      >
                        Create Invoices
                      </a>
                    </div>
                  </nav>
                  <div
                    class="tab-pane fade show active"
                    id="nav-create-invoice"
                    role="tabpanel"
                    aria-labelledby="nav-create-invoice-tab"
                  >
                    <div class="invoice-center-table-header flex-data">
                      <h3 class="title">Create Invoices</h3>
                    </div>

                    <div class="row">
                      <div class="col-md-5">
                        <div class="business-logo">
                          <div class="upload-btn-wrapper">
                            <button class="button">
                              <img
                                src={
                                  this.state.imagePreviewUrl != null
                                    ? this.state.imagePreviewUrl
                                    : window.location.origin +
                                      "/assets/img/business-logo.svg"
                                }
                              />
                              Add your business logo
                            </button>
                            <input
                              type="file"
                              name="logo"
                              accept="image/*"
                              onChange={this.handleChangeImage}
                            />
                          </div>
                        </div>
                        <div class="business-logo">
                          <h4 class="business-text">
                            [+] Your Business Information
                          </h4>
                          <a
                            href="#"
                            class="btn btn-quaternary"
                            data-toggle="modal"
                            data-target=".edit-business-modal"
                            id="edit-business-modal"
                          >
                            Edit
                          </a>
                        </div>
                      </div>

                      <CreateInvoiceRightSec
                        getInvoiceInput={this.getInvoiceInput}
                        invoiceInput={this.state.invoiceInput}
                      />
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="bill-to-customer">
                          <div class="row mb-4">
                            <div class="col-md-1 padding-right-zero">
                              <h4 class="customer-head">Bill to:</h4>
                            </div>
                            <div class="col-md-5 padding-left-zero resp-xs-mrg-btm">
                              <div class="add-customer">
                                <div class="input-group">
                                  <input
                                    type="text"
                                    class="form-control input-form"
                                    placeholder="Email address or Name"
                                    value={
                                      this.state.invoiceInput
                                        .to_user_email_address
                                    }
                                    name="to_user_email_address"
                                    onChange={this.getInvoiceInput}
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text">
                                      <img
                                        src={
                                          window.location.origin +
                                          "/assets/img/people.svg"
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-1 padding-right-zero">
                              <h4 class="customer-head">CC:</h4>
                            </div>
                            <div class="col-md-5 padding-left-zero">
                              <div class="add-customer">
                                <div class="input-group">
                                  <input
                                    type="text"
                                    class="form-control input-form-1"
                                    placeholder="Email address"
                                    value={
                                      this.state.invoiceInput
                                        .cc_user_email_address
                                    }
                                    name="cc_user_email_address"
                                    onChange={this.getInvoiceInput}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 padding-right-zero padding-left-zero"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <CreateInvoiceTableSec
                      tableLength={this.state.tableLength}
                      getItemsInput={this.getItemsInput}
                      invoiceItemInput={this.state.invoiceItemInput}
                      editSelectedItem={this.editSelectedItem}
                      selectedItem={this.state.selectedItem}
                      invoiceItemsArray={
                        this.props.createInvoice.invoiceItemsArray
                      }
                      selectedItemInput={this.state.selectedItemInput}
                      handleChangeSelectedItem={this.handleChangeSelectedItem}
                      saveSelectedItem={this.saveSelectedItem}
                      invoiceItemsList={
                        this.props.createInvoice.invoiceItemList
                      }
                      deleteSelectedItem={this.deleteSelectedItem}
                    />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="text-center">
                          <a
                            href="#"
                            class="btn btn-quaternary"
                            onClick={this.increaseTableLength}
                          >
                            + Add another Item
                          </a>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="total-amount-display">
                          <table class="table table-responsive-sm table-borderless">
                            <tbody>
                              <tr>
                                <td class="desc">Subtotal</td>
                                <td class="desc">$105.00</td>
                              </tr>
                              <tr>
                                <td class="title">Total</td>
                                <td class="title">$105.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="space-mg"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <form class="note-form">
                          <div class="form-group">
                            <label for="note">Note to Recipient</label>
                            <textarea
                              class="form-control"
                              id="note"
                              rows="5"
                              placeholder="For Example “Thank you for the business”"
                              value={this.state.invoiceInput.notes}
                              name="notes"
                              onChange={this.getInvoiceInput}
                            ></textarea>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-6">
                        <form class="note-form">
                          <div class="form-group">
                            <label for="terms">Terms and Conditions</label>
                            <textarea
                              class="form-control"
                              id="terms"
                              rows="5"
                              placeholder="Include your return and cancellation policy"
                              value={this.state.invoiceInput.terms_conditions}
                              name="terms_conditions"
                              onChange={this.getInvoiceInput}
                            ></textarea>
                          </div>
                        </form>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="align-right">
                          <button
                            type="submit"
                            class="btn btn-secondary-1 mr-2 resp-xs-mrg-btm disabled"
                            disabled={this.props.createInvoice.buttonDisable}
                            onClick={this.saveInvoiceDetails}
                          >
                            {this.props.createInvoice.loadingButtonContent !=
                            null
                              ? this.props.createInvoice.loadingButtonContent
                              : "Save as draft"}
                          </button>
                          {this.props.createInvoice.generatedInvoiceId !=
                          null ? (
                            <>
                              <Link
                                to={`/preview-invoice/${this.props.createInvoice.generatedInvoiceId}`}
                                class="btn btn-secondary-1 mr-2"
                              >
                                Preview
                              </Link>
                              <button
                                type="submit"
                                class="btn btn-quaternary"
                                disabled={
                                  this.props.createInvoice.sendInvoice
                                    .buttonDisable
                                }
                              >
                                {this.props.createInvoice.sendInvoice
                                  .buttonLoadingContent != null
                                  ? this.props.createInvoice.sendInvoice
                                      .buttonLoadingContent
                                  : "Send"}
                              </button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-center"></div>
          </div>
          <BusinessDetailsModel
            getBusinessDetailsData={this.getBusinessDetailsData}
            businessDetailsInput={this.state.businessDetailsInput}
            saveBusinessDetails={this.saveBusinessDetails}
            buttonDisable={this.props.businessDetails.buttonDisable}
            loadingButtonContent={
              this.props.businessDetails.loadingButtonContent
            }
          />
        </div>
      </Beforeunload>
    );
  }
}

const mapStateToPros = (state) => ({
  businessDetails: state.businessDetails,
  createInvoice: state.createInvoice,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditInvoiceIndex);

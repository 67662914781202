import React from "react";

const DraftSec = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        {/* <div className="dash-box">
          <div className="dash-info">
            <div className="sub-dash-box">
              <img
                src={
                  window.location.origin +
                  "/assets/img/invoice-violet-icons.svg"
                }
              />
            </div>
            <div className="invoice-details">
              <h5>You have 1 draft invoices</h5>
              <p>Paypal Invoicing</p>
            </div>
          </div>
          <p className="desc">
            Finish and send your draft invoices to get paid fast
          </p>
          <hr />
          <div className="dash-footer">
            <a href="#" className="btn btn-teritory mr-3">
              View Draft Invoices
            </a>
            <a href="#" className="link">
              More<i className="fas fa-chevron-right ml-1"></i>
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DraftSec;

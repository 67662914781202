import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const DashboardMoneySec = (props) => {
  const { walletData } = props.wallet;
  return (
    <div className="col-md-6 resp-sm-mrg-btm">
      <div className="dash-box">
        <div className="dash-header">
          <h3>Money</h3>
          <Link to={"/wallet"} className="link">
            More<i className="fas fa-chevron-right ml-1"></i>
          </Link>
        </div>
        <div className="dash-status">
          <h4>Available</h4>
        </div>
        <div className="dash-amount">
          <h4>
            {walletData.loading
              ? "Loading..."
              : walletData.data.user_wallet.remaining_formatted}
          </h4>
        </div>
        <p className="conv-desc desc">
          * This is an estimate based on the most recent conversion rate.
        </p>
        <a
          className="btn btn-teritory mr-3"
          href="#"
          data-toggle="modal"
          data-target="#withdraw-modal"
        >
          Withdraw Money
        </a>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet,
});

export default connect(mapStateToPros, null)(DashboardMoneySec);

import {
  SEND_DISPUTE_REQUEST_START,
  SEND_DISPUTE_REQUEST_SUCCESS,
  SEND_DISPUTE_REQUEST_FAILURE,
  FETCH_DISPUTES_START,
  FETCH_DISPUTES_SUCCESS,
  FETCH_DISPUTES_FAILURE,
  CANCEL_DISPUTE_REQUEST_START,
  CANCEL_DISPUTE_REQUEST_SUCCESS,
  CANCEL_DISPUTE_REQUEST_FAILURE,
  FETCH_SINGLE_DISPUTE_START,
  FETCH_SINGLE_DISPUTE_SUCCESS,
  FETCH_SINGLE_DISPUTE_FAILURE,
  FETCH_CLOSED_CASE_START,
  FETCH_CLOSED_CASE_SUCCESS,
  FETCH_CLOSED_CASE_FAILURE,
  FETCH_OPENED_CASE_START,
  FETCH_OPENED_CASE_SUCCESS,
  FETCH_OPENED_CASE_FAILURE,
  SEND_DISPUTE_MESSAGE_START,
  SEND_DISPUTE_MESSAGE_SUCCESS,
  SEND_DISPUTE_MESSAGE_FAILURE,
  GET_SINGLE_DISPUTE_MESSAGES_START,
  GET_SINGLE_DISPUTE_MESSAGES_SUCCESS,
  GET_SINGLE_DISPUTE_MESSAGES_FAILURE,
} from "./ActionConstant";

// Send dispute actions.

export function sendDisputeRequestStart(data) {
  return {
    type: SEND_DISPUTE_REQUEST_START,
    data,
  };
}

export function sendDisputeRequestSuccess(data) {
  return {
    type: SEND_DISPUTE_REQUEST_SUCCESS,
    data,
  };
}

export function sendDisputeRequestFailure(error) {
  return {
    type: SEND_DISPUTE_REQUEST_FAILURE,
    error,
  };
}

export function fetchDisputesStart(data) {
  return {
    type: FETCH_DISPUTES_START,
    data,
  };
}

export function fetchDisputesSuccess(data) {
  return {
    type: FETCH_DISPUTES_SUCCESS,
    data,
  };
}

export function fetchDisputesFailure(error) {
  return {
    type: FETCH_DISPUTES_FAILURE,
    error,
  };
}

export function cancelDisputeRequestStart(data) {
  return {
    type: CANCEL_DISPUTE_REQUEST_START,
    data,
  };
}

export function cancelDisputeRequestSuccess(data) {
  return {
    type: CANCEL_DISPUTE_REQUEST_SUCCESS,
    data,
  };
}

export function cancelDisputeRequestFailure(error) {
  return {
    type: CANCEL_DISPUTE_REQUEST_FAILURE,
    error,
  };
}

export function fetchSingleDisputeStart(data) {
  return {
    type: FETCH_SINGLE_DISPUTE_START,
    data,
  };
}

export function fetchSingleDisputeSuccess(data) {
  return {
    type: FETCH_SINGLE_DISPUTE_SUCCESS,
    data,
  };
}

export function fetchSingleDisputeFailure(error) {
  return {
    type: FETCH_SINGLE_DISPUTE_FAILURE,
    error,
  };
}

export function fetchClosedCaseStart(data) {
  return {
    type: FETCH_CLOSED_CASE_START,
    data,
  };
}

export function fetchClosedCaseSuccess(data) {
  return {
    type: FETCH_CLOSED_CASE_SUCCESS,
    data,
  };
}

export function fetchClosedCaseFailure(error) {
  return {
    type: FETCH_CLOSED_CASE_FAILURE,
    error,
  };
}

export function fetchOpenedCaseStart(data) {
  return {
    type: FETCH_OPENED_CASE_START,
    data,
  };
}

export function fetchOpenedCaseSuccess(data) {
  return {
    type: FETCH_OPENED_CASE_SUCCESS,
    data,
  };
}

export function fetchOpenedCaseFailure(error) {
  return {
    type: FETCH_OPENED_CASE_FAILURE,
    error,
  };
}
export function sendDisputeMessageStart(data) {
  return {
    type: SEND_DISPUTE_MESSAGE_START,
    data,
  };
}

export function sendDisputeMessageSuccess(data) {
  return {
    type: SEND_DISPUTE_MESSAGE_SUCCESS,
    data,
  };
}

export function sendDisputeMessageFailure(error) {
  return {
    type: SEND_DISPUTE_MESSAGE_FAILURE,
    error,
  };
}

export function getSingleDisputeMessagesStart(data) {
  return {
    type: GET_SINGLE_DISPUTE_MESSAGES_START,
    data,
  };
}

export function getSingleDisputeMessagesSuccess(data) {
  return {
    type: GET_SINGLE_DISPUTE_MESSAGES_SUCCESS,
    data,
  };
}

export function getSingleDisputeMessagesFailure(error) {
  return {
    type: GET_SINGLE_DISPUTE_MESSAGES_FAILURE,
    error,
  };
}

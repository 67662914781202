import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import ReactCodeInput from "react-verification-code-input";
import LoginIndex from "./LoginIndex";
import RegisterIndex from "./RegisterIndex";
import ForgotPassword from "./ForgotPassword";
import {
  registerVerifyResendStart,
  registerVerifyStart,
} from "../../store/actions/UserAction";

class RegisterVerifyIndex extends Component {
  state = {
    inputData: {},
  };
  handleChange = (input) => {
    const inputData = { ...this.state.inputData };
    inputData["verification_code"] = input;
    this.setState({ inputData });
  };
  submitVerfication = (event) => {
    event.preventDefault();
    this.props.dispatch(registerVerifyStart(this.state.inputData));
  };

  resendVerfication = (event) => {
    event.preventDefault();
    this.props.dispatch(registerVerifyResendStart());
  };

  render() {
    return (
      <div className="main-wrapper edit-profile xs-padding register-verify">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-3">
              <div className="edit-profile-box">
                <div className="row">
                  <div className="col-md-12 resp-sm-mrg-btm">
                    <div className="edit-profile-info">
                      <p className="desc">
                        For verification code, please check your email inbox
                      </p>

                      <h2 className="title-head">
                        Enter the verification code
                      </h2>
                      <form onSubmit={this.submitVerfication}>
                        <div className="input-group mb-4">
                          <ReactCodeInput
                            fields={6}
                            onChange={this.handleChange}
                            autoFocus={true}
                          />
                        </div>

                        <div className="resp-align-center mb-4">
                          <button
                            type="submit"
                            className="btn btn-quaternary"
                            disabled={
                              this.props.user.registerVerify.buttonDisable
                            }
                          >
                            {this.props.user.registerVerify
                              .loadingButtonContent != null
                              ? this.props.user.registerVerify
                                  .loadingButtonContent
                              : "Verify Now"}
                          </button>
                        </div>
                      </form>
                      <h6 className="resp-z-index">
                        Haven't received the code?
                        <span className="ml-2">
                          <a href="#" onClick={this.resendVerfication}>
                            Send again
                          </a>
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginIndex />
        <RegisterIndex />
        <ForgotPassword />
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  user: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RegisterVerifyIndex);

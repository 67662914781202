import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class SideBarIndex extends Component {
  state = {};
  render() {
    return (
      <div className="sidebar" id="sidebar-main">
        <Link className="navbar-brand" to="/dashboard">
          <img
            src={configuration.get("configData.site_logo")}
            className="logo"
            alt={configuration.get("configData.site_name")}
          />
        </Link>
        <ul className="list-unstyled list">
          <li>
            <NavLink activeClassName="active" to={"/dashboard"}>
              <i className="fas fa-home mr-2"></i>Home
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/wallet"}>
              <i className="fas fa-wallet mr-2"></i>Wallet
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/send-money"}>
              {" "}
              <i className="fas fa-paper-plane mr-2"></i>Send Money
            </NavLink>
          </li>

          <li>
            <NavLink activeClassName="active" to={"/manage-invoice"}>
              <i className="fas fa-file-invoice mr-2"></i>Invoices
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/transaction"}>
              <i className="fas fa-exchange-alt mr-2"></i>Transactions
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/resolution-center"}>
              <i className="fas fa-briefcase mr-2"></i>Resolution Center
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/settings"}>
              <i className="fas fa-cog mr-2"></i>Settings
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/profile"}>
              <i className="fas fa-user-circle mr-2"></i>Profile
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/logout"}>
              <i className="fas fa-sign-out-alt mr-2"></i>Logout
            </NavLink>
          </li>
        </ul>
        <div className="sidebar-footer">
          <h6>
            Copyright ©{new Date().getFullYear()}{" "}
            {configuration.get("configData.site_name")}
          </h6>
        </div>
      </div>
    );
  }
}

export default SideBarIndex;

import {
  CREATE_PAYMENT_REQUEST_START,
  CREATE_PAYMENT_REQUEST_SUCCESS,
  CREATE_PAYMENT_REQUEST_FAILURE,
  CREATE_PAYMENT_REQUEST_CALLBACK_START,
  CREATE_PAYMENT_REQUEST_CALLBACK_SUCCESS,
  CREATE_PAYMENT_REQUEST_CALLBACK_FAILURE,
  FETCH_COUNTRY_LIST_START,
  FETCH_COUNTRY_LIST_SUCCESS,
  FETCH_COUNTRY_LIST_FAILURE,
} from "./ActionConstant";

// Get HTP Wallet Details actions.
export function createPaymentRequestStart(data) {
  return {
    type: CREATE_PAYMENT_REQUEST_START,
    data,
  };
}

export function createPaymentRequestSuccess(data) {
  return {
    type: CREATE_PAYMENT_REQUEST_SUCCESS,
    data,
  };
}

export function createPaymentRequestFailure(error) {
  return {
    type: CREATE_PAYMENT_REQUEST_FAILURE,
    error,
  };
}

export function paymentCallbackStart(data) {
  return {
    type: CREATE_PAYMENT_REQUEST_CALLBACK_START,
    data,
  };
}

export function paymentCallbackSuccess(data) {
  return {
    type: CREATE_PAYMENT_REQUEST_CALLBACK_SUCCESS,
    data,
  };
}

export function paymentCallbackFailure(error) {
  return {
    type: CREATE_PAYMENT_REQUEST_CALLBACK_FAILURE,
    error,
  };
}


export function fetchCountryDetailsStart(data) {
  return {
    type: FETCH_COUNTRY_LIST_START,
    data,
  };
}

export function fetchCountryDetailsSuccess(data) {
  return {
    type: FETCH_COUNTRY_LIST_SUCCESS,
    data,
  };
}

export function fetchCountryDetailsFailure(error) {
  return {
    type: FETCH_COUNTRY_LIST_FAILURE,
    error,
  };
}
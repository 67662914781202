import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./BillingAccount.css";
import {
  getBankAccountStart,
  deleteBankAccountStart,
  makeDefaultBankAccountStart,
} from "../../../store/actions/BankAccountAction";

const BillingAccountList = (props) => {
  useEffect(() => {
    if (props.bankAccount.loading) props.dispatch(getBankAccountStart());
  }, []);

  const deleteAccount = (event, account) => {
    event.preventDefault();
    props.dispatch(
      deleteBankAccountStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
  };

  const makeDefaultAccount = (event, account) => {
    event.preventDefault();
    props.dispatch(
      makeDefaultBankAccountStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
  };

  return (
    <div className="main-wrapper xs-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="main-title">Billing Account List</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="billing-box">
              <div className="billing-account-list-header">
                <Link to={"/add-billing-account"}>
                  <button className="btn btn-quaternary width-160">
                    Add Account
                  </button>
                </Link>
              </div>
              <div className="billing-account-center-table">
                {props.bankAccount.loading ? (
                  "Loading..."
                ) : props.bankAccount.data.billing_accounts.length > 0 ? (
                  <table className="table billing-list-tabel table-responsive-sm table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Nick Name</th>
                        <th scope="col">Account Name</th>
                        <th scope="col">Account Number</th>
                        <th scope="col">Bank Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.bankAccount.data.billing_accounts.map(
                        (accounts) => (
                          <tr>
                            <td>
                              <Link
                                to={{
                                  pathname: `/single-billing-account/${accounts.user_billing_account_unique_id}`,
                                  state: accounts,
                                }}
                              >
                                {accounts.account_holder_name}
                              </Link>
                            </td>
                            <td>{accounts.account_holder_name}</td>
                            <td>{accounts.account_number}</td>
                            <td>{accounts.bank_name}</td>
                            <td>
                              {accounts.is_default == 0 ? (
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    makeDefaultAccount(event, accounts)
                                  }
                                >
                                  <span className="badge badge-default1">
                                    Make Default
                                  </span>
                                </Link>
                              ) : (
                                <span className="badge badge-default">
                                  Default
                                </span>
                              )}
                            </td>
                            <td>
                              <ul className="list-unstyled billing-action-btn">
                                <li>
                                  <Link
                                    to={{
                                      pathname: `/edit-billing-account/${accounts.user_billing_account_unique_id}`,
                                      state: accounts,
                                    }}
                                  >
                                    <img
                                      src={
                                        window.location.origin +
                                        "/assets/img/billing-edit.svg"
                                      }
                                      className="full-img billing-edit mr-2"
                                    />
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    onClick={(event) =>
                                      deleteAccount(event, accounts)
                                    }
                                  >
                                    <img
                                      src={
                                        window.location.origin +
                                        "/assets/img/billing-delete.svg"
                                      }
                                      className="full-img billing-delete"
                                    />
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        )
                      )}{" "}
                    </tbody>
                  </table>
                ) : (
                  "No Bank Accounts saved yet"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="space-center"></div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BillingAccountList);

import React from "react";
import ContentLoader from "react-content-loader";

const CardsLoader = (props) => (
  <ContentLoader
    speed={2}
    width={800}
    height={130}
    viewBox="0 0 800 130"
    backgroundColor="#c4c4c4"
    foregroundColor="#fcfcfc"
    {...props}
  >
    <rect x="-9" y="12" rx="2" ry="2" width="180" height="180" />
    <rect x="205" y="12" rx="2" ry="2" width="180" height="180" />
    <rect x="409" y="12" rx="2" ry="2" width="180" height="180" />
    <rect x="626" y="9" rx="2" ry="2" width="180" height="180" />
  </ContentLoader>
);

export default CardsLoader;

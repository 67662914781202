import React, { Component } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const $ = window.$;

class AfterLoginFooter extends Component {
  state = {};

    componentDidMount() {
      // var footerHeight = $("#footer").outerHeight();
      // var deviceheight = $(window).outerHeight();
      // var contentheight = deviceheight - footerHeight - 70;
      // $(".main-wrapper").css("min-height", contentheight);
  }
  render() {
    return (
      <div className="main-wrapper footer-secondary" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="footer-secondary-link list-unstyled">
                <li>
                  <Link to="/page/about">About US</Link>
                </li>
                <li>
                  <Link to="/page/terms">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/page/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/page/help">Help Center</Link>
                </li>

                <li>
                  <Link to="/page/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/page/faq">FAQ's</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AfterLoginFooter;

import React, { Component } from "react";
import {
  fetchCardDetailsStart,
  selectDefaultCardStart,
} from "../../../store/actions/CardsAction";
import { connect } from "react-redux";
import CardsLoader from "../../Loader/CardsLoader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCardModel from "../../Accounts/Cards/AddCardModel";
import {
  addMoneyViaBankStart,
  addMoneyViaCardStart,
} from "../../../store/actions/WalletAction";
import {
  getBankAccountStart,
  makeDefaultBankAccountStart,
} from "../../../store/actions/BankAccountAction";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const stripePromise = loadStripe("pk_test_uDYrTXzzAuGRwDYtu7dkhaF3");

class AddMoneyIndex extends Component {
  state = {
    paymentType: null,
    bankInputData: {},
  };

  setPaymentType = (event) => {
    this.setState({ paymentType: event.currentTarget.value });
    if (event.currentTarget.value == "card") {
      if (this.props.cards.cardDetails.loading) {
        this.props.dispatch(fetchCardDetailsStart());
      }
    }

    if (event.currentTarget.value == "bank") {
      if (this.props.bankAccount.loading) {
        this.props.dispatch(getBankAccountStart());
      }
    }
  };

  changeDefaultCard = (event, card) => {
    event.preventDefault();
    this.props.dispatch(selectDefaultCardStart(card));
  };

  changeDefaultAccount = (event, account) => {
    event.preventDefault();
    const bankInputData = { ...this.state.bankInputData };
    bankInputData[event.currentTarget.name] = event.currentTarget.value;
    this.setState({ bankInputData });
    this.props.dispatch(
      makeDefaultBankAccountStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
  };

  getBankInputData = ({ currentTarget: input }) => {
    const bankInputData = { ...this.state.bankInputData };
    bankInputData[input.name] = input.value;
    if (input.type === "file") {
      bankInputData[input.name] = input.files[0];
    }
    this.setState({ bankInputData });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.paymentType == "bank") {
      this.props.dispatch(addMoneyViaBankStart(this.state.bankInputData));
    } else if (this.state.paymentType == "card") {
      this.props.dispatch(addMoneyViaCardStart(this.state.bankInputData));
    }
  };

  render() {
    const { paymentType } = this.state;
    const { cardDetails } = this.props.cards;
    const { bankAccount } = this.props;
    return (
      <div className="main-wrapper add-money xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Add Money</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="add-money-box">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-xl-7">
                    <div className="row">
                      <div className="col-md-12">
                        <form className="theme-form" autoComplete="off">
                          <h4>
                            {" "}
                            <label>Enter Amount</label>
                          </h4>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <input
                              type="text"
                              className="form-control input-form-1"
                              placeholder="Amount"
                              aria-label="Enter Amount"
                              name="amount"
                              autoComplete="off"
                              onChange={this.getBankInputData}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5"></div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="sub-title">Select Payment Type</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card-wrapper">
                          <input
                            className="c-card"
                            type="radio"
                            id="card"
                            name="payment_type"
                            value="card"
                            onChange={this.setPaymentType}
                          />
                          <div className="card-content">
                            <div className="card-state-icon"></div>
                            <label className="width-100" htmlFor="card">
                              <div className="a-box">
                                <div className="sender-details">
                                  <h5 className="name">Card Payment</h5>
                                </div>
                                <div className="bank-details">
                                  <h5 className="bank-name">
                                    Make payment using your add
                                  </h5>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card-wrapper">
                          <input
                            className="c-card"
                            type="radio"
                            id="bank"
                            name="payment_type"
                            value="bank"
                            onChange={this.setPaymentType}
                          />
                          <div className="card-content">
                            <div className="card-state-icon"></div>
                            <label className="width-100" htmlFor="bank">
                              <div className="a-box">
                                <div className="sender-details">
                                  <h5 className="name">Bank Account Payment</h5>
                                </div>
                                <div className="bank-details">
                                  <h5 className="bank-name">
                                    Make payment using bank account
                                  </h5>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-lg"></div>
                    {paymentType == "card" ? (
                      <div className="row">
                        {cardDetails.loading ? (
                          <div>
                            <CardsLoader />
                          </div>
                        ) : cardDetails.data.cards.length > 0 ? (
                          cardDetails.data.cards.map((card) => (
                            <div className="col-md-6">
                              <div className="card-wrapper">
                                <input
                                  className="c-card"
                                  type="checkbox"
                                  id="3"
                                  value="3"
                                  defaultChecked={
                                    card.is_default == 1 ? true : false
                                  }
                                  onChange={(event) =>
                                    this.changeDefaultCard(event, card)
                                  }
                                />
                                <div className="card-content">
                                  <div className="card-state-icon"></div>
                                  <label className="width-100" for="3">
                                    <div className="a-box">
                                      <div className="sender-details">
                                        <h5 className="name">
                                          Card Type: {card.card_type}
                                        </h5>
                                      </div>
                                      <h3 className="account-no">
                                        XXXX XXXX {card.last_four}
                                      </h3>
                                      <div className="bank-details">
                                        <h5 className="bank-name">
                                          {card.is_default == 1
                                            ? "Default Card"
                                            : null}
                                        </h5>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-6 resp-sm-mrg-btm">
                            <div
                              className="dash-box flex-center text-center"
                              data-toggle="modal"
                              data-target="#add-card"
                            >
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/add-icon.svg"
                                }
                              />
                              <p className="desc">Add Card</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {paymentType == "bank" ? (
                      <div className="row">
                        {bankAccount.loading ? (
                          <div>
                            <CardsLoader />
                          </div>
                        ) : bankAccount.data.billing_accounts.length > 0 ? (
                          <>
                            <form className="theme-form" autoComplete="off">
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control input-form"
                                  placeholder="Transaction ID"
                                  name="payment_id"
                                  autoComplete="off"
                                  onChange={this.getBankInputData}
                                />
                              </div>
                              {bankAccount.data.billing_accounts.map(
                                (account) => (
                                  <div className="col-md-12">
                                    <div className="card-wrapper">
                                      <input
                                        className="c-card"
                                        type="checkbox"
                                        id="3"
                                        value={account.user_billing_account_id}
                                        defaultChecked={
                                          account.is_default == 1 ? true : false
                                        }
                                        onChange={(event) =>
                                          this.changeDefaultAccount(
                                            event,
                                            account
                                          )
                                        }
                                      />
                                      <div className="card-content">
                                        <div className="card-state-icon"></div>
                                        <label className="width-100" for="3">
                                          <div className="a-box">
                                            <div className="sender-details">
                                              <h5 className="name">
                                                Nick Name:{" "}
                                                {account.account_holder_name}
                                              </h5>
                                            </div>
                                            <h3 className="account-no">
                                              Acc No: {account.account_number}
                                            </h3>
                                            <div className="bank-details">
                                              <h5 className="bank-name">
                                                {account.is_default == 1
                                                  ? "Default Account"
                                                  : null}
                                              </h5>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </form>
                          </>
                        ) : (
                          <div className="col-md-12 resp-sm-mrg-btm">
                            <Link to={"/billing-account-list"}>
                              <div className="dash-box flex-center text-center">
                                <img
                                  src={
                                    window.location.origin +
                                    "/assets/img/add-icon.svg"
                                  }
                                />
                                <p className="desc">Add Bank Account</p>
                              </div>
                            </Link>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {/* {paymentType == "bank" ? (
                      <div className="row add-money-bank">
                        <div className="col-md-7">
                          <div className="row">
                            <div className="col-md-12">
                              <form className="theme-form" autoComplete="off">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control input-form"
                                    placeholder="Transaction ID"
                                    name="payment_id"
                                    autoComplete="off"
                                    onChange={this.getBankInputData}
                                  />
                                </div>
                                <div className="form-group upload-doc-box">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file input-form button"
                                    placeholder="Transcation Image"
                                    name="bank_statement_picture"
                                    id="exampleFormControlFile1"
                                    onChange={this.getBankInputData}
                                    id="exampleFormControlFile1"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5"></div>
                      </div>
                    ) : null} */}

                    <div className="space-lg"></div>
                    <div className="row">
                      <div className="col-md-12 resp-align-center">
                        <button
                          className="btn btn-quaternary width-120 res-md-mrg-btm resp-sm-mrg-btm"
                          onClick={this.handleSubmit}
                          disabled={
                            this.props.wallet.addMoneyInput.buttonDisable
                          }
                        >
                          {this.props.wallet.addMoneyInput
                            .loadingButtonContent != null
                            ? this.props.wallet.addMoneyInput
                                .loadingButtonContent
                            : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-md-12">
                    {/* <img
                      src={
                        window.location.origin + "/assets/img/send-money.svg"
                      }
                      className="full-img"
                    /> */}

                    <div className="preview-details">
                      <h3 className="preview-title">Admin Bank Account:</h3>

                      <hr></hr>

                      <div className="flex-new-data">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="flex-space-equal">
                              <p className="preview-no">Account Number:</p>
                              <p className="preview-no">:</p>
                            </div>
                            <div className="flex-space-equal">
                              <p className="preview-no">Account Holder </p>
                              <p className="preview-no">:</p>
                            </div>
                            <div className="flex-space-equal">
                              <p className="preview-email">Bank </p>
                              <p className="preview-no">:</p>
                            </div>
                            <div className="flex-space-equal">
                              <p className="preview-email">Branch </p>
                              <p className="preview-no">:</p>
                            </div>
                            <div className="flex-space-equal">
                              <p className="preview-email">IFSC Code</p>
                              <p className="preview-no">:</p>
                            </div>
                            <div className="flex-space-equal">
                              <p className="preview-email">SWIFT Code</p>
                              <p className="preview-no">:</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <p className="admin-bank-desc">
                              {configuration.get("configData.account_number")}
                            </p>
                            <p className="admin-bank-desc">
                              {configuration.get(
                                "configData.account_holder_name"
                              )}
                            </p>
                            <p className="admin-bank-desc">
                              {configuration.get("configData.bank_name")}
                            </p>
                            <p className="admin-bank-desc">
                              {configuration.get("configData.branch_name")}
                            </p>

                            <p className="admin-bank-desc">
                              {configuration.get("configData.ifsc_code")}
                            </p>
                            <p className="admin-bank-desc">
                              {configuration.get("configData.swift_code")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
        <Elements stripe={stripePromise}>
          <AddCardModel />
        </Elements>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  cards: state.cards,
  wallet: state.wallet,
  bankAccount: state.bankAccount.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddMoneyIndex);

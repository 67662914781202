import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_INVOICE_HISTORY_START,
  FETCH_INVOICE_DRAFT_START,
  FETCH_INVOICE_UNPAID_START,
  FETCH_INVOICE_PAID_START,
  FETCH_INVOICE_RECEIVED_START,
  INVOICE_PAYNOW_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchInvoiceHistoryFailure,
  fetchInvoiceDraftSuccess,
  fetchInvoiceDraftFailure,
  fetchInvoiceUnpaidSuccess,
  fetchInvoiceUnpaidFailure,
  fetchInvoicePaidSuccess,
  fetchInvoicePaidFailure,
  fetchInvoiceHistorySuccess,
  fetchInvoiceReceivedSuccess,
  fetchInvoiceReceivedFailure,
  invoicePayNowStart,
  invoicePayNowFailure,
  invoicePayNowSuccess,
} from "../actions/ManageInvoiceAction";

function* fetchInvoiceHistoryAPI() {
  try {
    const skipCount = yield select(
      (state) => state.invoice.invoiceHistory.skip
    );
    const response = yield api.postMethod("generated_invoices_index", {
      skip: skipCount,
    });

    if (response.data.success) {
      yield put(fetchInvoiceHistorySuccess(response.data.data));
    } else {
      yield put(fetchInvoiceHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchInvoiceHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchInvoiceDraftAPI() {
  try {
    const skipCount = yield select((state) => state.invoice.invoiceDraft.skip);
    const response = yield api.postMethod("generated_invoices_drafts", {
      skip: skipCount,
    });

    if (response.data.success) {
      yield put(fetchInvoiceDraftSuccess(response.data.data));
    } else {
      yield put(fetchInvoiceDraftFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchInvoiceDraftFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchInvoiceUnpaidAPI() {
  try {
    const skipCount = yield select((state) => state.invoice.invoiceUnpaid.skip);
    const response = yield api.postMethod("generated_invoices_unpaid", {
      skip: skipCount,
    });

    if (response.data.success) {
      yield put(fetchInvoiceUnpaidSuccess(response.data.data));
    } else {
      yield put(fetchInvoiceUnpaidFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchInvoiceUnpaidFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchInvoicePaidAPI() {
  try {
    const skipCount = yield select((state) => state.invoice.invoicePaid.skip);
    const response = yield api.postMethod("generated_invoices_paid", {
      skip: skipCount,
    });

    if (response.data.success) {
      yield put(fetchInvoicePaidSuccess(response.data.data));
    } else {
      yield put(fetchInvoicePaidFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchInvoicePaidFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchReceivedInvoiceAPI() {
  try {
    const skipCount = yield select(
      (state) => state.invoice.invoiceReceived.skip
    );
    const response = yield api.postMethod("generated_invoices_received", {
      skip: skipCount,
    });

    if (response.data.success) {
      yield put(fetchInvoiceReceivedSuccess(response.data.data));
    } else {
      yield put(fetchInvoiceReceivedFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchInvoiceReceivedFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* invoicePayNowAPI() {
  try {
    const inputData = yield select(
      (state) => state.invoice.invoicePayNow.inputData
    );
    const response = yield api.postMethod(
      "generated_invoices_payment",
      inputData
    );

    if (response.data.success) {
      yield put(invoicePayNowSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/manage-invoice");
    } else {
      yield put(invoicePayNowFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(invoicePayNowFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_INVOICE_HISTORY_START, fetchInvoiceHistoryAPI),
    yield takeLatest(FETCH_INVOICE_DRAFT_START, fetchInvoiceDraftAPI),
    yield takeLatest(FETCH_INVOICE_UNPAID_START, fetchInvoiceUnpaidAPI),
    yield takeLatest(FETCH_INVOICE_PAID_START, fetchInvoicePaidAPI),
    yield takeLatest(FETCH_INVOICE_RECEIVED_START, fetchReceivedInvoiceAPI),
    yield takeLatest(INVOICE_PAYNOW_START, invoicePayNowAPI),
  ]);
}

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import EmptyDataTable from "../../Handler/EmptyDataTable";
import { cancelWithDrawRequestStart } from "../../../store/actions/WithDrawAction";
import CancelTransactionInputModel from "./CancelTransactionInputModel";
import _ from "lodash";

const WithdrawTransactionSec = (props) => {
  const withDrawals = props.withDraw;

  const cancelWithDrawRequest = (event, transaction) => {
    event.preventDefault();
    props.dispatch(
      cancelWithDrawRequestStart({
        user_withdrawal_id: transaction.user_withdrawal_id,
      })
    );
  };

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [results, setResults] = useState([]);

  const handleSearchChange = ({ currentTarget: input }) => {
    setIsLoadingSearch(true);
    setTimeout(() => {
      if (input.value < 1) {
        setIsLoadingSearch(false);
        setResults([]);
        return;
      }
      const re = new RegExp(_.escapeRegExp(input.value), "i");
      const isMatch = (result) => re.test(result.user_withdrawal_unique_id) || re.test(result.username);
      setIsLoadingSearch(false);
      setResults(_.filter(withDrawals.data.history, isMatch));
    }, 300);
  };

  let renderData;

  if (results.length > 0) {
    renderData = results;
  } else {
    renderData = withDrawals.data.history;
  }

  return (
    <div
      className="tab-pane fade"
      id="nav-withdraw-trans"
      role="tabpanel"
      aria-labelledby="nav-withdraw-trans-tab"
    >
      <div className="transactions-table">
        <div className="transactions-table-header flex-data">
          <h3 className="title">Withdraw Transactions</h3>
          <form autoComplete="off">
            <div className="input-group">
              <input
                type="text"
                className="form-control input-form"
                placeholder="Search"
                name="search"
                onChange={handleSearchChange}
                autoComplete="off"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </form>
        </div>
        <table className="table trans-table table-responsive-sm table-borderless">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Date</th>
              <th scope="col">Reference ID</th>
              <th scope="col">Requested</th>
              <th scope="col">Paid</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {withDrawals.loading ? (
              "Loading..."
            ) : renderData.length > 0 ? (
              renderData.map((transaction, i) => (
                <tr>
                  <td className="date">{i + 1}</td>

                  <td className="date">{transaction.updated_at}</td>
                  <td className="id">
                    {transaction.user_withdrawal_unique_id}
                  </td>
                  <td className="text-warning">
                    {transaction.requested_amount_formatted}
                    {transaction.currency_code ? ' ' + transaction.currency_code : ""}
                  </td>
                  <td className="text-success">
                    {transaction.paid_amount_formatted}
                  </td>
                  <td>
                    <p className="text-dark text-uppercase transaction-status">
                      {transaction.status_formatted}
                    </p>
                  </td>
                  <td>
                    {transaction.cancel_btn_status == 1 ? (
                      <button
                        className="btn btn-xs btn-quaternary"
                        // data-toggle="modal"
                        // data-target="#withdraw-cancel-request"
                        onClick={(event) =>
                          cancelWithDrawRequest(event, transaction)
                        }
                      >
                        Cancel
                      </button>
                    ) : (
                      "-"
                    )}
                  </td>
                  <CancelTransactionInputModel transaction={transaction} />
                </tr>
              ))
            ) : (
              <EmptyDataTable></EmptyDataTable>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  withDraw: state.withDraw.withDrawals,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(WithdrawTransactionSec);

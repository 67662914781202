import {
  FETCH_WALLET_DETAILS_START,
  FETCH_WALLET_DETAILS_SUCCESS,
  FETCH_WALLET_DETAILS_FAILURE,
  ADD_MONEY_VIA_CARD_START,
  ADD_MONEY_VIA_CARD_SUCCESS,
  ADD_MONEY_VIA_CARD_FAILURE,
  ADD_MONEY_VIA_BANK_START,
  ADD_MONEY_VIA_BANK_SUCCESS,
  ADD_MONEY_VIA_BANK_FAILURE,
  ADD_MONEY_VIA_HTP_START,
  ADD_MONEY_VIA_HTP_SUCCESS,
  ADD_MONEY_VIA_HTP_FAILURE,
  ADD_NEW_WALLET_START,
  ADD_NEW_WALLET_SUCCESS,
  ADD_NEW_WALLET_FAILURE,
  FETCH_WALLET_ACCOUNT_TYPE_START,
  FETCH_WALLET_ACCOUNT_TYPE_SUCCESS,
  FETCH_WALLET_ACCOUNT_TYPE_FAILURE,
} from "./ActionConstant";

// Get Wallet Details actions.

export function fetchWalletDetailsStart(data) {
  return {
    type: FETCH_WALLET_DETAILS_START,
    data,
  };
}

export function fetchWalletDetailsSuccess(data) {
  return {
    type: FETCH_WALLET_DETAILS_SUCCESS,
    data,
  };
}

export function fetchWalletDetailsFailure(error) {
  return {
    type: FETCH_WALLET_DETAILS_FAILURE,
    error,
  };
}

// add Wallet via card actions.

export function addMoneyViaCardStart(data) {
  return {
    type: ADD_MONEY_VIA_CARD_START,
    data,
  };
}

export function addMoneyViaCardSuccess(data) {
  return {
    type: ADD_MONEY_VIA_CARD_SUCCESS,
    data,
  };
}

export function addMoneyViaCardFailure(error) {
  return {
    type: ADD_MONEY_VIA_CARD_FAILURE,
    error,
  };
}

// add Wallet via bank actions.

export function addMoneyViaBankStart(data) {
  return {
    type: ADD_MONEY_VIA_BANK_START,
    data,
  };
}

export function addMoneyViaBankSuccess(data) {
  return {
    type: ADD_MONEY_VIA_BANK_SUCCESS,
    data,
  };
}

export function addMoneyViaBankFailure(error) {
  return {
    type: ADD_MONEY_VIA_BANK_FAILURE,
    error,
  };
}

// add Wallet via card actions.

export function addMoneyViaHTPStart(data) {
  return {
    type: ADD_MONEY_VIA_HTP_START,
    data,
  };
}

export function addMoneyViaHTPSuccess(data) {
  return {
    type: ADD_MONEY_VIA_HTP_SUCCESS,
    data,
  };
}

export function addMoneyViaHTPFailure(error) {
  return {
    type: ADD_MONEY_VIA_HTP_FAILURE,
    error,
  };
}

// get all Wallet account actions.

export function fetchWalletAccountTypeStart(data) {
  return {
    type: FETCH_WALLET_ACCOUNT_TYPE_START,
    data,
  };
}

export function fetchWalletAccountTypeSuccess(data) {
  return {
    type: FETCH_WALLET_ACCOUNT_TYPE_SUCCESS,
    data,
  };
}

export function fetchWalletAccountTypeFailure(error) {
  return {
    type: FETCH_WALLET_ACCOUNT_TYPE_FAILURE,
    error,
  };
}

// add Wallet via bank actions.

export function addNewWalletStart(data) {
  return {
    type: ADD_NEW_WALLET_START,
    data,
  };
}

export function addNewWalletSuccess(data) {
  return {
    type: ADD_NEW_WALLET_SUCCESS,
    data,
  };
}

export function addNewWalletFailure(error) {
  return {
    type: ADD_NEW_WALLET_FAILURE,
    error,
  };
}


import React, { Component, useCallback } from "react";
import { connect } from "react-redux";
import {
  
  paymentCallbackStart
} from "../../../store/actions/HTPAction";

class  CallbackResponseIndex extends Component {

  state = {};
  componentDidMount() {
    //if (this.props.wallet.walletData.loading) {
      this.props.dispatch(paymentCallbackStart());
    //}
  }

  render() {
    console.log('dddd');
    const paymentCallback = this.props.htp.paymentCallback.successData? this.props.htp.paymentCallback.successData:'';

    console.log(paymentCallback);

    return (
      <div className="main-wrapper home xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card-style">
                <h2 className="main-title mb-0">Summary</h2>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
const mapStateToPros = (state) => ({
  wallet: state.wallet,
  htp: state.htp,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CallbackResponseIndex);

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  editChangePassword,
  changePasswordStart,
} from "../../../store/actions/UserAction";

class ChangePasswordIndex extends Component {
  handleChangePassword = ({ currentTarget: input }) => {
    this.props.dispatch(editChangePassword(input.name, input.value));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(changePasswordStart());
  };

  render() {
    const { inputData } = this.props.changePassword;
    return (
      <div className="main-wrapper change-password xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Change Password</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="change-password-box">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <form className="theme-form cp-box" autoComplete="off">
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label>old password</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="password"
                                className="form-control"
                                name="old_password"
                                value={inputData.data.old_password}
                                onChange={this.handleChangePassword}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label for="new-pass">new password</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="password"
                                name="password"
                                value={inputData.data.password}
                                className="form-control"
                                onChange={this.handleChangePassword}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label for="new-pass">confirm password</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="password"
                                name="password_confirmation"
                                value={inputData.data.password_confirmation}
                                className="form-control"
                                onChange={this.handleChangePassword}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-8 resp-align-center">
                              <button
                                className="btn btn-quaternary"
                                onClick={this.handleSubmit}
                                disabled={
                                  this.props.changePassword.buttonDisable
                                }
                              >
                                {this.props.changePassword
                                  .loadingButtonContent != null
                                  ? this.props.changePassword
                                      .loadingButtonContent
                                  : "Change Password"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      src={
                        window.location.origin + "/assets/img/send-money.svg"
                      }
                      className="full-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-center space-center-1"></div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  changePassword: state.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePasswordIndex);

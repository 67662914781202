import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import api from "../../../Environment";
import { connect } from "react-redux";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../helper/NotificationMessage";
import { fetchCardDetailsStart } from "../../../store/actions/CardsAction";

const $ = window.$;

const AddCardModel = ({ dispatch }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [addCardButtonDisable, setAddCardButtonDisable] = useState(false);
  const [addCardLoadingContent, setAddCardLoadingContent] = useState(null);

  const addCard = async (ev) => {
    ev.preventDefault();
    setAddCardButtonDisable(true);
    setAddCardLoadingContent("Loading... Please wait");
    if (stripe) {
      await stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        })
        .then((payload) => {
          // console.log("Payload", payload);
          const inputData = {
            card_token: payload.paymentMethod.id,
          };
          api
            .postMethod("cards_add", inputData)
            .then((response) => {
              if (response.data.success) {
                const notificationMessage = getSuccessNotificationMessage(
                  response.data.message
                );
                dispatch(createNotification(notificationMessage));
                dispatch(fetchCardDetailsStart());

                setAddCardButtonDisable(false);
                setAddCardLoadingContent(null);
              } else {
                const notificationMessage = getErrorNotificationMessage(
                  response.data.error
                );
                dispatch(createNotification(notificationMessage));
              }
              $("#add-card").modal("hide");
            })
            .catch((error) => {
              console.log("Error", error);
              setAddCardButtonDisable(false);
              setAddCardLoadingContent(null);
              const notificationMessage = getErrorNotificationMessage(
                "Error Please try again"
              );
              dispatch(createNotification(notificationMessage));
            });
        })
        .catch((error) => {
          console.log("Eroor", error);
          setAddCardButtonDisable(false);
          setAddCardLoadingContent(null);
          const notificationMessage = getErrorNotificationMessage(
            "Please check your card details and try again.."
          );
          dispatch(createNotification(notificationMessage));
        });
    } else {
      setAddCardButtonDisable(false);
      setAddCardLoadingContent(null);
      const notificationMessage = getErrorNotificationMessage(
        "Stripe is not configured"
      );
      dispatch(createNotification(notificationMessage));
    }
  };

  return (
    <div
      className="modal fade"
      id="add-card"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="add-card-model"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="add-card-model">
              Add Card
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="theme-form" onSubmit={addCard} autoComplete="off">
              <CardElement />
              <div className="modal-footer pt-55">
                <button
                  type="button"
                  className="btn btn-quaternary ml-0 width-200-new"
                  disabled={addCardButtonDisable}
                  onClick={addCard}
                >
                  {addCardLoadingContent != null
                    ? addCardLoadingContent
                    : "Add"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary width-200-new"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(AddCardModel);

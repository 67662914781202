import React, {useState} from "react";
import i18n from "../../i18n";
import { Link } from "react-router-dom";

const LanguageSelector = () => {
    
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(e.target.value);
    }

    return (

        <li className="nav-item dropdown dropdown-hover mx-2">
            <select className="ml-2 languageSwitcher" defaultValue={selectedLanguage} onChange={chooseLanguage}>  
            <option value="th">TH</option>
            <option value="en">EN</option>
           </select> 
      </li>

    );
};

export default LanguageSelector;
import React, { Component } from "react";

const ScheduleInvoiceSec = () => {
  return (
    <div
      className="tab-pane fade"
      id="nav-scheduled-invoice"
      role="tabpanel"
      aria-labelledby="nav-scheduled-invoice-tab"
    >
      <div className="transactions-table">
        <div className="transactions-table-header flex-data">
          <h3 className="title">Scheduled Invoice</h3>
          <form autoComplete="off">
            <div className="input-group">
              <input
                type="text"
                className="form-control input-form"
                placeholder="Search"
                autoComplete="off"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </form>
        </div>
        <table className="table trans-table table-responsive-sm table-borderless">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Reference ID</th>
              <th scope="col">Recepient</th>
              <th scope="col">Amount</th>
              <th scope="col">Created</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="date">10-May-2019</td>
              <td className="id">19N78678TD</td>
              <td>
                <div className="trans-details">
                  <div className="trans-img">
                    <img
                      src={
                        window.location.origin + "/assets/img/profile-img.jpg"
                      }
                      className="user-img"
                    />
                  </div>
                  <div className="user-details">
                    <h5 className="name">Paul Wesley</h5>
                    <p className="desc">Wallet Account Deposit</p>
                  </div>
                </div>
              </td>
              <td className="positive-amount">+ $ 235.78 USD</td>
              <td>
                <p className="status-success">Success</p>
              </td>
            </tr>
            <tr>
              <td className="date">10-May-2019</td>
              <td className="id">19N78678TD</td>
              <td>
                <div className="trans-details">
                  <div className="trans-img">
                    <img
                      src={
                        window.location.origin + "/assets/img/profile-img.jpg"
                      }
                      className="user-img"
                    />
                  </div>
                  <div className="user-details">
                    <h5 className="name">Paul Wesley</h5>
                    <p className="desc">Wallet Account Deposit</p>
                  </div>
                </div>
              </td>
              <td className="negative-amount">- $ 235.78 USD</td>
              <td>
                <p className="status-notsent">Not Sent</p>
              </td>
            </tr>
            <tr>
              <td className="date">10-May-2019</td>
              <td className="id">19N78678TD</td>
              <td>
                <div className="trans-details">
                  <div className="trans-img">
                    <img
                      src={
                        window.location.origin + "/assets/img/profile-img.jpg"
                      }
                      className="user-img"
                    />
                  </div>
                  <div className="user-details">
                    <h5 className="name">Paul Wesley</h5>
                    <p className="desc">Wallet Account Deposit</p>
                  </div>
                </div>
              </td>
              <td className="positive-amount">+ $ 235.78 USD</td>
              <td>
                <p className="status-pending">Pending</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="pagination-content">
          <ul className="pagination">
            <li>
              <a href="#" className="active-zero">
                <i className="fas fa-angle-double-left"></i>
              </a>
            </li>
            <li>
              <a href="#" className="active">
                1
              </a>
            </li>
            <li>
              <a href="#">2</a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li>
              <a href="#">4</a>
            </li>
            <span className="page-dots">................</span>
            <li>
              <a href="#">15</a>
            </li>
            <li>
              <a href="#" className="active-zero">
                <i className="fas fa-angle-double-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ScheduleInvoiceSec;

import React, { Component } from "react";
import { connect } from "react-redux";
import AllTransaction from "./AllTransactionSec";
import DepositTransaction from "./DespositTransactionSec";
import WithdrawTransactionSec from "./WithdrawTransactionSec";
import PaymentReceivedSec from "./PaymentReceivedSec";
import PaymentSentSec from "./PaymentSentSec";
import {
  fetchDepositTransactionStart,
  fetchReceivedPaymentTransactionStart,
  fetchSentPaymentTransactionStart,
} from "../../../store/actions/TransactionAction";
import { fetchWithDrawalsStart } from "../../../store/actions/WithDrawAction";
import DisputeModel from "./DisputeModel";
import { sendDisputeRequestStart } from "../../../store/actions/DisputeAction";

const $ = window.$;

class TransactionIndex extends Component {
  state = {
    disputeTransaction: {},
    disputeInputData: {},
  };

  depositTransactionCall = () => {
    if (this.props.transaction.depositTrans.loading)
      this.props.dispatch(fetchDepositTransactionStart());
  };

  paymentReceivedCall = () => {
    if (this.props.transaction.receivedPayTrans.loading)
      this.props.dispatch(fetchReceivedPaymentTransactionStart());
  };

  paymentSentCall = () => {
    if (this.props.transaction.sentPayTrans.loading)
      this.props.dispatch(fetchSentPaymentTransactionStart());
  };

  withDrawalCall = () => {
    if (this.props.withDraw.withDrawals.loading)
      this.props.dispatch(fetchWithDrawalsStart());
  };

  selectDisputeTransaction = (transaction) => {
    this.setState({
      disputeTransaction: transaction,
      disputeInputData: {
        user_wallet_payment_id: transaction.user_wallet_payment_id,
      },
    });
  };

  // Dispute get message

  handleChange = ({ currentTarget: input }) => {
    const disputeInputData = { ...this.state.disputeInputData };
    disputeInputData[input.name] = input.value;
    this.setState({ disputeInputData });
  };

  // submit dispute form - send dispute request.

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(sendDisputeRequestStart(this.state.disputeInputData));
    $(".dispute-form").modal("hide");
  };

  render() {
    return (
      <div className="main-wrapper transactions xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Transactions</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="transactions-box">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className="nav-item nav-link active"
                      id="nav-all-trans-tab"
                      data-toggle="tab"
                      href="#nav-all-trans"
                      role="tab"
                      aria-controls="nav-all-trans"
                      aria-selected="true"
                    >
                      All Transactions
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-payment-sent-tab"
                      data-toggle="tab"
                      href="#nav-payment-sent"
                      role="tab"
                      aria-controls="nav-payment-sent"
                      aria-selected="false"
                      onClick={this.paymentSentCall}
                    >
                      Payment Sent
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-payment-received-tab"
                      data-toggle="tab"
                      href="#nav-payment-received"
                      role="tab"
                      aria-controls="nav-payment-received"
                      aria-selected="false"
                      onClick={this.paymentReceivedCall}
                    >
                      Payment Received
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-deposit-trans-tab"
                      data-toggle="tab"
                      href="#nav-deposit-trans"
                      role="tab"
                      aria-controls="nav-deposit-trans"
                      aria-selected="false"
                      onClick={this.depositTransactionCall}
                    >
                      Deposit Transactions
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-withdraw-trans-tab"
                      data-toggle="tab"
                      href="#nav-withdraw-trans"
                      role="tab"
                      aria-controls="nav-withdraw-trans"
                      aria-selected="false"
                      onClick={this.withDrawalCall}
                    >
                      Withdraw Transactions
                    </a>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <AllTransaction />
                  <DepositTransaction />
                  <WithdrawTransactionSec />
                  <PaymentReceivedSec />
                  <PaymentSentSec
                    selectDisputeTransaction={this.selectDisputeTransaction}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
        <DisputeModel
          disputeTransaction={this.state.disputeTransaction}
          disputeInputData={this.state.disputeInputData}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          buttonDisable={this.props.dispute.buttonDisable}
          loadingButtonContent={this.props.dispute.loadingButtonContent}
        />
      </div>
    );
  }
}
const mapStateToPros = (state) => ({
  transaction: state.transaction,
  withDraw: state.withDraw,
  dispute: state.dispute,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TransactionIndex);

import React, { useState } from "react";
import { connect } from "react-redux";
import { cancelWithDrawRequestStart } from "../../../store/actions/WithDrawAction";

const $ = window.$;

const CancelTransactionInputModel = (props) => {
  const [message, setMessage] = useState("");

  const handleChange = ({ currentTarget: input }) => {
    setMessage(input.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(
      cancelWithDrawRequestStart({
        user_withdrawal_id: props.transaction.user_withdrawal_id,
        cancel_reason: message,
      })
    );
    $("#withdraw-cancel-request").modal("hide");
  };

  return (
    <div
      className="modal fade"
      id="withdraw-cancel-request"
      tabindex="-1"
      role="dialog"
      aria-labelledby="withdraw-modaltitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="withdraw-modaltitle">
              Withdraw Cancel Message
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            className="theme-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="modal-body">
              <p className="text-muted">
                Could you please explain why do you want to cancel the request?
              </p>

              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control input-form-1"
                  placeholder="Mesage"
                  aria-label="Enter Message"
                  name="message"
                  value={message}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-quaternary width-120"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(CancelTransactionInputModel);

import {
  SEARCH_USER_START,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  SEND_MONEY_START,
  SEND_MONEY_SUCCESS,
  SEND_MONEY_FAILURE,
} from "./ActionConstant";

// Get Wallet Details actions.

export function searchUserStart(data) {
  return {
    type: SEARCH_USER_START,
    data,
  };
}

export function searchUserSuccess(data) {
  return {
    type: SEARCH_USER_SUCCESS,
    data,
  };
}

export function searchUserFailure(error) {
  return {
    type: SEARCH_USER_FAILURE,
    error,
  };
}

// Get Wallet Details actions.

export function sendMoneyStart(data) {
  return {
    type: SEND_MONEY_START,
    data,
  };
}

export function sendMoneySuccess(data) {
  return {
    type: SEND_MONEY_SUCCESS,
    data,
  };
}

export function sendMoneyFailure(error) {
  return {
    type: SEND_MONEY_FAILURE,
    error,
  };
}

import {
  SEARCH_USER_START,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  SEND_MONEY_START,
  SEND_MONEY_SUCCESS,
  SEND_MONEY_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  userList: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  sendMoney: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const SendMoneyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_USER_START:
      return {
        ...state,
        userList: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        userList: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case SEARCH_USER_FAILURE:
      return {
        ...state,
        userList: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case SEND_MONEY_START:
      return {
        ...state,
        sendMoney: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case SEND_MONEY_SUCCESS:
      return {
        ...state,
        sendMoney: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case SEND_MONEY_FAILURE:
      return {
        ...state,
        sendMoney: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };

    default:
      return state;
  }
};

export default SendMoneyReducer;

import React, { Component } from "react";
import WalletDepositSec from "./WalletDepositSec";
import WalletWithdrawSec from "./WalletWithdrawSec";
import WalletAccountSec from "./WalletAccountSec";
import { connect } from "react-redux";
import { fetchWalletDetailsStart, fetchWalletAccountTypeStart } from "../../store/actions/WalletAction";
import { Link } from "react-router-dom";
import WithdrawModel from "./WithdrawModel";
import AddMoneyModel from "./AddMoneyModel";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";


class WalletIndex extends Component {
  state = {};

  componentDidMount() {
    if (this.props.wallet.walletData.loading) {
      this.props.dispatch(fetchWalletDetailsStart());
    }
    if (this.props.wallet.walletAccountType.loading) {
      this.props.dispatch(fetchWalletAccountTypeStart());
    }
    if (this.props.profile.loading) {
      this.props.dispatch(fetchUserDetailsStart());
    }
  }

  render() {
    const { walletData } = this.props.wallet;

    return (
      <div className="main-wrapper wallet xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Wallet</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="wallet-box">
                <div className="row">
                  <div className="col-md-12">
                    <h4>Wallet Balance</h4>
                    <WalletAccountSec />
                    {this.props.profile.loading ? (
                      "Loading..."
                    ) : this.props.profile.data.is_kyc_document_approved ==
                      2 ? (
                      <>
                        <Link
                          to={"/add-account"}
                          className="btn btn-teritory mr-3 resp-xs-mrg-btm"
                        >
                          Add New Wallet
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          to="/profile"
                          disabled={true}
                          className="btn btn-teritory mr-3 resp-xs-mrg-btm"
                        >
                          Update KYC
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-lg"></div>
          <div className="row">
            <WalletDepositSec />
            <WalletWithdrawSec />
          </div>
          <div className="space-center"></div>
        </div>
        <AddMoneyModel />
        <WithdrawModel />
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  wallet: state.wallet,
  profile: state.users.profile,
  walletAccountType: state.wallet.walletAccountType,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletIndex);

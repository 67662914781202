import {
  FETCH_INVOICE_HISTORY_START,
  FETCH_INVOICE_HISTORY_SUCCESS,
  FETCH_INVOICE_HISTORY_FAILURE,
  FETCH_INVOICE_DRAFT_START,
  FETCH_INVOICE_DRAFT_SUCCESS,
  FETCH_INVOICE_DRAFT_FAILURE,
  FETCH_INVOICE_UNPAID_START,
  FETCH_INVOICE_UNPAID_SUCCESS,
  FETCH_INVOICE_UNPAID_FAILURE,
  FETCH_INVOICE_PAID_START,
  FETCH_INVOICE_PAID_SUCCESS,
  FETCH_INVOICE_PAID_FAILURE,
  FETCH_INVOICE_RECEIVED_START,
  FETCH_INVOICE_RECEIVED_SUCCESS,
  FETCH_INVOICE_RECEIVED_FAILURE,
  INVOICE_PAYNOW_START,
  INVOICE_PAYNOW_SUCCESS,
  INVOICE_PAYNOW_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  invoiceHistory: {
    data: {
      invoices: [],
    },
    loading: true,
    error: false,
    skip: 0,
    buttonDisable: false,
    loadingButtonContent: null,
    totalItem: 0,
  },

  invoiceDraft: {
    data: {
      invoices: [],
    },
    loading: true,
    error: false,
    skip: 0,
    buttonDisable: false,
    loadingButtonContent: null,
    totalItem: 0,
  },
  invoiceUnpaid: {
    data: {
      invoices: [],
    },
    loading: true,
    error: false,
    skip: 0,
    buttonDisable: false,
    loadingButtonContent: null,
    totalItem: 0,
  },
  invoicePaid: {
    data: {
      invoices: [],
    },
    loading: true,
    error: false,
    skip: 0,
    buttonDisable: false,
    loadingButtonContent: null,
    totalItem: 0,
  },
  invoiceReceived: {
    data: {
      invoices: [],
    },
    loading: true,
    error: false,
    skip: 0,
    buttonDisable: false,
    loadingButtonContent: null,
    totalItem: 0,
  },
  invoicePayNow: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const ManageInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICE_HISTORY_START:
      return {
        ...state,
        invoiceHistory: {
          data: {
            invoices: [...state.invoiceHistory.data.invoices],
          },
          loading: state.invoiceHistory.loading ? true : false,
          error: false,
          skip: state.invoiceHistory.skip,
          totalItem: state.invoiceHistory.totalItem,
        },
      };
    case FETCH_INVOICE_HISTORY_SUCCESS:
      return {
        ...state,
        invoiceHistory: {
          data: {
            invoices: [
              ...state.invoiceHistory.data.invoices,
              ...action.data.invoices,
            ],
          },
          totalItem: action.data.total,
          loading: false,
          error: false,
          skip: action.data.invoices.length + state.invoiceHistory.skip,
        },
      };
    case FETCH_INVOICE_HISTORY_FAILURE:
      return {
        ...state,
        invoiceHistory: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_INVOICE_DRAFT_START:
      return {
        ...state,
        invoiceDraft: {
          data: {
            invoices: [...state.invoiceDraft.data.invoices],
          },
          loading: state.invoiceDraft.loading ? true : false,
          error: false,
          skip: state.invoiceDraft.skip,
          totalItem: state.invoiceDraft.totalItem,
        },
      };
    case FETCH_INVOICE_DRAFT_SUCCESS:
      return {
        ...state,
        invoiceDraft: {
          data: {
            invoices: [
              ...state.invoiceDraft.data.invoices,
              ...action.data.invoices,
            ],
          },
          totalItem: action.data.total,
          loading: false,
          error: false,
          skip: action.data.invoices.length + state.invoiceDraft.skip,
        },
      };
    case FETCH_INVOICE_DRAFT_FAILURE:
      return {
        ...state,
        invoiceDraft: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_INVOICE_UNPAID_START:
      return {
        ...state,
        invoiceUnpaid: {
          data: {
            invoices: [...state.invoiceUnpaid.data.invoices],
          },
          loading: state.invoiceUnpaid.loading ? true : false,
          error: false,
          skip: state.invoiceUnpaid.skip,
          totalItem: state.invoiceUnpaid.totalItem,
        },
      };
    case FETCH_INVOICE_UNPAID_SUCCESS:
      return {
        ...state,
        invoiceUnpaid: {
          data: {
            invoices: [
              ...state.invoiceUnpaid.data.invoices,
              ...action.data.invoices,
            ],
          },
          totalItem: action.data.total,
          loading: false,
          error: false,
          skip: action.data.invoices.length + state.invoiceUnpaid.skip,
        },
      };
    case FETCH_INVOICE_UNPAID_FAILURE:
      return {
        ...state,
        invoiceUnpaid: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_INVOICE_PAID_START:
      return {
        ...state,
        invoicePaid: {
          data: {
            invoices: [...state.invoicePaid.data.invoices],
          },
          loading: state.invoicePaid.loading ? true : false,
          error: false,
          skip: state.invoicePaid.skip,
          totalItem: state.invoicePaid.totalItem,
        },
      };
    case FETCH_INVOICE_PAID_SUCCESS:
      return {
        ...state,
        invoicePaid: {
          data: {
            invoices: [
              ...state.invoicePaid.data.invoices,
              ...action.data.invoices,
            ],
          },
          totalItem: action.data.total,
          loading: false,
          error: false,
          skip: action.data.invoices.length + state.invoicePaid.skip,
        },
      };
    case FETCH_INVOICE_PAID_FAILURE:
      return {
        ...state,
        invoicePaid: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_INVOICE_RECEIVED_START:
      return {
        ...state,
        invoiceReceived: {
          data: {
            invoices: [...state.invoiceReceived.data.invoices],
          },
          loading: state.invoiceReceived.loading ? true : false,
          error: false,
          skip: state.invoiceReceived.skip,
          totalItem: state.invoiceReceived.totalItem,
        },
      };
    case FETCH_INVOICE_RECEIVED_SUCCESS:
      return {
        ...state,
        invoiceReceived: {
          data: {
            invoices: [
              ...state.invoiceReceived.data.invoices,
              ...action.data.invoices,
            ],
          },
          totalItem: action.data.total,
          loading: false,
          error: false,
          skip: action.data.invoices.length + state.invoiceReceived.skip,
        },
      };
    case FETCH_INVOICE_RECEIVED_FAILURE:
      return {
        ...state,
        invoiceReceived: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case INVOICE_PAYNOW_START:
      return {
        ...state,
        invoicePayNow: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case INVOICE_PAYNOW_SUCCESS:
      return {
        ...state,
        invoicePayNow: {
          data: action.data,
          loading: true,
          error: false,
          inputData: {},
        },
      };
    case INVOICE_PAYNOW_FAILURE:
      return {
        ...state,
        invoicePayNow: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };

    default:
      return state;
  }
};

export default ManageInvoiceReducer;

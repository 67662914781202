import React, { Component } from "react";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { useTranslation, Translation } from "react-i18next";

const $ = window.$;

function MyAuthFooter () {
  const { t } = useTranslation('translation');
  return (
    <Translation>
    { (t, { i18n }) =>
      <div className="footer-fixed sm-padding-top" id="landing-footer">
        <footer className="footer-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-7 resp-sm-mrg-btm">
                  <div class="footer-link">
                    <ul className="list-unstyled list">
                        <li>
                          <Link to={"/page/privacy"}>{t('menu.PrivacyPolicy')}</Link>
                        </li>
                        <li>
                          <Link to={"/page/how-it-works"}>{t('menu.Howitworks')}</Link>
                        </li>
                        <li>
                          <Link to={"/page/about-us"}>{t('menu.AboutUs')}</Link>
                        </li>
                        <li><Link to={"/page/contact"}>{t('menu.Contact')}</Link>
                        </li>
                        <li><Link to={"/page/disclaimer"}>{t('menu.Disclaimer')}</Link>
                        </li>
                        {/* <li>
                          <Link to={"/page/download"}>Download</Link>
                        </li> */}
                    </ul>
                  </div>
                  <div className="w-100 d-flex pt-2">
                    <div class="copyright">© 2023 
                      <a href="https://confidiapay.com/" target="_top"> ConfidiaPay</a>.
                    </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-5 resp-sm-mrg-btm">
                 <ul className="download">
                  <li className="mr-2">
                    <a href="https://play.google.com/store/apps/details?id=com.confidiapay.wallet.app&hl=en-TH" target="_blank">
                        <img
                        src={
                          window.location.origin +
                          "/assets/img/download-android.png"
                        }
                      />
                      </a>
                  </li>
                  <li>
                    <a href="https://apps.apple.com/th/app/confidiapay/id6473804441" target="_blank">
                        <img
                        src={
                          window.location.origin +
                          "/assets/img/download_app_store.png"
                        }
                      />
                      </a>
                  </li>
                 </ul>
              </div> */}
            </div>
          </div>
        </footer>
      </div>
    }
    </Translation>
  )
}
class AuthFooter extends Component {
  state = {};

  componentDidMount() {
        var footerHeight = $("#landing-footer").outerHeight();
        var deviceheight = $(window).outerHeight();
        var contentheight = deviceheight - footerHeight - 70;
        $(".landing-main-wrapper").css("min-height", contentheight);
    }
  render() {
    return (
     <MyAuthFooter />
    );
  }
}

export default AuthFooter;

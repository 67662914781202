import React from "react";

const BusinessDetailsModel = ({
  getBusinessDetailsData,
  businessDetailsInput,
  buttonDisable,
  loadingButtonContent,
  saveBusinessDetails,
}) => {
  return (
    <div
      className="modal fade edit-business-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="withdraw-modaltitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="withdraw-modaltitle">
              Edit Business Information
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="theme-form" autoComplete="off">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="Business Name"
                  name="business_name"
                  value={businessDetailsInput.business_name}
                  onChange={getBusinessDetailsData}
                  autoComplete="off"
                />
              </div>
              <div className="row form-group resp-block">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    name="first_name"
                    value={businessDetailsInput.first_name}
                    onChange={getBusinessDetailsData}
                    autoComplete="off"
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last name"
                    name="last_name"
                    value={businessDetailsInput.last_name}
                    onChange={getBusinessDetailsData}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="Address"
                  name="address"
                  value={businessDetailsInput.address}
                  onChange={getBusinessDetailsData}
                  autoComplete="off"
                />
              </div>
              <div className="row form-group resp-block">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    name="mobile"
                    value={businessDetailsInput.mobile}
                    onChange={getBusinessDetailsData}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="Email Address"
                  name="email"
                  value={businessDetailsInput.email}
                  onChange={getBusinessDetailsData}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="Website"
                  name="website"
                  value={businessDetailsInput.website}
                  onChange={getBusinessDetailsData}
                />
              </div>
              <div className="row resp-block-1">
                <div className="col resp-mrg-btm-rem">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tax ID"
                    name="tax_number"
                    value={businessDetailsInput.tax_number}
                    onChange={getBusinessDetailsData}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Additional information"
                    name="additional_information"
                    value={businessDetailsInput.additional_information}
                    onChange={getBusinessDetailsData}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-quaternary width-120"
              disabled={buttonDisable}
              onClick={saveBusinessDetails}
            >
              {loadingButtonContent != null ? loadingButtonContent : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetailsModel;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchWithDrawalsStart } from "../../store/actions/WithDrawAction";
import EmptyData from "../Handler/EmptyData";

const WalletWithdrawSec = (props) => {
  useEffect(() => {
    if (props.withDraw.withDrawals.loading)
      props.dispatch(fetchWithDrawalsStart());
  }, []);
  const { withDrawals } = props.withDraw;
  return (
    <div className="col-md-6 full-mg-width">
      <div className="w-box">
        <h3 className="sub-title">Withdraw</h3>
        {withDrawals.loading ? (
          "Loading..."
        ) : withDrawals.data.history.length > 0 ? (
          <>
            {withDrawals.data.history.map((data) => (
              <div className="flex-data border-bottom mt-4">
                <div className="deposit-details">
                  <div className="user-img">
                    <img src={data.withdraw_picture} />
                  </div>
                  <div className="user-details">
                    <h5 className="name">
                      #<b>{data.user_withdrawal_unique_id}</b>
                    </h5>
                    <p className="desc">{data.status_formatted}</p>
                  </div>
                </div>
                <div className="deposit-amount">
                  <p className="negative-amount">
                    - {data.requested_amount_formatted}
                  </p>
                </div>
              </div>
            ))}
            <div className="view-more-link">
              <Link to={"/transaction"} className="link">
                View More<i className="fas fa-chevron-right ml-1"></i>
              </Link>
            </div>
          </>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  withDraw: state.withDraw,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletWithdrawSec);

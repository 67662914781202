import React, { Component } from "react";
import { connect } from "react-redux";
import { displayLogin } from "../../store/actions/CommonAction";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

import {
  getRegisterInputData,
  userRegisterStart,
} from "../../store/actions/UserAction";

import {
  fetchCountryDetailsStart
} from "../../store/actions/HTPAction";

const $ = window.$;

class RegisterIndex extends Component {

  state = {
    loading: true,
    RegisterInputData: {},
  };

  componentDidMount() {
      this.props.dispatch(fetchCountryDetailsStart());
      $('#country_state').hide();
  }
 
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getRegisterInputData(input.name, input.value));
  };

  showLogin = (event) => {
    $('#registerModal').modal('hide');
    this.props.dispatch(displayLogin());
  };

  handleRegisterSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(userRegisterStart());
  };

  setCountry = () => {
    const countryList = [];
    const countryStateData = this.props.countryList.data;
    let k = Object.keys(countryStateData);
    countryList.push(<option value="" defaultValue>Select Country</option>);
    for(let i = 0, len = k.length; i < len; i++) {
      let key = k[i];
      if (countryStateData[key]) {
        countryList.push(<option value={countryStateData[key].iso3}>{countryStateData[key].name}</option>);
      }
    }
    return countryList;
  };

  handleCountryChange = ({ currentTarget: input }) => {
    $('#country_state').show();
    this.props.dispatch(getRegisterInputData(input.name, input.value));
    this.setCountryState(input.value);
  };

  handleCountryStateChange = ({ currentTarget: input }) => {
    this.props.dispatch(getRegisterInputData(input.name, input.value));
  };
  
  setCountryState = (countrySelected = "") => {
    const countryStateData = this.props.countryList.data;
    const stateList = [];
    let k = Object.keys(countryStateData);
    for(let i = 0, len = k.length; i < len; i++) {
      let key = k[i];
      if (countryStateData[key]) {
        if (countrySelected === countryStateData[key].iso3) {
          stateList.push(<option value="">Select State</option>);
          let obj = countryStateData[key].states;
           for (let c = 0; c<obj.length; c ++){
              stateList.push(<option value={obj[c].id}>{obj[c].name}</option>);
           }
        }
      }
    }
    return stateList;
  };


  render() {
    console.log();
    const inputData = this.props.inputData.registerInputData;
    const showSignup = this.props.common.displaySignup ? "show" : "";
    const countryList = this.setCountry();
    const stateList  = this.setCountryState(inputData.data.country_code);
    return (
      <div
        className={"modal fade signup signup-sec " + showSignup}
        role="dialog"
        id="registerModal"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close btn-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Link to="/" className="l-logo">
                      <img
                        src={configuration.get("configData.site_logo")}
                        alt={configuration.get("configData.site_name")}
                      />
                    </Link>
                    <div className="signup-content">
                      <h4 className="title text-left">Sign Up</h4>
                      <form autoComplete="off">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="first_name"
                            value={inputData.data.first_name}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-user"></i>
                            </span>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="last_name"
                            value={inputData.data.last_name}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-user"></i>
                            </span>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            name="mobile"
                            value={inputData.data.mobile}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-phone"></i>
                            </span>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={inputData.data.email}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </div>
                        </div>
                        <div className="input-group mb-4">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={inputData.data.password}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <select name="country_code" 
                            id="country_code" 
                            className="form-control form-select-lg" 
                            value={inputData.data.country}
                            onChange={(values) => this.handleCountryChange(values)}
                          >
                            {countryList}
                          </select>
                        </div>
                        <div className="form-group">
                            <select name="country_state" 
                            id="country_state" 
                            className="form-control form-select-lg" 
                            value={inputData.data.country_state}
                            onChange={(values) => this.handleCountryStateChange(values)}
                            >
                              {stateList}
                            </select>
                        </div>
                        <button
                          className="btn btn-primary mb-3"
                          href="#"
                          onClick={this.handleRegisterSubmit}
                          disabled={this.props.inputData.buttonDisable}
                        >
                          {this.props.inputData.loadingButtonContent != null
                            ? this.props.inputData.loadingButtonContent
                            : "Register"}
                        </button>
                      </form>
                      <h6 className="resp-z-index">
                        Already have an account?
                        <span className="ml-2">
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target=".login"
                            onClick={this.showLogin}
                          >
                            Login
                          </a>
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.users,
  countryList: state.htp.countryList,
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RegisterIndex);

import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_DISPUTES_START,
  SEND_DISPUTE_REQUEST_START,
  CANCEL_DISPUTE_REQUEST_START,
  FETCH_SINGLE_DISPUTE_START,
  FETCH_CLOSED_CASE_START,
  FETCH_OPENED_CASE_START,
  GET_SINGLE_DISPUTE_MESSAGES_START,
  SEND_DISPUTE_MESSAGE_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  fetchDisputesSuccess,
  fetchDisputesFailure,
  sendDisputeRequestSuccess,
  sendDisputeRequestFailure,
  cancelDisputeRequestSuccess,
  cancelDisputeRequestFailure,
  fetchSingleDisputeSuccess,
  fetchSingleDisputeFailure,
  fetchDisputesStart,
  fetchOpenedCaseSuccess,
  fetchOpenedCaseFailure,
  fetchClosedCaseSuccess,
  fetchClosedCaseFailure,
  getSingleDisputeMessagesSuccess,
  getSingleDisputeMessagesFailure,
  sendDisputeMessageSuccess,
  sendDisputeMessageFailure,
  fetchOpenedCaseStart,
  getSingleDisputeMessagesStart,
} from "../actions/DisputeAction";
import { fetchSentPaymentTransactionStart } from "../actions/TransactionAction";

function* fetchDisputesAPI() {
  try {
    const response = yield api.postMethod("disputes_index");

    if (response.data.success) {
      yield put(fetchDisputesSuccess(response.data));
    } else {
      yield put(fetchDisputesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchDisputesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchOpenedCasesAPI() {
  try {
    const response = yield api.postMethod("disputes_opened");

    if (response.data.success) {
      yield put(fetchOpenedCaseSuccess(response.data));
    } else {
      yield put(fetchOpenedCaseFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchOpenedCaseFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchClosedCasesAPI() {
  try {
    const response = yield api.postMethod("disputes_closed");

    if (response.data.success) {
      yield put(fetchClosedCaseSuccess(response.data));
    } else {
      yield put(fetchClosedCaseFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchClosedCaseFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getSingleDisputeMsgAPI() {
  try {
    const inputData = yield select((state) => state.dispute.getMsges.inputData);
    const response = yield api.postMethod("disputes_messages", inputData);

    if (response.data.success) {
      yield put(getSingleDisputeMessagesSuccess(response.data));
    } else {
      yield put(getSingleDisputeMessagesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getSingleDisputeMessagesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendMessageAPI() {
  try {
    const inputData = yield select((state) => state.dispute.sendMsg.inputData);
    const response = yield api.postMethod("disputes_messages_save", inputData);

    if (response.data.success) {
      yield put(sendDisputeMessageSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(
        getSingleDisputeMessagesStart({
          user_dispute_id: inputData.user_dispute_id,
        })
      );
    } else {
      yield put(sendDisputeMessageFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sendDisputeMessageFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendDisputeRequestAPI() {
  try {
    const inputData = yield select(
      (state) => state.dispute.sendDispute.inputData
    );
    const response = yield api.postMethod("disputes_send", inputData);

    if (response.data.success) {
      yield put(sendDisputeRequestSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchDisputesStart());
      yield put(fetchSentPaymentTransactionStart());
    } else {
      yield put(sendDisputeRequestFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sendDisputeRequestFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* cancelDisputeRequestAPI() {
  try {
    const inputData = yield select(
      (state) => state.dispute.cancelDispute.inputData
    );
    const response = yield api.postMethod("disputes_cancel", inputData);

    if (response.data.success) {
      yield put(cancelDisputeRequestSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchOpenedCaseStart());
    } else {
      yield put(cancelDisputeRequestFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(cancelDisputeRequestFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleDisputeAPI() {
  try {
    const inputData = yield select(
      (state) => state.dispute.singleDispute.inputData
    );
    const response = yield api.postMethod("disputes_view", inputData);

    if (response.data.success) {
      yield put(fetchSingleDisputeSuccess(response.data));
    } else {
      yield put(fetchSingleDisputeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleDisputeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_DISPUTES_START, fetchDisputesAPI)]);
  yield all([yield takeLatest(FETCH_CLOSED_CASE_START, fetchClosedCasesAPI)]);
  yield all([yield takeLatest(FETCH_OPENED_CASE_START, fetchOpenedCasesAPI)]);
  yield all([
    yield takeLatest(GET_SINGLE_DISPUTE_MESSAGES_START, getSingleDisputeMsgAPI),
  ]);
  yield all([yield takeLatest(SEND_DISPUTE_MESSAGE_START, sendMessageAPI)]);
  yield all([
    yield takeLatest(SEND_DISPUTE_REQUEST_START, sendDisputeRequestAPI),
  ]);
  yield all([
    yield takeLatest(CANCEL_DISPUTE_REQUEST_START, cancelDisputeRequestAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_DISPUTE_START, fetchSingleDisputeAPI),
  ]);
}

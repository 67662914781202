import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchWalletDetailsStart, 
  fetchWalletAccountTypeStart,
  addNewWalletStart  } from "../../../store/actions/WalletAction";

class AddAccountIndex extends Component {

  state = {
    loading: true,
    addWalletInputData: {},
  };

  componentDidMount() {
    if (this.props.wallet.walletData.loading) {
      this.props.dispatch(fetchWalletDetailsStart());
    }
    if (this.props.wallet.walletAccountType.loading) {
      this.props.dispatch(fetchWalletAccountTypeStart());
    }
  }

  setAccountTypeList = () => {
    const options = [];
    const accountData = this.props.wallet.walletAccountType.data;
    let k = Object.keys(accountData);
    options.push(<option defaultValue>Select Account Type</option>);
    for(let i = 0, len = k.length; i < len; i++) {
      let key = k[i];
      if (accountData[key]) {
        options.push(<option value={accountData[key].currency+"-"+accountData[key].name}>{accountData[key].name}</option>);
      }
    }
    return options;
  };

  handleAccountTypeChange = ({ currentTarget: input }) => {
    const addWalletInputData = { ...this.state.addWalletInputData };
    if (input.name != 'wallet_type'){
      let result = input.value.split("-");
      addWalletInputData['wallet_code'] = result[0] ;
      addWalletInputData['wallet_name'] = result[1] ;
    }else{
      addWalletInputData[input.name] = input.value;
    }
    this.setState({ addWalletInputData });
  };

  handleAddNewWalletSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(addNewWalletStart(this.state.addWalletInputData));
  };

  render() {
    const inputData = this.props.wallet.addWalletAccountInput.data;
    const accountTypeList = this.setAccountTypeList();
    return (
      <div className="main-wrapper add-money xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Add New Wallet</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="add-money-box">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="card-wrapper">
                          <div className="card-content">
                            <div className="a-box">
                              <h5>Select Wallet Account Type</h5>
                              <div className="pt-3">
                              <select name="wallet_code" 
                                className="form-control form-select-lg" 
                                value={inputData.wallet_currency}
                                onChange={(values) => this.handleAccountTypeChange(values)}
                              >
                                {accountTypeList}
                              </select>
                              </div>
                              <div className="pt-3">
                                <input
                                  type="radio"
                                  name="wallet_type"
                                  value="PA"
                                  onChange={(values) => this.handleAccountTypeChange(values)}
                                />
                                <span className="name font-weight-bold"> Personal Account</span>
                                <p><small>Make wallet using your personal.</small></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-lg"></div>
                    <div className="row">
                        <div className="col-md-9 resp-align-center d-flex justify-content-center">
                          <button
                            className="btn btn-quaternary width-120 res-md-mrg-btm resp-sm-mrg-btm"
                            onClick={this.handleAddNewWalletSubmit}
                            disabled={
                              this.props.wallet.addWalletAccountInput.buttonDisable
                            }
                          >
                            {this.props.wallet.addWalletAccountInput
                              .loadingButtonContent != null
                              ? this.props.wallet.addWalletAccountInput
                                  .loadingButtonContent
                              : "Submit"} 
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  wallet: state.wallet,
  wallet_account: state.wallet.addWalletAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddAccountIndex);

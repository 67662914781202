import React, { Component } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class HeaderIndex extends Component {
  state = {};
  render() {
    return (
      <header className="header">
        <nav className="navbar-1 navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <div className="resp-flex">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon nav-icon-sub"></span>
              </button>
              <Link className="navbar-brand d-block d-lg-none" to={""}>
                <img
                  src={configuration.get("configData.site_logo")}
                  className="logo"
                  title={configuration.get("configData.site_name")}
                />
              </Link>
            </div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav ml-auto mt-lg-0">
                <li className="nav-item">
                  <Link to={"/profile"}>
                    <img
                      src={localStorage.getItem("user_picture")}
                      className="user-img"
                    />
                  </Link>
                </li>
                <div className="sidebar d-block d-lg-none" id="sidebar-sub">
                  <ul className="list-unstyled list">
                    <li>
                      <Link to={"/"}>
                        <i className="fas fa-home mr-2"></i>Home
                      </Link>
                    </li>
                    <li>
                      <Link to={"/send-money"}>
                        <i className="fas fa-exchange-alt mr-2"></i>Send Money
                      </Link>
                    </li>
                    <li>
                      <Link to={"/wallet"}>
                        <i className="fas fa-wallet mr-2"></i>Wallet
                      </Link>
                    </li>
                    <li>
                      <Link to={"/manage-invoice"}>
                        <i className="fas fa-file-invoice mr-2"></i>Invoice
                      </Link>
                    </li>
                    <li>
                      <Link to={"/resolution-center"}>
                        <i className="fas fa-building mr-2"></i>Resolution
                        Center
                      </Link>
                    </li>
                    <li>
                      <Link to={"/profile"}>
                        <i className="fas fa-user-circle mr-2"></i>Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={"/logout"}>
                        <i className="fas fa-sign-out-alt mr-2"></i>Logout
                      </Link>
                    </li>
                  </ul>
                  <div className="sidebar-footer">
                    <h6>
                      Copyright ©{new Date().getFullYear()}{" "}
                      {configuration.get("configData.site_name")}
                    </h6>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default HeaderIndex;

import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";
import { SEND_MONEY_START, SEARCH_USER_START } from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  sendMoneySuccess,
  sendMoneyFailure,
  searchUserSuccess,
  searchUserFailure,
} from "../actions/SendMoneyAction";

function* searchUserAPI() {
  try {
    const inputData = yield select(
      (state) => state.sendMoney.userList.inputData
    );
    const response = yield api.postMethod("users_search", inputData);
    if (response.data.success) {
      yield put(searchUserSuccess(response.data.data));
    } else {
      yield put(searchUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(searchUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendMoneyAPI() {
  try {
    const inputData = yield select(
      (state) => state.sendMoney.sendMoney.inputData
    );
    if (!inputData.to_user_id || !inputData.amount) {
      yield put(sendMoneyFailure("Please fill all the details"));
      const notificationMessage = getErrorNotificationMessage(
        "Please fill all the details"
      );
      yield put(createNotification(notificationMessage));
    } else {
      const response = yield api.postMethod("wallets_send_money", inputData);
      if (response.data.success) {
        yield put(sendMoneySuccess(response.data.data));
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/transaction");
      } else {
        yield put(sendMoneyFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(sendMoneyFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(SEND_MONEY_START, sendMoneyAPI),
    yield takeLatest(SEARCH_USER_START, searchUserAPI),
  ]);
}

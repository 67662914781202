import React from "react";

const DisputeModel = (props) => {
  return (
    <div
      className="modal fade dispute-form"
      tabindex="-1"
      role="dialog"
      aria-labelledby="withdraw-modaltitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="withdraw-modaltitle">
              Dispute
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="desc">
              Dispute the Paul Wesley transactions on{" "}
              {props.disputeTransaction.paid_date}. The Amount is{" "}
              {props.disputeTransaction.currency}
              {props.disputeTransaction.requested_amount}
            </p>
            <form className="theme-form" autoComplete="off">
              <div className="form-group">
                <textarea
                  className="form-control input-form"
                  rows="3"
                  name="message"
                  value={props.disputeInputData.message}
                  onChange={props.handleChange}
                  autoComplete="off"
                ></textarea>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-quaternary width-120"
              disabled={props.buttonDisable}
              onClick={props.handleSubmit}
            >
              {props.loadingButtonContent != null
                ? props.loadingButtonContent
                : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeModel;

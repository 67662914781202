import React, { Component } from "react";
import LoginIndex from "../../Auth/LoginIndex";
import RegisterIndex from "../../Auth/RegisterIndex";
import ForgotPassword from "../../Auth/ForgotPassword";
import { connect } from "react-redux";
import { displayLogin, displaySignup } from "../../../store/actions/CommonAction";
import { useTranslation, Translation } from "react-i18next";

const $ = window.$;

function MyAboutUsIndex () {
  const { t } = useTranslation('translation');
  return (
    <Translation>
      { (t, { i18n }) =>
        <>
        <section class="page-header sm-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <h1 class="title">{t('page.aboutus.h1')} </h1>
              </div>
            </div>
          </div>
        </section>
        <section class="page-content">
          <div class="container">
            <div class="row">
              <div class="col-md-12 resp-sm-mrg-btm">
                <h3>{t('page.aboutus.h2')}</h3>
                <p>{t('page.aboutus.c1')}</p>
                <p>{t('page.aboutus.c2')}</p>
                <p>{t('page.aboutus.c3')}</p>
                <p>{t('page.aboutus.c4')}</p>
                <p>{t('page.aboutus.c5')}</p>
              </div>
            </div>
          </div>
        </section>
        </>
      }
    </Translation>
  )
}

class AboutUsIndex extends Component {
  handleLogin = (event) => {
    event.preventDefault();
    this.props.dispatch(displayLogin());
  };
  handleSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  showSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  render() {
    return (
      <>
       <MyAboutUsIndex />
        <LoginIndex />
        <RegisterIndex />
        <ForgotPassword />
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AboutUsIndex);

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchSingleDisputeStart,
  cancelDisputeRequestStart,
  getSingleDisputeMessagesStart,
  sendDisputeMessageStart,
} from "../../../store/actions/DisputeAction";

class SingleCaseDetailsIndex extends Component {
  state = {
    inputData: {
      user_dispute_id: "",
    },
  };
  componentDidMount() {
    this.props.dispatch(
      fetchSingleDisputeStart({ user_dispute_id: this.props.match.params.id })
    );
    this.props.dispatch(
      getSingleDisputeMessagesStart({
        user_dispute_id: this.props.match.params.id,
      })
    );
    this.setState({
      inputData: {
        user_dispute_id: this.props.match.params.id,
      },
    });
  }

  cancelDispute = (event) => {
    event.preventDefault();
    this.props.dispatch(
      cancelDisputeRequestStart({ user_dispute_id: this.props.match.params.id })
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    inputData[input.name] = input.value;
    this.setState({ inputData });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const inputData = { ...this.state.inputData };
    inputData["user_dispute_id"] = this.props.match.params.id;
    this.setState({ inputData });
    setTimeout(() => {
      this.props.dispatch(sendDisputeMessageStart(this.state.inputData));
      this.setState({
        inputData: {
          message: "",
        },
      });
    }, 500);
  };

  render() {
    const { singleDispute, getMsges } = this.props.dispute;
    if (singleDispute.loading) {
      return "Loading...";
    } else {
      return (
        <div className="main-wrapper single-dispute xs-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="main-title">Dispute</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="single-dispute-message-box">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="m-left">
                        <div className="message-left">
                          <h2 className="single-d-title">Transaction ID</h2>
                          <p className="single-d-desc">
                            {
                              singleDispute.data.data.user_dispute
                                .user_dispute_unique_id
                            }
                          </p>
                        </div>
                        <div className="message-left">
                          <h2 className="single-d-title">Transaction Date</h2>
                          <p className="single-d-desc">
                            {singleDispute.data.data.user_dispute.created_at}
                          </p>
                        </div>
                        <div className="message-left">
                          <h2 className="single-d-title">Recepient</h2>
                          <p className="single-d-desc">
                            {singleDispute.data.data.user_dispute.username}
                          </p>
                        </div>
                        <div className="message-left">
                          <h2 className="single-d-title">Amount</h2>
                          <p className="single-d-desc">
                            {
                              singleDispute.data.data.user_dispute
                                .amount_formatted
                            }
                          </p>
                        </div>
                        <div className="message-left">
                          <h2 className="single-d-title">Status</h2>
                          <p className="success-btn">
                            {
                              singleDispute.data.data.user_dispute
                                .status_formatted
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="message-right">
                        <div className="chat-header">
                          <h3 className="c-title">Customer Support</h3>
                          {singleDispute.data.data.user_dispute
                            .cancel_btn_status === 1 ? (
                            <a
                              href="#"
                              className="btn btn-quaternary"
                              onClick={this.cancelDispute}
                            >
                              Cancel
                            </a>
                          ) : null}
                        </div>
                        <div
                          className="chat-m-body"
                          style={{ "min-height": "20rem" }}
                        >
                          {getMsges.loading ? (
                            "Loading..."
                          ) : getMsges.data.data.messages.length > 0 ? (
                            getMsges.data.data.messages.map((msg) => (
                              <div
                                className={
                                  msg.message_align == "sent"
                                    ? "chat-body-left"
                                    : "chat-body-right"
                                }
                              >
                                <div className="chat-user-img">
                                  <img
                                    src={msg.user_picture}
                                    className="user-img"
                                  />
                                </div>
                                <div className="chat-message">
                                  <p className="chat-m">{msg.message}</p>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p
                              className="text-muted text-center"
                              style={{ padding: "20px" }}
                            >
                              No messages
                            </p>
                          )}
                        </div>
                        <div className="chat-footer">
                          <form onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control input-form"
                                placeholder="Enter Message"
                                onChange={this.handleChange}
                                name="message"
                                value={this.state.inputData.message}
                                autoComplete="off"
                              />
                              <button
                                type="submit"
                                className="input-group-append"
                              >
                                <span className="input-group-text">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/assets/img/send.svg"
                                    }
                                  />
                                </span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-center"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToPros = (state) => ({
  dispute: state.dispute,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SingleCaseDetailsIndex);

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchUserDetailsStart,
  editUserDetails,
  updateUserDetailsStart,
} from "../../../store/actions/UserAction";

import {
  fetchCountryDetailsStart
} from "../../../store/actions/HTPAction";

const $ = window.$;

class EditProfile extends Component {
  state = {
    loading: true,
    inputData: {},
    imagePreviewUrl: null,
  };

  componentDidMount() {
    if (this.props.userData.profile.loading)
      this.props.dispatch(fetchUserDetailsStart());
      this.props.dispatch(fetchCountryDetailsStart());
  }
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(editUserDetails(input.name, input.value));
  };

  handleChangeImage = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "picture")
        this.setState({
          imagePreviewUrl: reader.result,
        });
    };
    if (file) {
      reader.readAsDataURL(file);
      setTimeout(() => {
        this.props.dispatch(updateUserDetailsStart(input.files[0]));
      }, 1000);
    }
  };

  updateProfile = (event) => {
    event.preventDefault();
    this.props.dispatch(updateUserDetailsStart());
  };

  handleCountryChange = ({ currentTarget: input }) => {
    // const inputData = { ...this.state.inputData };
    // inputData[input.name] = input.value;
    // this.setState({ inputData });
    //this.setCountryState(input.value);
    this.props.dispatch(editUserDetails(input.name, input.value));
    //this.setCountryState(input.value);
  };

  handleCountryStateChange = ({ currentTarget: input }) => {
    console.log(input.name + ': ' + input.value);
    this.props.dispatch(editUserDetails('country_state', input.value));
  };

  setCountry = (countrySelected = '') => {
    
    let options = [];

    if (countrySelected === '') {
      countrySelected = this.props.userData.profile.data.country_code;
    }

    if (this.props.countryList.data) {
      let countryData = this.props.countryList.data;
      let length = Object.keys(countryData).length;
      options.push(<option value="" >Select Country</option>);
      for(let i = 0; i < length; i++) {
        if (countryData[i].iso3 === countrySelected) {
          options.push(<option value={countryData[i].iso3} selected>{countryData[i].name}</option>);
        }else{
          options.push(<option value={countryData[i].iso3}>{countryData[i].name}</option>);
        }
      }
    }
    return options;
  };

  setCountryState = () => {

    let options = [];
    let countrySelected = '';
    let stateSelected = '';

    const inputData = { ...this.state.inputData };

    if (typeof(inputData.country_code) != 'undefined') {
      countrySelected = inputData.country_code;
    }else {
      countrySelected =  this.props.userData.profile.data.country_code;
    }

    if (typeof(inputData.country_state) != 'undefined') {
      stateSelected = inputData.country_state;
    }else {
      stateSelected =  this.props.userData.profile.data.state;
    }

    console.log(stateSelected);

    if (this.props.countryList.data) {

      let countryData = this.props.countryList.data;
      let length_c = Object.keys(countryData).length;

      for(let i = 0; i < length_c; i++) {
        if (countryData[i].iso3 === countrySelected) {
          let stateData = countryData[i].states;
          let length_s = Object.keys(stateData).length;
          options.push(<option value="">Select state</option>);
          for(let k=0; k < length_s; k++){
            if(stateData[k].id == stateSelected) {
              options.push(<option value={stateData[k].id} selected>{stateData[k].name}</option>);
            }else{
              options.push(<option value={stateData[k].id}>{stateData[k].name}</option>);
            }
          }
          
        }
      }
    }
    return options;
  };
 
  render() {
    const userData  = this.props.userData.profile;
    const countryList  = this.setCountry();
    const stateList = this.setCountryState();
    if (userData.loading === true ) {
      return "Loading...";
    } else {
      return (
        <div className="main-wrapper edit-profile xs-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="main-title">Edit Profile</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="edit-profile-box">
                  <div className="row">
                    <div className="col-md-5 resp-sm-mrg-btm">
                      <div className="edit-profile-details">
                        <div className="edit-profile-img mb-3">
                          <img
                            src={
                              this.state.imagePreviewUrl != null
                                ? this.state.imagePreviewUrl
                                : userData.data.picture
                            }
                            className="ep-img"
                          />
                        </div>
                        <div className="upload-btn-wrapper">
                          <button className="button">Upload a file</button>
                          <input
                            type="file"
                            accept="image/*"
                            name="picture"
                            onChange={this.handleChangeImage}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="edit-profile-info">
                        <h2 className="title-head">Update Details</h2>
                        <form autoComplete="off">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              value={userData.data.first_name}
                              name="first_name"
                              autoComplete="off"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              value={userData.data.last_name}
                              name="last_name"
                              autoComplete="off"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              autoComplete="off"
                              value={userData.data.email}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              name="mobile"
                              value={userData.data.mobile}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              name="address"
                              value={userData.data.address}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-group">
                             <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              value={userData.data.city}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-group">
                            <select name="country_code" 
                              className="form-control form-select-lg" 
                              onChange={(values) => this.handleCountryChange(values)}
                            >
                              {countryList}
                            </select>
                          </div>
                          <div className="form-group">
                              <select name="country_state" 
                              className="form-control form-select-lg" 
                              onChange={(values) => this.handleCountryStateChange(values)}
                              >
                                {stateList}
                              </select>
                            </div>
                          <div className="form-group">
                             <input
                              type="text"
                              className="form-control"
                              placeholder="postcode"
                              name="postcode"
                              value= {userData.data.postcode}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-group">
                             <input
                              type="text"
                              className="form-control"
                              placeholder="Default Currency"
                              name="default_currency"
                              value={userData.data.default_currency_code}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </form>
                        <div className="resp-align-center">
                          <button
                            type="submit"
                            className="btn btn-quaternary"
                            onClick={this.updateProfile}
                            disabled={this.props.userData.buttonDisable}
                          >
                            {this.props.userData.loadingButtonContent != null
                              ? this.props.userData.loadingButtonContent
                              : "Update Profile"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-center"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToPros = (state) => ({
  userData: state.users,
  countryList: state.htp.countryList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProfile);

import React, { Component } from "react";
import AddCardModel from "./AddCardModel";
import { connect } from "react-redux";
import {
  fetchCardDetailsStart,
  selectDefaultCardStart,
  deleteCardStart,
} from "../../../store/actions/CardsAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardsLoader from "../../Loader/CardsLoader";

const stripePromise = loadStripe("pk_test_uDYrTXzzAuGRwDYtu7dkhaF3");

class CardsList extends Component {
  constructor() {
    super();
    this.state = { stripe: null, displayAddCardSection: false };
  }
  componentDidMount() {
    if (this.props.cards.cardDetails.loading)
      this.props.dispatch(fetchCardDetailsStart());
  }

  cardAddedStatusChange = () => {
    this.setState({ displayAddCardSection: false });
  };

  addCardSection = (event) => {
    event.preventDefault();
    this.setState({ displayAddCardSection: true });
  };

  changeDefaultCard = (event, card) => {
    event.preventDefault();
    this.props.dispatch(selectDefaultCardStart(card));
  };

  deleteCard = (event, card) => {
    event.preventDefault();
    this.props.dispatch(deleteCardStart(card));
  };

  render() {
    const { cardDetails } = this.props.cards;
    return (
      <div className="main-wrapper card-details xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Payment Methods</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card-details-box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      {cardDetails.loading ? (
                        <div>
                          <CardsLoader />
                        </div>
                      ) : cardDetails.data.cards.length > 0 ? (
                        cardDetails.data.cards.map((card) => (
                          <div className="col-md-4">
                            <div className="cd-box">
                              <div className="card-details-top">
                                <div className="card-info">
                                  <h5 className="title">
                                    XXXX XXXX XXXX {card.last_four}
                                  </h5>
                                </div>
                                <div className="card-icons">
                                  <img
                                    src={
                                      window.location.origin +
                                      "/assets/img/credit-card.svg"
                                    }
                                    className="card-img"
                                  />
                                </div>
                              </div>
                              <h5 className="title text-muted">
                                {card.card_type}
                              </h5>
                              <div className="card-details-bottom">
                                <div className="card-info">
                                  {card.is_default == 1 ? (
                                    <p className="card-link-1 text-success">
                                      Default Card
                                    </p>
                                  ) : (
                                    <a
                                      href="#"
                                      onClick={(event) =>
                                        this.changeDefaultCard(event, card)
                                      }
                                    >
                                      <p className="card-link-1">
                                        Set as Default
                                      </p>
                                    </a>
                                  )}
                                </div>
                                {card.is_default != 1 ? (
                                  <div className="card-icons">
                                    <a
                                      href="#"
                                      onClick={(event) =>
                                        this.deleteCard(event, card)
                                      }
                                    >
                                      <img
                                        src={
                                          window.location.origin +
                                          "/assets/img/delete.svg"
                                        }
                                        className="card-img-delete mr-2"
                                      />
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-data-found-table-sec">
                          <img
                            src={
                              window.location.origin +
                              "/assets/img/no-data-found.svg"
                            }
                            className="no-data-found-table"
                          />
                          <p className="title">No results Found</p>
                        </div>
                      )}
                    </div>
                    <div className="space-mg"></div>
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className="dash-box flex-center text-center"
                          data-toggle="modal"
                          data-target="#add-card"
                        >
                          <img
                            src={
                              window.location.origin +
                              "/assets/img/add-icon.svg"
                            }
                          />
                          <p className="desc">Add Card</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
        <Elements stripe={stripePromise}>
          <AddCardModel />
        </Elements>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  cards: state.cards,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CardsList);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

import {
  getLoginInputData,
  userLoginStart,
} from "../../store/actions/UserAction";
import {
  displaySignup,
  displayForgotPassword,
} from "../../store/actions/CommonAction";
const $ = window.$;

class LoginIndex extends Component {
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getLoginInputData(input.name, input.value));
  };
  handleLoginSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(userLoginStart());
  };
  showSignup = (event) => {
    $('#loginModal').modal('hide');
    this.props.dispatch(displaySignup());
  };

  showForgotPassword = (event) => {
    $('#loginModal').modal('hide');
    this.props.dispatch(displayForgotPassword());
  };

  render() {
    const inputData = this.props.inputData.loginInputData;
    const showLogin = this.props.common.displayLogin ? "show" : "";
    return (
      <div className={"modal fade login login-sec " + showLogin} id="loginModal" role="dialog">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close btn-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Link to="/" className="l-logo">
                      <img
                        src={configuration.get("configData.site_logo")}
                        alt={configuration.get("configData.site_name")}
                      />
                    </Link>
                    <div className="login-content">
                      <h4 className="title text-left">Login</h4>
                      <form autoComplete="off">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={inputData.data.email}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </div>
                        </div>
                        <div className="input-group mb-4">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={inputData.data.password}
                            onChange={this.handleChange}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </span>
                          </div>
                        </div>
                        <button
                          className="btn btn-primary mb-3"
                          href="#"
                          onClick={this.handleLoginSubmit}
                          disabled={this.props.inputData.buttonDisable}
                        >
                          {this.props.inputData.loadingButtonContent != null
                            ? this.props.inputData.loadingButtonContent
                            : "Login"}
                        </button>
                        {/* <div className="custom-control custom-checkbox mr-sm-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="login-check"
                          />
                          <label
                            className="custom-control-label"
                            for="login-check"
                          >
                            Remember Password
                          </label>
                        </div> */}
                      </form>
                      <h6 className="resp-z-index">
                        Don't have an account?
                        <span className="ml-2">
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target=".signup"
                            onClick={this.showSignup}
                          >
                            Sign Up
                          </a>
                        </span>
                        <br />
                        <span>
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target=".forgot-password"
                            className="resp-z-index"
                            onClick={this.showForgotPassword}
                          >
                            Forgot Password?
                          </a>
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToPros = (state) => ({
  inputData: state.users,
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginIndex);

import React, { Component } from "react";
import { Notify } from "react-redux-notify";
import SideBarIndex from "./sidebar/SideBarIndex";
import HeaderIndex from "./header/HeaderIndex";
import AfterLoginFooter from "./footer/AfterLoginFooter";
import { Offline } from "react-detect-offline";

class MainLayout extends Component {
  render() {
    return (
      <body className="body-new">
        <SideBarIndex />
        <div className="main-content">
          <HeaderIndex />
          <Notify position="TopRight" />
          <Offline>You're offline right now. Check your connection.</Offline>
          <div className="main-wrap-sec">{React.cloneElement(this.props.children)}</div>
          <AfterLoginFooter />
        </div>
      </body>
    );
  }
}

export default MainLayout;

import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import api from "../../Environment";
import renderHTML from "react-render-html";
import { connect } from "react-redux";
import { fetchSinglePageStart } from "../../store/actions/PageAction";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ForgotPassword from "../Auth/ForgotPassword";

class StaticIndex extends Component {
  state = {
    pageData: null,
    loadingPageData: true,
    active: null,
    displayContent: null,
    currentPageTitle: null,
  };
  componentDidMount() {
    this.setState({ currentPageTitle: this.props.match.params.title });
    setTimeout(() => {
      this.props.dispatch(
        fetchSinglePageStart({ unique_id: this.props.match.params.title })
      );
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.title !== prevProps.match.params.title) {
      // call the fetch function again
      this.props.dispatch(
        fetchSinglePageStart({ unique_id: this.props.match.params.title })
      );
    }
  }

  getPageDetails = () => {
    let pageData = { unique_id: this.state.currentPageTitle };
    console.log(pageData);
    api.postMethod("pages_list", pageData).then((response) => {
      if (response.data.success) {
        this.setState({
          pageData: response.data.data,
          loadingPageData: false,
        });
        setTimeout(() => {
          // this.setState({
          //   displayContent: this.state.pageData,
          //   currentPageTitle: "",
          // });
        }, 1000);
      } else {
      }
    });
  };

  changePage = (event, page) => {
    event.preventDefault();
    this.setState({ displayContent: page });
  };
  render() {
    // const { displayContent } = this.state;

    const displayContent = this.props.page.pageData;

    return (
      <div className="main-wrap-sec-1">
        <div className="static-page-sec sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {displayContent.loading ? (
                  "Loading..."
                ) : (
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">
                        {displayContent.data.title}
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="main-section">
                        {renderHTML(displayContent.data.description)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <LoginIndex />
        <RegisterIndex />
        <ForgotPassword />
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  page: state.page,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticIndex);

import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  SAVE_BUSINESS_DETAILS_START,
  FETCH_BUSINESS_DETAILS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  saveBusinessDetailsSuccess,
  saveBusinessDetailsFailure,
  fetchBusinessDetailsSuccess,
  fetchBusinessDetailsFailure,
} from "../actions/BusinessDetailsAction";
import { saveInvoiceId } from "../actions/CreateInvoiceAction";

function* saveBusinessDetailsAPI() {
  try {
    const inputData = yield select(
      (state) => state.businessDetails.businessDetailsInput.data
    );
    const response = yield api.postMethod(
      "generated_invoices_info_save",
      inputData
    );

    if (response.data.success) {
      yield put(saveBusinessDetailsSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(saveInvoiceId(response.data.data.generated_invoice_id));
    } else {
      yield put(saveBusinessDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveBusinessDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchBusinessDetailsAPI() {
  try {
    const response = yield api.postMethod("invoices_business_information");

    if (response.data.success) {
      yield put(fetchBusinessDetailsSuccess(response.data.data));
    } else {
      yield put(fetchBusinessDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBusinessDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(SAVE_BUSINESS_DETAILS_START, saveBusinessDetailsAPI),
    yield takeLatest(FETCH_BUSINESS_DETAILS_START, fetchBusinessDetailsAPI),
  ]);
}

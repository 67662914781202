import React, { Component } from "react";
import { connect } from "react-redux";
import {
  searchUserStart,
  sendMoneyStart,
} from "../../../store/actions/SendMoneyAction";
import { fetchUserDetailsStart } from "../../../store/actions/UserAction";
import { fetchWalletDetailsStart } from "../../../store/actions/WalletAction";

const $ = window.$;

class SendMoneyIndex extends Component {
  state = {
    sendMoneyInput: {},
    searchUser: "",
    displayUserList: "",
  };

  componentDidMount() {
    if (this.props.profile.loading) {
      this.props.dispatch(fetchUserDetailsStart());
    }
    if (this.props.wallet.walletData.loading) {
      this.props.dispatch(fetchWalletDetailsStart());
    }
  }

  handleChange = ({ currentTarget: input }) => {
    this.setState({ searchUser: input.value });
    if (this.state.searchUser.length > 1) {
      this.props.dispatch(
        searchUserStart({ search_key: this.state.searchUser })
      );
      this.setState({ displayUserList: "show" });
    }
    if (this.state.searchUser.length == 0) {
      this.setState({ displayUserList: "" });
    }
  };
  handleChangeAmount = ({ currentTarget: input }) => {
    const sendMoneyInput = { ...this.state.sendMoneyInput };
    sendMoneyInput[input.name] = input.value;
    this.setState({ sendMoneyInput });
  };

  handleChangeWallect = ({ currentTarget: input }) => {
    const sendMoneyInput = { ...this.state.sendMoneyInput };
    let result = input.value.split("-");
    sendMoneyInput['user_wallet_id'] = result[0] ;
    sendMoneyInput['remaining'] = result[1] ;
    sendMoneyInput['currency_code'] = result[2] ;
    $('#currency_text').html(result[2]);
    this.setState({ sendMoneyInput });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const sendMoneyInput = { ...this.state.sendMoneyInput };
    if (sendMoneyInput.remaining < sendMoneyInput.amount) {
        $('#amout_message').html("<p class='text-danger'> Your amount can not more remaining </p>");
    }else{
      this.props.dispatch(sendMoneyStart(this.state.sendMoneyInput));
    }
  };
  chooseUser = (event, user) => {
    event.preventDefault();
    this.setState({ displayUserList: "", searchUser: user.name });
    const sendMoneyInput = { ...this.state.sendMoneyInput };
    sendMoneyInput["to_user_id"] = user.user_id;
    this.setState({ sendMoneyInput });
  };

  setWalletList = () => {
    const walletList =  this.props.wallet.walletData.data.user_wallet;
    const SelectOptions = [];
    SelectOptions.push(<option defaultValue>Select Wallet</option>);
    if (walletList) {
      const options = walletList.map((item) => {
        SelectOptions.push(<option value={item.user_wallet_id + '-' + item.remaining + '-' + item.currency_code  }>{item.currency_name + '  ['+ item.remaining + ' ' + item.currency_code +']' }</option>);
      });
    }
    return SelectOptions;
  };

  render() {
    const sendMoney = this.props.sendMoney;
    const userData  = this.props.userData.profile;
    const walletSelectOption = this.setWalletList();
    return (
      <div className="main-wrapper send-money xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Send Money</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="send-money-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <form className="theme-form" autoComplete="off">
                          <div  className="form-group dropdown">
                            <select name="user_wallet_id"
                                className="form-control form-select-lg"
                                onChange={this.handleChangeWallect}
                              >
                                {walletSelectOption}
                              </select>
                          </div>
                          <div className="form-group dropdown">
                            <input
                              type="text"
                              className="form-control input-form"
                              id="dropdownMenuButton"
                              aria-haspopup="true"
                              aria-expanded="false"
                              placeholder="Search user by name, mobile or email"
                              onChange={this.handleChange}
                              name="search_key"
                              autoComplete="off"
                              value={this.state.searchUser}
                            />
                            <div
                              className={
                                this.state.displayUserList == "show"
                                  ? "dropdown-menu sendmoney-dropdown show"
                                  : "dropdown-menu sendmoney-dropdown"
                              }
                              aria-labelledby="dropdownMenuButton"
                            >
                              {sendMoney.userList.loading
                                ? "Loading..."
                                : sendMoney.userList.data.users.length > 0
                                ? sendMoney.userList.data.users.map((user) => (
                                    <>
                                      <a
                                        href="#"
                                        onClick={(event) =>
                                          this.chooseUser(event, user)
                                        }
                                      >
                                        <div className="send-money-dropdown-content">
                                          <div className="send-money-user-image">
                                            <img
                                              src={user.picture}
                                              className="user-img"
                                            />
                                          </div>
                                          <div className="send-money-user-details">
                                            <h5 className="user-name">
                                              {user.name}
                                            </h5>
                                            <p className="text-muted">
                                              {user.mobile}
                                            </p>
                                          </div>
                                        </div>
                                      </a>
                                      <br />
                                    </>
                                  ))
                                : "No users found"}
                            </div>
                          </div>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span id="currency_text" className="input-group-text"></span>
                            </div>
                            <input
                              type="number"
                              min="0"
                              step="any"
                              className="form-control input-form-1"
                              placeholder="Amount"
                              aria-label="Amount"
                              name="amount"
                              value={this.state.sendMoneyInput.amount || ''}
                              onChange={this.handleChangeAmount}
                            />
                          </div>
                          <div id="amout_message"></div>
                          {this.props.profile.loading ? (
                            "Loading..."
                          ) : this.props.profile.data
                              .is_kyc_document_approved == 2 ? (
                            <button
                              type="submit"
                              className="btn btn-quaternary width-120"
                              onClick={this.handleSubmit}
                              disabled={this.props.sendMoney.buttonDisable}
                            >
                              {this.props.sendMoney.loadingButtonContent != null
                                ? this.props.sendMoney.loadingButtonContent
                                : "Send"}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-quaternary width-120"
                              disabled={true}
                            >
                              Send
                            </button>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={
                        window.location.origin + "/assets/img/send-money.svg"
                      }
                      className="full-img send-money-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  sendMoney: state.sendMoney,
  profile: state.users.profile,
  userData: state.users,
  wallet: state.wallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SendMoneyIndex);

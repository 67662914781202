import React, { Component } from "react";
import LoginIndex from "../../Auth/LoginIndex";
import RegisterIndex from "../../Auth/RegisterIndex";
import ForgotPassword from "../../Auth/ForgotPassword";
import { connect } from "react-redux";

import { displayLogin, displaySignup } from "../../../store/actions/CommonAction";

const $ = window.$;

class DownloadIndex extends Component {
  handleLogin = (event) => {
    event.preventDefault();
    this.props.dispatch(displayLogin());
  };
  handleSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  showSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  render() {
    return (
      <>
        <section class="page-header sm-padding">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <h1 class="title">Download </h1>
              </div>
            </div>
          </div>
        </section>
        <section class="page-content">
          <div class="container">
            <div class="row">
              <div class="col-md-12 resp-sm-mrg-btm">
                <h3>HowToPay Solutions for your Payment billing and ecommerce needs.</h3>
                <p>With over 20 years in the industry, we provide solutions such as POS, Billing, Invoicing and API integration tools to help you run your business more effectively and securely. </p>
                <p>Our cloud Point Of Sale (POS) solution is based on years of development and testing of cutting edge payment solutions. Adding QR, Cloud and Mobile Apps to provide a complete solution. </p>
              </div>
            </div>

            <div class="row resp-sm-mrg-btm">
                <div class="col-6 text-right">
                 <a href="https://play.google.com/store/apps/details?id=com.howtopay.walletapp">
                  <img
                      src={
                        window.location.origin + "/assets/img/download-android.png"
                      }
                      class="download_btn"
                    />

                 </a>
                </div>
              <div class="col-6 text-left">
                <a href="https://apps.apple.com/us/app/howtopay/id1584629533">
                  <img
                      src={
                        window.location.origin + "/assets/img/download_app_store.png"
                      }
                      class="download_btn"
                    />
                </a>
              </div>
            </div>  
          </div>
        </section>
     
        <LoginIndex />
        <RegisterIndex />
        <ForgotPassword />
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DownloadIndex);

import React from "react";

const AddAccountModel = ({
  bankAccount,
  handleChangeBankAccount,
  handleSubmitBankAccount,
  displayAccountAccountModel,
}) => {
  return (
    <div
      className={displayAccountAccountModel ? "modal fade show" : "modal fade"}
      id="add-account-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="add-account-modaltitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="add-account-modaltitle">
              Billing Account
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="theme-form" autoComplete="off">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="Name"
                  name="account_holder_name"
                  autoComplete="off"
                  value={
                    bankAccount.addBankAccountInput.data != undefined
                      ? bankAccount.addBankAccountInput.data.account_holder_name
                      : null
                  }
                  onChange={handleChangeBankAccount}
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="Account Number"
                  name="account_number"
                  autoComplete="off"
                  value={
                    bankAccount.addBankAccountInput.data != undefined
                      ? bankAccount.addBankAccountInput.data.account_number
                      : null
                  }
                  onChange={handleChangeBankAccount}
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="Branch Name"
                  name="title"
                  autoComplete="off"
                  value={
                    bankAccount.addBankAccountInput.data != undefined
                      ? bankAccount.addBankAccountInput.data.title
                      : null
                  }
                  onChange={handleChangeBankAccount}
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="IFSC Code"
                  name="ifsc_code"
                  autoComplete="off"
                  value={
                    bankAccount.addBankAccountInput.data != undefined
                      ? bankAccount.addBankAccountInput.data.ifsc_code
                      : null
                  }
                  onChange={handleChangeBankAccount}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-form"
                  placeholder="Swift Code"
                  name="swift_code"
                  autoComplete="off"
                  value={
                    bankAccount.addBankAccountInput.data != undefined
                      ? bankAccount.addBankAccountInput.data.swift_code
                      : null
                  }
                  onChange={handleChangeBankAccount}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-quaternary"
              onClick={handleSubmitBankAccount}
              disabled={bankAccount.buttonDisable}
            >
              {bankAccount.loadingButtonContent != null
                ? bankAccount.loadingButtonContent
                : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccountModel;

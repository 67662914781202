import React from "react";

const EmptyData = () => {
  return (
    <div className="no-data-found-small-sec">
      <img
        src={window.location.origin + "/assets/img/no-data-found.svg"}
        className="no-data-found-small"
      />
      <p className="title">No results Found</p>
    </div>
  );
};

export default EmptyData;

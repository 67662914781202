import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getKycDocumentStart,
  addKycDocumentStart,
} from "../../../store/actions/KycDocumentAction";
import ProfileLoader from "../../Loader/ProfileLoader";
import { Link } from "react-router-dom";

class KYCDetails extends Component {
  state = {
    loading: true,
    inputData: {},
    imagePreviewUrl: {},
    isKycApproved: false,
  };
  componentDidMount() {
    if (this.props.kycDocument.kycDocDetails.loading)
      this.props.dispatch(getKycDocumentStart());
  }

  handleChangeImage = (event, document) => {
    const inputData = { ...this.state.inputData };
    if (event.currentTarget.type === "file") {
      inputData[event.currentTarget.name] = event.currentTarget.files[0];
      inputData["kyc_document_id"] = document.kyc_document_id;
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = event.currentTarget.files[0];

    reader.onloadend = () => {
      const imagePreviewUrl = { ...this.state.imagePreviewUrl };
      imagePreviewUrl[document.kyc_document_id] = reader.result;
      this.setState({
        imagePreviewUrl,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleSubmit = () => {
    this.props.dispatch(addKycDocumentStart(this.state.inputData));
  };
  render() {
    const { kycDocDetails } = this.props.kycDocument;
    if (kycDocDetails.loading) {
      return (
        <div className="main-wrapper profile xs-padding">
          <div className="container">
            <div className="row">
              <ProfileLoader />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-wrapper kyc-details xs-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="main-title">
                  KYC Details
                  <Link
                    to="/profile"
                    className="btn btn-quaternary f-8 float-right"
                  >
                    Go Back
                  </Link>
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="kyc-details-box">
                  {kycDocDetails.data.data.kyc_documents.length > 0 ? (
                    <>
                      {kycDocDetails.data.data.kyc_documents.map((document) => (
                        <React.Fragment key={document.kyc_document_id}>
                          <div className="kyc-box">
                            <div className="sub-heading">
                              <h4>{document.name}</h4>
                              <p>{document.description}</p>
                            </div>
                            <div className="space-mg"></div>
                            <div className="row">
                              <div className="col-md-6 resp-mrg-btm-rem">
                                <div className="k-box">
                                  <div className="kyc-personal-img">
                                    {this.state.imagePreviewUrl[
                                      document.kyc_document_id
                                    ] != undefined ? (
                                      <img
                                        src={
                                          this.state.imagePreviewUrl[
                                            document.kyc_document_id
                                          ]
                                        }
                                        className="kyc-p-img"
                                        alt={document.name}
                                      ></img>
                                    ) : (
                                      <img
                                        src={
                                          document.user_kyc_document
                                            .document_file
                                            ? document.user_kyc_document
                                                .document_file
                                            : document.picture
                                        }
                                        className="kyc-p-img"
                                        alt={document.name}
                                      ></img>
                                    )}
                                  </div>
                                  <hr />
                                  <div className="kyc-type-info">
                                    <h5 className="kyc-type">
                                      {document.name}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              {this.state.isKycApproved == false ? (
                                <div className="col-md-6">
                                  <div className="upload-doc-box">
                                    <div className="upload-doc-img">
                                      <img
                                        src={
                                          window.location.origin +
                                          "/assets/img/upload-doc.svg"
                                        }
                                        alt="upload-document"
                                      />
                                    </div>
                                    <div className="upload-btn-wrapper">
                                      <button className="button">
                                        Upload Image
                                      </button>
                                      <input
                                        type="file"
                                        name="document_file"
                                        accept="image/*"
                                        onChange={(event) =>
                                          this.handleChangeImage(
                                            event,
                                            document
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="space-lg"></div>
                            <button
                              type="submit"
                              className="btn btn-quaternary"
                              disabled={this.props.kycDocument.buttonDisable}
                              onClick={this.handleSubmit}
                            >
                              {this.props.kycDocument.loadingButtonContent !=
                              null
                                ? this.props.kycDocument.loadingButtonContent
                                : "Submit"}
                            </button>
                          </div>
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    "No documents."
                  )}
                </div>
              </div>
            </div>
            <div className="space-center"></div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToPros = (state) => ({
  kycDocument: state.kycDocument,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(KYCDetails);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { sendWithDrawRequestStart } from "../../store/actions/WithDrawAction";
import configuration from "react-global-configuration";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";

const $ = window.$;

const WithdrawModel = (props) => {
  useEffect(() => {
    if (props.bankAccount.loading) props.dispatch(getBankAccountStart());

    $('#withdraw-modal').on('show.bs.modal', function(event) {
      var button = $(event.relatedTarget) // Button that triggered the modal
      var currency_code = button.data('currency_code')
      setInputData((inputData) => ({
        ...inputData,
        ['currency_code']: currency_code,
      }));
    })

  }, []);

  const [amount, setAmount] = useState(0);

  const [inputData, setInputData] = useState({});

  const handleChange = (event) => {
    event.persist();
    setAmount(event.target.value);
    setInputData((inputData) => ({
      ...inputData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(sendWithDrawRequestStart(inputData));
    $("#withdraw-modal").modal("hide");
  };

  return (
    <div
      className="modal fade"
      id="withdraw-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="withdraw-modaltitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="withdraw-modaltitle">
              Withdraw Money
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            className="theme-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="modal-body">
              <p className="text-muted">
                Min withdraw limit is:{" "}
                <b>
                  {configuration.get("configData.user_withdrawals_min_amount") + ' ' + inputData.currency_code}
                </b>
              </p>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">{inputData.currency_code}</span>
                </div>
                <input
                  type="text"
                  className="form-control input-form-1"
                  placeholder="Amount"
                  aria-label="Enter Amount"
                  name="requested_amount"
                  value={inputData.amount}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              {props.bankAccount.loading ? (
                "Loading.."
              ) : props.bankAccount.data.billing_accounts.length > 0 ? (
                <>
                  <div className="form-group">
                    <label htmlFor="cars">Choose Bank Account:</label>

                    <select
                      name="user_billing_account_id"
                      id="cars"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {props.bankAccount.data.billing_accounts.map(
                        (account) => (
                          <option value={account.user_billing_account_id}>
                            {account.nickname
                              ? account.nickname
                              : account.account_holder_name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </>
              ) : (
                "Please add Bank account"
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-quaternary width-120"
                disabled={props.withDraw.buttonDisable}
                onClick={handleSubmit}
              >
                {props.withDraw.loadingButtonContent != null
                  ? props.withDraw.loadingButtonContent
                  : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  withDraw: state.withDraw,
  bankAccount: state.bankAccount.bankAccount,
  htp: state.htp,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WithdrawModel);

import React, { Component } from "react";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import { connect } from "react-redux";

import { displayLogin, displaySignup } from "../../store/actions/CommonAction";
import { useTranslation, Translation } from "react-i18next";

const $ = window.$;

function MyHomeIndex () {
  const { t } = useTranslation('translation');
  return (
  <Translation>
    { (t, { i18n }) =>
       <>
       <section class="sm-padding">
         <div class="banner-sec sm-padding">
           <div class="container">
             <div class="row">
               <div class="col-md-6 resp-sm-mrg-btm">
                 <div class="banner-content">
                   <h1>
                   {t('home.product.c1')}<span class="primary-txt"> {t('home.product.c4')}</span>
                   </h1>
                   <p>
                    {t('home.product.c2')}
                   </p>
                   <p>
                    {t('home.product.c3')}
                   </p>
                 </div>
               </div>
               <div class="col-md-6">
                 <img
                   src={window.location.origin + "/assets/img/confidiapay-home-img-2x540x360.jpg"}
                   class="banner-img"
                 />
               </div>
             </div>
           </div>
         </div>
       </section>

       <section id="features" class="features-sec sm-padding">
         <div class="container">
           <div class="row">
             <div class="col-md-4 resp-sm-mrg-btm">
               <div class="features-box">
                 <div class="features-img">
                   <img
                     src={
                       window.location.origin + "/assets/img/features-1.svg"
                     }
                     class="f-img"
                   />
                 </div>
                 <div class="features-details">
                   <h3>{t('home.features.c1')}</h3>
                   <p>
                   {t('home.features.c2')}
                   </p>
                 </div>
               </div>
             </div>
             <div class="col-md-4 resp-sm-mrg-btm">
               <div class="features-box">
                 <div class="features-img">
                   <img
                     src={
                       window.location.origin + "/assets/img/features-2.svg"
                     }
                     class="f-img"
                   />
                 </div>
                 <div class="features-details">
                   <h3>{t('home.features.c3')}</h3>
                   <p>
                    {t('home.features.c4')}
                   </p>
                 </div>
               </div>
             </div>
             <div class="col-md-4">
               <div class="features-box">
                 <div class="features-img">
                   <img
                     src={
                       window.location.origin + "/assets/img/features-3.svg"
                     }
                     class="f-img"
                   />
                 </div>
                 <div class="features-details">
                   <h3>{t('home.features.c5')}</h3>
                   <p>
                    {t('home.features.c6')} 
                   </p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </section>

       {/* <section id="download-app-sec" class="features-sec sm-padding">
         <div class="container">
           <div class="row align-items-center">
             <div class="col-md-6 resp-sm-mrg-btm">
               <h2 class="title">
                 Simplify your life with  
                 <br />Confidia<span class="text-gray">Pay</span>
               </h2>
               <div class="desc text-center pt3">
                 <p>
                   Download our modern and simple to use mobile apps today.
                 </p>
                 <ul className="download">
                   <li className="mr-2">
                     <a href="https://play.google.com/store/apps/details?id=com.confidiapay.wallet.app&hl=en-TH" target="_blank">
                         <img
                         src={
                           window.location.origin +
                           "/assets/img/download-android.png"
                         }
                       />
                       </a>
                   </li>
                   <li>
                     <a href="https://apps.apple.com/th/app/confidiapay/id6473804441" target="_blank">
                         <img
                         src={
                           window.location.origin +
                           "/assets/img/download_app_store.png"
                         }
                       />
                       </a>
                   </li>
               </ul>
               </div>
             </div>
             <div class="col-md-6 resp-sm-mrg-btm">
               <img
                   src={
                     window.location.origin +
                     "/assets/img/confidiapay-home-img-3x540x363.jpg"
                   }
                />
               </div>
           </div>
         </div>
       </section> */}

       <section class="aboutus-sec sm-padding">
         <div class="container">
           <div class="row">
             <div class="col-md-6">
               <h4 class="title">
               {t('home.aboutus.c1')} 
               </h4>
               <img
                 src={
                   window.location.origin + "/assets/img/title-underline.svg"
                 }
                 class="title-border"
               />
               <div class="desc">
                 <p>
                  {t('home.aboutus.c2')} 
                 </p>
                 <p>
                  {t('home.aboutus.c3')} 
                 </p>
                 <p>
                  {t('home.aboutus.c4')} 
                 </p>
               </div>
             </div>
             <div class="col-md-6">
               <img
                 src={window.location.origin + "/assets/img/confidiapay-home-img-1x540x363.jpg"}
                 class="banner-img"
               />
             </div>
           </div>
         </div>
       </section>

       <section class="aboutus-sec sm-padding">
         <div class="container">
           <div class="row">
             <div class="col-md-5">
               <img
                 src={window.location.origin + "/assets/img/confidiapay-home-img-3x540x360.jpg"}
                 class="banner-img"
               />
             </div>
             <div class="col-md-7">
               <h4 class="title">
                {t('home.aboutus.c5')} 
               </h4>
               <div class="desc">
                 <p>
                  {t('home.aboutus.c6')} 
                 </p>
                 <p>
                 {t('home.aboutus.c7')} 
                 </p>
                 <p>
                  {t('home.aboutus.c8')} 
                 </p>
               </div>
             </div>
           </div>
         </div>
       </section>

       <LoginIndex />
       <RegisterIndex />
       <ForgotPassword />
     </>
    }
  </Translation>
  )
}

class HomeIndex extends Component {
  handleLogin = (event) => {
    event.preventDefault();
    this.props.dispatch(displayLogin());
  };
  handleSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  showSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  render() {
    return (
      < MyHomeIndex />
    );
  }
}

const mapStateToPros = (state) => ({
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeIndex);

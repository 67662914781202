import React from "react";

const CreateInvoiceTableSec = ({
  tableLength,
  getItemsInput,
  invoiceItemInput,
  invoiceItemsArray,
  saveSelectedItem,
  editSelectedItem,
  selectedItem,
  selectedItemInput,
  handleChangeSelectedItem,
  deleteSelectedItem,
  invoiceItemsList,
}) => {
  return (
    <div className="invoice-custom-center-table">
      <form autoComplete="off">
        <table className="table invoice-custom-table table-responsive-sm table-borderless">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Line Total</th>
            </tr>
          </thead>
          <tbody>
            {invoiceItemsList.loading
              ? ""
              : invoiceItemsList.data.data.invoice_items.length > 0
              ? invoiceItemsList.data.data.invoice_items.map((item, i) =>
                  selectedItem != null &&
                  selectedItem == item.generated_invoice_item_id ? (
                    <tr>
                      <td className="input-custom">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Item name"
                            value={selectedItemInput.name}
                            name="name"
                            onChange={handleChangeSelectedItem}
                            autoComplete="off"
                          />
                        </div>
                      </td>
                      <td className="input-custom">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Price"
                            min="0"
                            step="any"
                            value={selectedItemInput.amount}
                            name="amount"
                            onChange={handleChangeSelectedItem}
                            autoComplete="off"
                          />
                        </div>
                      </td>
                      <td className="input-custom">
                        <div className="form-group">
                          <input
                            type="number"
                            min="0"
                            step="any"
                            className="form-control"
                            placeholder="Enter Quantity"
                            value={selectedItemInput.quantity}
                            name="quantity"
                            autoComplete="off"
                            onChange={handleChangeSelectedItem}
                          />
                        </div>
                      </td>
                      <td className="input-custom">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control invoice-90"
                            placeholder="Total"
                            value={
                              selectedItemInput.quantity *
                              selectedItemInput.amount
                            }
                            name="total"
                            disabled={true}
                          />
                          <a href="#" onClick={saveSelectedItem}>
                            <img
                              src={
                                window.location.origin +
                                "/assets/img/tick-invoice.svg"
                              }
                              className="times-t"
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr key={item.generated_invoice_item_id}>
                      <td>{item.name}</td>
                      <td>{item.amount}</td>
                      <td>{item.quantity}</td>
                      <td>
                        <div>
                          {item.total}
                          <a
                            href="#"
                            onClick={(event) => editSelectedItem(event, item)}
                          >
                            <img
                              src={
                                window.location.origin + "/assets/img/pen.svg"
                              }
                              className="times-e"
                            />
                          </a>

                          <a
                            href="#"
                            onClick={(event) => deleteSelectedItem(event, item)}
                          >
                            <img
                              src={
                                window.location.origin +
                                "/assets/img/remove.svg"
                              }
                              className="times-d"
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  )
                )
              : null}
            <tr>
              <td className="input-custom">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Item name"
                    value={invoiceItemInput.name}
                    name="name"
                    onChange={getItemsInput}
                    autoComplete="off"
                  />
                </div>
              </td>
              <td className="input-custom">
                <div className="form-group">
                  <input
                    type="number"
                    min="0"
                    step="any"
                    className="form-control"
                    placeholder="Enter Price"
                    value={invoiceItemInput.amount}
                    name="amount"
                    onChange={getItemsInput}
                    autoComplete="off"
                  />
                </div>
              </td>
              <td className="input-custom">
                <div className="form-group">
                  <input
                    type="number"
                    min="0"
                    step="any"
                    className="form-control"
                    placeholder="Enter Quantity"
                    value={invoiceItemInput.quantity}
                    name="quantity"
                    onChange={getItemsInput}
                    autoComplete="off"
                  />
                </div>
              </td>
              <td className="input-custom">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Total"
                    value={invoiceItemInput.quantity * invoiceItemInput.amount}
                    name="total"
                    onChange={getItemsInput}
                  />
                </div>
              </td>
            </tr>
            {/* ))} */}
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default CreateInvoiceTableSec;

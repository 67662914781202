import React from "react";

const NotFoundIndex = () => {
  return (
    <>
      <div className="main-wrapper xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="no-result-found">
                <img
                  src={window.location.origin + "/assets/img/no-data-found.svg"}
                  className="full-img"
                />
                <h3 className="no-result-found-txt">Not Found</h3>
              </div>
              <div class="space-50"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundIndex;

import React, { Component } from "react";
import RCOpenedCase from "./RCOpenedCase";
import RCClosedCase from "./RCClosedCase";
import { connect } from "react-redux";
import {
  fetchDisputesStart,
  fetchClosedCaseStart,
  cancelDisputeRequestStart,
  fetchOpenedCaseStart,
} from "../../store/actions/DisputeAction";

class ResolutionCenterIndex extends Component {
  state = {};
  componentDidMount() {
    if (this.props.dispute.openedCases.loading)
      this.props.dispatch(fetchOpenedCaseStart());
  }

  fetchclosedCases = (event) => {
    if (this.props.dispute.closedCase.loading)
      this.props.dispatch(fetchClosedCaseStart());
  };

  cancelDispute = (event, transaction) => {
    event.preventDefault();
    this.props.dispatch(
      cancelDisputeRequestStart({
        user_dispute_id: transaction.user_dispute_id,
      })
    );
  };

  render() {
    return (
      <div className="main-wrapper transactions xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Resolution Center</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="transactions-box">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className="nav-item nav-link active"
                      id="nav-opened-case-tab"
                      data-toggle="tab"
                      href="#nav-opened-case"
                      role="tab"
                      aria-controls="nav-opened-case"
                      aria-selected="true"
                    >
                      Opened Cases
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-closed-case-tab"
                      data-toggle="tab"
                      href="#nav-closed-case"
                      role="tab"
                      aria-controls="nav-closed-case"
                      aria-selected="false"
                      onClick={this.fetchclosedCases}
                    >
                      Closed Cases
                    </a>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <RCOpenedCase
                    cancelDispute={this.cancelDispute}
                    disputes={this.props.dispute.openedCases}
                  />
                  <RCClosedCase disputes={this.props.dispute.closedCase} />
                </div>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  dispute: state.dispute,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ResolutionCenterIndex);

import {
  CREATE_PAYMENT_REQUEST_START,
  CREATE_PAYMENT_REQUEST_SUCCESS,
  CREATE_PAYMENT_REQUEST_FAILURE,
  CREATE_PAYMENT_REQUEST_CALLBACK_START,
  CREATE_PAYMENT_REQUEST_CALLBACK_SUCCESS,
  CREATE_PAYMENT_REQUEST_CALLBACK_FAILURE,
  FETCH_COUNTRY_LIST_START,
  FETCH_COUNTRY_LIST_SUCCESS,
  FETCH_COUNTRY_LIST_FAILURE
} from "../actions/ActionConstant";

const initialState = {
  paymentData: {
    data: {},
    loading: true,
    error: false,
    successData: {},
  },
  paymentCallback: {
    data: {},
    loading: true,
    error: false,
    successData: {},
  },
  countryList: {
    data: {},
    loading: true,
    error: false,
  },
};

const HTPReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PAYMENT_REQUEST_START:
      return {
        ...state,
        paymentData: {
          data: action.data,
          loading: true,
          error: false,
          successData: {},
        },
      };
    case CREATE_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        paymentData: {
            data: {},
            loading: false,
            error: false,
            successData: action.data,
        },
      };
    case CREATE_PAYMENT_REQUEST_FAILURE:
        return {
          ...state,
          paymentData: {
            data: {},
            loading: true,
            error: action.error,
            successData: {},
          },
        };
    case CREATE_PAYMENT_REQUEST_CALLBACK_START:
      return {
        ...state,
        paymentCallback: {
          data: action.data,
          loading: true,
          error: false,
          successData: {},
        },
      };
    case CREATE_PAYMENT_REQUEST_CALLBACK_SUCCESS:
      return {
        ...state,
        paymentCallback: {
            data: {},
            loading: false,
            error: false,
            successData: action.data,
        },
      };
    case CREATE_PAYMENT_REQUEST_CALLBACK_FAILURE:
      return {
        ...state,
        paymentCallback: {
          data: {},
          loading: true,
          error: action.error,
          successData: {},
        },
      };
    case FETCH_COUNTRY_LIST_START:
      return {
        ...state,
        countryList: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: {
          data: action.data.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_COUNTRY_LIST_FAILURE:
    return {
      ...state,
      countryState: {
        data: {},
        loading: true,
        error: action.error,
      }
    };
      default:
      return state;
  }
};

export default HTPReducer;

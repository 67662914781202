import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  notificationStatusUpdateStart,
  fetchUserDetailsStart,
} from "../../store/actions/UserAction";

class Settings extends Component {
  componentDidMount() {
    if (this.props.profile.loading)
      this.props.dispatch(fetchUserDetailsStart());
  }

  notificationStatusUpdate = (event) => {
    if (event.currentTarget.checked)
      this.props.dispatch(notificationStatusUpdateStart({ status: 1 }));
    else this.props.dispatch(notificationStatusUpdateStart({ status: 0 }));
  };

  render() {
    return (
      <div className="main-wrapper xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Settings</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 resp-mrg-btm">
              <div className="settings-box-sec">
                <h4 className="title">Account Settings</h4>
                <ul className="list-unstyled settings-list">
                  <li>
                    <Link to={"/verify-kyc"}>
                      <span className="m-r-5">
                        <i className="fas fa-check-circle"></i>
                      </span>
                      KYC Documents
                    </Link>
                  </li>
                  <li>
                    <Link to={"/cards"}>
                      <span className="m-r-5">
                        <i className="fas fa-credit-card"></i>
                      </span>
                      Payment Methods
                    </Link>
                  </li>
                  <li>
                    <Link to={"/billing-account-list"}>
                      <span className="m-r-5">
                        <i className="fas fa-money-check"></i>
                      </span>
                      Manage Bank Account
                    </Link>
                  </li>
                  <li>
                    <Link to={"/edit-profile"}>
                      <span className="m-r-5">
                        <i className="fas fa-edit"></i>
                      </span>{" "}
                      Update Profile
                    </Link>
                  </li>
                  <li>
                    <Link to={"/change-password"}>
                      <span className="m-r-5">
                        <i className="fas fa-key"></i>
                      </span>{" "}
                      Change Password
                    </Link>
                  </li>
                  <li>
                    <Link to={"/delete-account"}>
                      <span className="m-r-5">
                        <i className="fas fa-trash"></i>
                      </span>{" "}
                      Delete Account
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="settings-box-sec">
                <h4 className="title">Security Settings</h4>
                <ul className="list-unstyled settings-list">
                  <li>
                    <a href="#" className="flex-sapce-btw">
                      Notification Preferances
                      {this.props.profile.loading ? (
                        "Loading..."
                      ) : (
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitch1"
                            defaultChecked={
                              this.props.profile.data.is_notification == 1
                                ? true
                                : false
                            }
                            onChange={this.notificationStatusUpdate}
                          />

                          <label
                            className="custom-control-label"
                            for="customSwitch1"
                          ></label>
                        </div>
                      )}
                    </a>
                    <p className="desc">
                      Get push notification to find out what's going on when
                      you're not on Ledsend. You Can trun them off anytime.
                    </p>
                  </li>
                  <li>
                    <Link to={`/page/help`}>
                      <span className="m-r-5">
                        <i className="fas fa-question"></i>
                      </span>{" "}
                      Help and Support
                    </Link>
                  </li>
                  <li>
                    <Link to={`/page/privacy`}>
                      <span className="m-r-5">
                        <i className="fas fa-shield-alt"></i>
                      </span>{" "}
                      Privacy & Saftey
                    </Link>
                  </li>
                  <li>
                    <Link to={`/page/terms`}>
                      <span className="m-r-5">
                        <i className="fas fa-lock"></i>
                      </span>{" "}
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  notification: state.users.notificationUpdate,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Settings);

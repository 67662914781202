import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchDepositTransactionStart } from "../../store/actions/TransactionAction";
import { Link } from "react-router-dom";
import EmptyData from "../Handler/EmptyData";

const WalletDepositSec = (props) => {
  useEffect(() => {
    if (props.transaction.depositTrans.loading)
      props.dispatch(fetchDepositTransactionStart());
  }, []);

  const { depositTrans } = props.transaction;
  return (
    <div className="col-md-6 full-mg-width res-md-mrg-btm">
      <div className="w-box">
        <h3 className="sub-title">Deposit</h3>
        {depositTrans.loading ? (
          "Loading..."
        ) : depositTrans.data.history.length > 0 ? (
          <>
            {" "}
            {depositTrans.data.history.map((transaction, i) => (
              <div className="flex-data border-bottom mt-4">
                <div className="deposit-details">
                  <div className="user-img">
                    <img src={transaction.wallet_picture} />
                  </div>
                  <div className="user-details">
                    <h5 className="name">
                      #<b>{transaction.user_wallet_payment_unique_id}</b>
                    </h5>
                    <p className="desc">{transaction.message}</p>
                  </div>
                </div>
                <div className="deposit-amount">
                  <p className="positive-amount">
                    {transaction.paid_amount_formatted}
                  </p>
                </div>
              </div>
            ))}{" "}
            <div className="view-more-link">
              <Link to={"/transaction"} className="link">
                View More<i className="fas fa-chevron-right ml-1"></i>
              </Link>
            </div>
          </>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  transaction: state.transaction,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletDepositSec);

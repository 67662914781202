import React from "react";

const EmptyDataTable = () => {
  return (
    <div className="no-data-found-table-sec">
      <img
        src={window.location.origin + "/assets/img/no-data-found.svg"}
        className="no-data-found-table"
      />
      <p className="title">No results Found</p>
    </div>
  );
};

export default EmptyDataTable;

import React, { Component } from "react";
import LoginIndex from "../../Auth/LoginIndex";
import RegisterIndex from "../../Auth/RegisterIndex";
import ForgotPassword from "../../Auth/ForgotPassword";
import { connect } from "react-redux";
import { useTranslation, Translation } from "react-i18next";
import { displayLogin, displaySignup } from "../../../store/actions/CommonAction";

const $ = window.$;

function MyPrivacyIndex () {
  const { t } = useTranslation('translation');
  return (
      <Translation>
        { (t, { i18n }) =>
          <>
        <section class="page-header sm-padding">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h1 class="title">{t("page.privacy.h1")}</h1>
                </div>
              </div>
            </div>
          </section>
          <section class="page-content">
            <div class="container">
              <div class="row">
                <div class="col-md-12 resp-sm-mrg-btm">
                  <h4>{t("page.privacy.section1.p1")}</h4>
                  <p>{t("page.privacy.section1.p2")}</p>
                    <p>{t("page.privacy.section1.p3")}</p>
                    <p>{t("page.privacy.section1.p4")}</p>
                    <h5>{t("page.privacy.section1.p5")}</h5>
                    <p>{t("page.privacy.section1.p6")}</p>
                    <p>{t("page.privacy.section1.p7")}</p>
                    <p>{t("page.privacy.section1.p8")}</p>
                    <h5>{t("page.privacy.section1.p9")}</h5>
                    <p>{t("page.privacy.section1.p10")}</p>
                    <p>{t("page.privacy.section1.p11")}</p>
                    <p>{t("page.privacy.section1.p12")}</p>
                    <p>{t("page.privacy.section1.p13")}</p>
                    <p>{t("page.privacy.section1.p14")}</p>
                    <p>{t("page.privacy.section1.p15")}</p>
                    <h4>{t("page.privacy.section1.p16")}</h4>
                    <p>{t("page.privacy.section1.p17")}</p>
                    <p>{t("page.privacy.section1.p18")}</p>
                    <h4>{t("page.privacy.section1.p19")}</h4>
                    <p>{t("page.privacy.section1.p20")}</p>
                    <h4>{t("page.privacy.section1.p22")}</h4>
                    <p>{t("page.privacy.section1.p23")}</p>
                    <p>{t("page.privacy.section1.p24")}</p>
                    <p>{t("page.privacy.section1.p25")}</p>
                    <p>{t("page.privacy.section1.p26")}</p>
                    <p>{t("page.privacy.section1.p27")}</p>
                    <p>{t("page.privacy.section1.p28")}</p>
                    <p>{t("page.privacy.section1.p29")}</p>
                    <p>{t("page.privacy.section1.p30")}</p>
                    <p>{t("page.privacy.section1.p31")}</p>
                    <p>{t("page.privacy.section1.p32")}</p>
                    <p>{t("page.privacy.section1.p33")}</p>
                    <p>{t("page.privacy.section1.p34")}</p>
                    <p>{t("page.privacy.section1.p35")}</p>
                    <p>{t("page.privacy.section1.p36")}</p>
                    <h4>{t("page.privacy.section1.p37")}</h4>
                    <p>{t("page.privacy.section1.p38")}</p>
                    <p>{t("page.privacy.section1.p39")}</p>
                    <p>{t("page.privacy.section1.p40")}</p>
                    <p>{t("page.privacy.section1.p41")}</p>
                    <p>{t("page.privacy.section1.p42")}</p>
                    <p>{t("page.privacy.section1.p43")}</p>
                    <p>{t("page.privacy.section1.p44")}</p>
                    <p>{t("page.privacy.section1.p45")}</p>
                    <p>{t("page.privacy.section1.p46")}</p>
                    <p>{t("page.privacy.section1.p47")}</p>
                    <p>{t("page.privacy.section1.p48")}</p>
                    <p>{t("page.privacy.section1.p49")}</p>
                    <p>{t("page.privacy.section1.p50")}</p>
                    <p>{t("page.privacy.section1.p51")}</p>
                    <p>{t("page.privacy.section1.p52")}</p>
                    <p>{t("page.privacy.section1.p53")}</p>
                    <h4>{t("page.privacy.section1.p54")}</h4>
                    <p>{t("page.privacy.section1.p55")}</p>
                    <p>{t("page.privacy.section1.p56")}</p>
                    <p>{t("page.privacy.section1.p57")}</p>
                    <p>{t("page.privacy.section1.p58")}</p>
                    <p>{t("page.privacy.section1.p59")}</p>
                    <p>{t("page.privacy.section1.p60")}</p>
                    <p>{t("page.privacy.section1.p61")}</p>
                    <h4>{t("page.privacy.section1.p62")}</h4>
                    <p>{t("page.privacy.section1.p63")}</p>
                </div>
              </div>
              <div className="row">
                  <div className="col-12">
                    <table>
                      <tbody>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p1")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p2")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p3")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p4")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p5")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p6")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p7")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p8")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p9")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p10")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p11")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p12")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p13")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p14")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p15")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p16")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p17")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p18")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p19")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p20")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p21")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p22")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p23")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p24")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p25")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p26")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p27")}</p>
                      </td>
                      </tr>
                      <tr>
                      <td>
                      <p>{t("page.privacy.section2.p28")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p29")}</p>
                      </td>
                      <td>
                      <p>{t("page.privacy.section2.p30")}</p>
                      </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4>{t("page.privacy.section3.p1")}</h4>
                  <p>{t("page.privacy.section3.p2")}</p>
                  <h4>{t("page.privacy.section3.p3")}</h4>
                  <p>{t("page.privacy.section3.p4")}</p>
                  <h4>{t("page.privacy.section3.p5")}</h4>
                  <p>{t("page.privacy.section3.p6")}</p>
                  <h4>{t("page.privacy.section3.p7")}</h4>
                  <p>{t("page.privacy.section3.p8")}</p>
                  <h4>{t("page.privacy.section3.p9")}</h4>
                  <p>{t("page.privacy.section3.p10")}</p>
                  <p>{t("page.privacy.section3.p11")}</p>
                  <p>{t("page.privacy.section3.p12")}</p>
                  <h4>{t("page.privacy.section3.p13")}</h4>
                  <p>{t("page.privacy.section3.p14")}</p>
                  <p>{t("page.privacy.section3.p15")}</p>
                  <p>{t("page.privacy.section3.p16")}</p>
                  <p>{t("page.privacy.section3.p17")}</p>
                  <p>{t("page.privacy.section3.p18")}</p>
                  <h4>{t("page.privacy.section3.p19")}</h4>
                  <p>{t("page.privacy.section3.p20")}</p>
                  <p>{t("page.privacy.section3.p21")}</p>
                  <p>{t("page.privacy.section3.p22")}</p>
                  <h4>{t("page.privacy.section3.p23")}</h4>
                  <p>{t("page.privacy.section3.p24")}</p>
                  <p>{t("page.privacy.section3.p25")}</p>
                  <h4>{t("page.privacy.section3.p26")}</h4>
                  <h4>{t("page.privacy.section3.p27")}</h4>
                  <p>{t("page.privacy.section3.p28")}</p>
                  <p>{t("page.privacy.section3.p29")}</p>
                  <p>{t("page.privacy.section3.p30")}</p>
                  <p>{t("page.privacy.section3.p31")}</p>
                  <h4>{t("page.privacy.section3.p32")}</h4>
                  <p>{t("page.privacy.section3.p33")}</p>
                  <ol>
                    <li>{t("page.privacy.section3.p34")}</li>
                    <li>{t("page.privacy.section3.p35")}</li>
                    <li>{t("page.privacy.section3.p36")}</li>
                    <li>{t("page.privacy.section3.p37")}</li>
                    <li>{t("page.privacy.section3.p38")}</li>
                    <li>{t("page.privacy.section3.p39")}</li>
                    <li>{t("page.privacy.section3.p40")}</li>
                  </ol>
                  <p>{t("page.privacy.section3.p41")}</p>
                  <p>{t("page.privacy.section3.p42")}</p>
                  <h4>{t("page.privacy.section3.p43")}</h4>
                  <p>{t("page.privacy.section3.p44")}</p>
                  <h4>{t("page.privacy.section3.p45")}</h4>
                  <p>{t("page.privacy.section3.p46")}</p>
                  <h4>{t("page.privacy.section3.p47")}</h4>
                  <p>{t("page.privacy.section3.p48")}</p>
                  <h4>{t("page.privacy.section3.p49")}</h4>
                  <h3>{t("page.privacy.section3.p50")}</h3>
                  <p>{t("page.privacy.section3.p51")}</p>
                  <p>{t("page.privacy.section3.p52")}</p>
                  <p>{t("page.privacy.section3.p53")}</p>
                  <h3>{t("page.privacy.section3.p54")}</h3>
                  <p>{t("page.privacy.section3.p55")}</p>
                  <ol>
                    <li>{t("page.privacy.section3.p56")}</li>
                    <li>{t("page.privacy.section3.p57")}</li>
                    <li>{t("page.privacy.section3.p58")}</li>
                  </ol>
                  <h4>{t("page.privacy.section3.p59")}</h4>
                  <p>{t("page.privacy.section3.p60")}</p>
                  <p>{t("page.privacy.section3.p61")}</p>
                  <p>{t("page.privacy.section3.p62")}</p>
                  <p>{t("page.privacy.section3.p63")}</p>
                  <p>{t("page.privacy.section3.p64")}</p>
                  <p>{t("page.privacy.section3.p65")}</p>
                  <p>{t("page.privacy.section3.p66")}</p>
                  <p>{t("page.privacy.section3.p67")}</p>
                </div>
              </div>
            </div>
          </section>
          </>
        }
      </Translation>

  )
}
class PrivacyIndex extends Component {
  handleLogin = (event) => {
    event.preventDefault();
    this.props.dispatch(displayLogin());
  };
  handleSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  showSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  render() {
    return (
      <>
        <MyPrivacyIndex />
        <LoginIndex />
        <RegisterIndex />
        <ForgotPassword />
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PrivacyIndex);

import React from "react";
import { Link } from "react-router-dom";
const RCOpenedCase = (props) => {
  const disputes = props.disputes;
  return (
    <div
      className="tab-pane fade show active"
      id="nav-opened-case"
      role="tabpanel"
      aria-labelledby="nav-opened-case-tab"
    >
      <div className="transactions-table">
        <div className="transactions-table-header flex-data">
          <h3 className="title">Opened Cases</h3>
          <form autoComplete="off">
            <div className="input-group">
              <input
                type="text"
                className="form-control input-form"
                placeholder="Search"
                autoComplete="off"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </form>
        </div>
        <table className="table trans-table table-responsive-sm table-borderless">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Date</th>
              <th scope="col">Case ID</th>
              <th scope="col">Recepient</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {disputes.loading ? (
              "Loading..."
            ) : disputes.data.data.user_disputes.length > 0 ? (
              disputes.data.data.user_disputes.map((dispute, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td className="date">{dispute.updated_at_formatted}</td>
                  <td className="id">{dispute.user_dispute_unique_id}</td>
                  <td>
                    <div className="trans-details">
                      <div className="trans-img">
                        <img
                          src={dispute.user_picture}
                          className="user-img"
                          alt={dispute.username}
                        />
                      </div>
                      <div className="user-details">
                        <h5 className="name">{dispute.username}</h5>
                        <p className="desc">{dispute.status_formatted}</p>
                      </div>
                    </div>
                  </td>
                  <td className="positive-amount">
                    {dispute.amount_formatted}
                  </td>
                  <td>
                    <p>
                      <Link to={`/dispute-case/${dispute.user_dispute_id}`}>
                        <p className="dispute">View</p>
                      </Link>
                    </p>
                    {dispute.cancel_btn_status === 1 ? (
                      <p>
                        <a
                          href="#"
                          onClick={(event) =>
                            props.cancelDispute(event, dispute)
                          }
                        >
                          <p className="dispute">Cancel</p>
                        </a>
                      </p>
                    ) : null}
                  </td>
                </tr>
              ))
            ) : (
              <div className="no-data-found-table-sec">
                <img
                  src={window.location.origin + "/assets/img/no-data-found.svg"}
                  className="no-data-found-table"
                />
                <p className="title">No results Found</p>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RCOpenedCase;

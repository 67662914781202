import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Welcome extends Component {
  render() {
    return (
      <div className="main-wrapper welcome xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="welcome-banner-sec">
                <div className="row">
                  <div className="col-md-6">
                    <div className="banner-content">
                      <h2 className="banner-title">
                        Welcome {localStorage.getItem("username")}.,
                      </h2>
                      <h4 className="banner-subtitle">
                        Accept and Receive money from anyone and anywhere,
                      </h4>
                      <p className="banner-desc">
                        Your brand new account is ready to go. Discover more
                        from your simpler, safer and speedier way to pay online,
                        on mobile and in person.
                      </p>
                      <Link to={"/verify-kyc"}>
                        <button className="btn btn-primary">Send Money</button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={
                        window.location.origin +
                        "/assets/img/welcome-banner.svg"
                      }
                      className="full-img resp-pt-50"
                    />
                  </div>
                </div>
              </div>
              <div className="welcome-call-to-action">
                <div className="row">
                  <div className="col-md-12">
                    <div className="action-sec">
                      <h6 className="title">
                        Update your KYC documents and enjoy the money transfers
                      </h6>
                      <Link to={"/verify-kyc"}>
                        <button className="btn btn-primary">Click Here</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="welcome-features-sec">
                <div className="row">
                  <div className="col-md-3 resp-mrg-btm-rem">
                    <div className="welcome-feature-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/img/welcome-visitor.svg"
                        }
                        className="welcome-feature-img"
                      />
                      <h4 className="title">3993255</h4>
                      <p className="desc">Visitors</p>
                    </div>
                  </div>
                  <div className="col-md-3 resp-mrg-btm-rem">
                    <div className="welcome-feature-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/img/welcome-avg_limit.svg"
                        }
                        className="welcome-feature-img"
                      />
                      <h4 className="title">798668</h4>
                      <p className="desc">Borrowers</p>
                    </div>
                  </div>
                  <div className="col-md-3 resp-mrg-btm-rem">
                    <div className="welcome-feature-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/img/welcome-time.svg"
                        }
                        className="welcome-feature-img"
                      />
                      <h4 className="title">10 min</h4>
                      <p className="desc">Average Credit Time</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="welcome-feature-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/img/welcome-money.svg"
                        }
                        className="welcome-feature-img"
                      />
                      <h4 className="title">4445.9 Million</h4>
                      <p className="desc">Loan Disbursed (INR)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="welcome-feature-sub">
                <div className="row">
                  <div className="col-md-12">
                    <div className="flex-feature-sub">
                      <h5>Loan on the go </h5>
                      <h5>Hard copy documentation not required</h5>
                      <h5>Get instantly</h5>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="welcome-steps-followed">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3 className="main-title">Follow simple steps</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 resp-mrg-btm-rem">
                    <div className="feature-sub-box">
                      <div className="feature-icons">
                        <img
                          src={window.location.origin + "/assets/img/step1.svg"}
                          className="welcome-feature-steps-img"
                        />
                      </div>
                      <div className="feature-steps-content">
                        <h4 className="title">Paperless Registration</h4>
                        <p className="desc">
                          Modern life can come with a lot of problems, but we
                          make sure our paperless registration is not one. Login
                          to our instant loan app to avail loan effortlessly.
                        </p>
                        <button className="btn btn-primary">Apply</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 resp-mrg-btm-rem">
                    <div className="feature-sub-box">
                      <div className="feature-icons">
                        <img
                          src={
                            window.location.origin + "/assets/img/step-2.svg"
                          }
                          className="welcome-feature-steps-img"
                        />
                      </div>
                      <div className="feature-steps-content">
                        <h4 className="title">Quick Approval</h4>
                        <p className="desc">
                          Onboarding you is a pleasure for us. With our AI-Based
                          algorithm, get your desired amount approved and
                          credited in your bank account in 1 hour without any
                          document.
                        </p>
                        <button className="btn btn-primary">Apply</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="feature-sub-box">
                      <div className="feature-icons">
                        <img
                          src={
                            window.location.origin + "/assets/img/step-3.svg"
                          }
                          className="welcome-feature-steps-img"
                        />
                      </div>
                      <div className="feature-steps-content">
                        <h4 className="title">Instant Fund Transfer</h4>
                        <p className="desc">
                          We can’t wait to see you enjoying with the instant
                          money you’ve got. Count on us and we are there. Leave
                          some of your amazing messages on our social networking
                          channels.
                        </p>
                        <button className="btn btn-primary">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;

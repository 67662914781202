import React, { Component } from "react";
import { connect } from "react-redux";
import AllInvoiceSec from "./AllInvoiceSec";
import DraftInvoiceSec from "./DraftInvoiceSec";
import ScheduleInvoiceSec from "./ScheduleInvoiceSec";
import PaidInvoiceSec from "./PaidInvoiceSec";
import UnpaidInvoiceSec from "./UnpaidInvoiceSec";
import {
  fetchInvoiceDraftStart,
  fetchInvoicePaidStart,
  fetchInvoiceUnpaidStart,
  fetchInvoiceHistoryStart,
  fetchInvoiceReceivedStart,
} from "../../../store/actions/ManageInvoiceAction";
import { Link } from "react-router-dom";
import ReceivedInvoiceSec from "./ReceivedInvoiceSec";
import { fetchUserDetailsStart } from "../../../store/actions/UserAction";

class ManageInvoiceIndex extends Component {
  state = {};

  componentDidMount() {
    if (this.props.invoice.invoiceHistory.loading)
      this.props.dispatch(fetchInvoiceHistoryStart());
    if (this.props.profile.loading) {
      this.props.dispatch(fetchUserDetailsStart());
    }
  }
  getUnpaidInvoice = () => {
    if (this.props.invoice.invoiceUnpaid.loading)
      this.props.dispatch(fetchInvoiceUnpaidStart());
  };

  getPaidInvoiceDetails = () => {
    if (this.props.invoice.invoicePaid.loading)
      this.props.dispatch(fetchInvoicePaidStart());
  };

  getDraftInvoice = () => {
    if (this.props.invoice.invoiceDraft.loading)
      this.props.dispatch(fetchInvoiceDraftStart());
  };

  getReceivedInvoice = () => {
    this.props.dispatch(fetchInvoiceReceivedStart());
  };

  render() {
    return (
      <div className="main-wrapper transactions manage-invoice-sec xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Manage Invoices</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="transactions-box">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className="nav-item nav-link active"
                      id="nav-all-invoice-tab"
                      data-toggle="tab"
                      href="#nav-all-invoice"
                      role="tab"
                      aria-controls="nav-all-invoice"
                      aria-selected="true"
                    >
                      All Invoices
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-draft-invoice-tab"
                      data-toggle="tab"
                      href="#nav-draft-invoice"
                      role="tab"
                      aria-controls="nav-draft-invoice"
                      aria-selected="false"
                      onClick={this.getDraftInvoice}
                    >
                      Drafts
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-paid-invoice-tab"
                      data-toggle="tab"
                      href="#nav-paid-invoice"
                      role="tab"
                      aria-controls="nav-paid-invoice"
                      aria-selected="false"
                      onClick={this.getPaidInvoiceDetails}
                    >
                      Paid Invoice
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-unpaid-invoice-tab"
                      data-toggle="tab"
                      href="#nav-unpaid-invoice"
                      role="tab"
                      aria-controls="nav-unpaid-invoice"
                      aria-selected="false"
                      onClick={this.getUnpaidInvoice}
                    >
                      Unpaid Invoice
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-received-invoice-tab"
                      data-toggle="tab"
                      href="#nav-received-invoice"
                      role="tab"
                      aria-controls="nav-received-invoice"
                      aria-selected="false"
                      onClick={this.getReceivedInvoice}
                    >
                      Received Invoice
                    </a>
                    {/* <a
                      className="nav-item nav-link"
                      id="nav-scheduled-invoice-tab"
                      data-toggle="tab"
                      href="#nav-scheduled-invoice"
                      role="tab"
                      aria-controls="nav-scheduled-invoice"
                      aria-selected="false"
                    >
                      Schedule Invoice
                    </a> */}
                    {this.props.profile.loading ? (
                      "Loading..."
                    ) : this.props.profile.data.is_kyc_document_approved ==
                      2 ? (
                      <Link
                        to={"/create-invoice"}
                        class="btn btn-quaternary width-160 pull-right right-align"
                      >
                        Create Invoice
                      </Link>
                    ) : (
                      <>
                        <button
                          disabled={true}
                          className="btn btn-quaternary width-160 pull-right right-align"
                        >
                          Create Invoice
                        </button>
                        <p className="pull-right right-align ">
                          NOTE: You will able to create invoice only after the
                          KYC verficaiton
                        </p>
                      </>
                    )}
                  </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                  <AllInvoiceSec />
                  <DraftInvoiceSec />
                  <ScheduleInvoiceSec />
                  <PaidInvoiceSec />
                  <UnpaidInvoiceSec />
                  <ReceivedInvoiceSec />
                </div>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
      </div>
    );
  }
}
const mapStateToPros = (state) => ({
  invoice: state.invoice,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ManageInvoiceIndex);

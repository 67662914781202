import React, { Component } from "react";
import { connect } from "react-redux";

class SubscriptionInvoiceNew extends Component {
  render() {
    return (
      <div className="main-wrapper subscription-invoice-new xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Subscription Invoice</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="invoice-new-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="user-details">
                      <div className="user-img">
                        <img
                          src={
                            window.location.origin +
                            "/assets/img/profile-img.jpg"
                          }
                          className="suscription-user-img"
                        />
                      </div>
                      <div className="invoice-content">
                        <h3 className="invoice-head-txt">Title</h3>
                        <p className="invoice-desc-txt">Description</p>
                      </div>
                    </div>
                    <div className="type-of-subscription">
                      <h3 className="invoice-sub-desc-txt">
                        <b>Type of Subscription -</b>
                        <span>Recurring Payment</span>
                      </h3>
                    </div>
                    <form className="theme-form" autoComplete="off">
                      <label for="payment-types" className="invoice-label">
                        Choose Payment Mode
                      </label>
                      <input
                        list="payment-type"
                        className="form-control input-form mb-3"
                        id="payment-types"
                        autoComplete="off"
                      />
                      <datalist id="payment-type">
                        <option value="Card" />
                        <option value="PayPal" />
                      </datalist>
                      <label className="invoice-label">Coupon</label>
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          className="form-control input-form-1"
                          id="coupon"
                          placeholder="Username"
                          aria-label="Enter Amount"
                          autoComplete="off"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">Apply</span>
                        </div>
                      </div>
                    </form>
                    <div className="invoice-price-info">
                      <div class="total-amount-display">
                        <table class="table table-responsive-sm table-borderless">
                          <tbody>
                            <tr>
                              <td class="desc">Subtotal</td>
                              <td class="desc">$105.00</td>
                            </tr>
                            <tr>
                              <td class="title">Total</td>
                              <td class="title">$105.00</td>
                            </tr>
                            <tr>
                              <td class="title">Amout Paid</td>
                              <td class="title">$105.00</td>
                            </tr>
                            <tr>
                              <td class="title-main">Amount Due</td>
                              <td class="title-main">$105.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="resp-align-center">
                      <button className="btn btn-quaternary">Pay Now</button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={
                        window.location.origin +
                        "/assets/img/invoice-subscription.svg"
                      }
                      className="invoice-full-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionInvoiceNew;

import React from "react";

const CreateInvoiceRightSec = ({ getInvoiceInput, invoiceInput }) => {
  return (
    <div className="col-md-7">
      <div className="invoice-right-table-header mb-2">
        <h4 className="template-head">Invoice Number</h4>
        <div className="form-group">
          <input
            type="text"
            className="form-control input-form"
            placeholder="Ex:4N76934"
            value={invoiceInput.invoice_number}
            name="invoice_number"
            onChange={getInvoiceInput}
          />
        </div>
      </div>
      <div className="invoice-right-table-header mb-2">
        <h4 className="template-head">Invoice Date</h4>
        <div className="input-group width-35">
          <input
            type="date"
            className="form-control input-form-1"
            placeholder="11/04/2019"
            value={invoiceInput.invoice_date}
            name="invoice_date"
            onChange={getInvoiceInput}
          />
        </div>
      </div>
      <div className="invoice-right-table-header mb-2">
        <h4 className="template-head">Reference</h4>
        <div className="form-group">
          <input
            type="text"
            className="form-control input-form"
            placeholder="For Example, PO#"
            value={invoiceInput.reference}
            name="reference"
            onChange={getInvoiceInput}
          />
        </div>
      </div>
      <div className="invoice-right-table-header mb-2">
        <h4 className="template-head">Due Date</h4>
        <div className="input-group width-35">
          <input
            type="date"
            className="form-control input-form-1"
            placeholder="11/04/2019"
            value={invoiceInput.due_date}
            name="due_date"
            onChange={getInvoiceInput}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateInvoiceRightSec;

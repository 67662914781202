import React, { Component } from "react";
import AccountDetails from "./AccountDetails";
import AddAccountModel from "./AddAccountModel";
import { connect } from "react-redux";
import ProfileLoader from "../../Loader/ProfileLoader";
import { 
  fetchUserDetailsStart, 
} from "../../../store/actions/UserAction";
import { Link } from "react-router-dom";
import {
  getBankAccountData,
  addBankAccountStart,
  getBankAccountStart,
} from "../../../store/actions/BankAccountAction";

import {
  fetchCountryDetailsStart,
} from "../../../store/actions/HTPAction";

const $ = window.$;

class ProfileIndex extends Component {
  state = {
    displayAccountAccountModel: false,
  };
  componentDidMount() {
    if (this.props.userData.profile.loading)
      this.props.dispatch(fetchUserDetailsStart());
      this.props.dispatch(fetchCountryDetailsStart());
  }

  showAddAccount = (event) => {
    this.setState({ displayAccountAccountModel: true });
  };

  handleSubmitBankAccount = (event) => {
    event.preventDefault();
    this.setState({ displayAccountAccountModel: false });
    this.props.dispatch(addBankAccountStart());
    $("#add-account-modal").modal("hide");
  };

  render() {
    const userData  = this.props.userData.profile;
    if (userData.loading === true) {
      return (
        <div className="main-wrapper profile xs-padding">
          <div className="container">
            <div className="row">
              <ProfileLoader />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-wrapper profile xs-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="main-title">Profile</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="profile-box">
                  <div className="row">
                    <div className="col-md-5 full-mg-width res-md-mrg-btm">
                      <div className="profile-details">
                        <div className="profile-img">
                          <img
                            src={userData.data.picture}
                            alt={userData.data.name}
                            className="p-img"
                          />
                        </div>
                        <h2 className="user-name">{userData.data.name}</h2>
                        <h3 className="user-email">{userData.data.email}</h3>
                        <div>
                          <Link
                            to={"/edit-profile"}
                            className="btn btn-quaternary"
                          >
                            Edit Profile
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 full-mg-width">
                      <div className="profile-info">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">Name</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              {userData.data.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info-1">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">Email</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              {userData.data.email}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">Phone Number</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              {userData.data.mobile ? userData.data.mobile : 'N/A'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info-1">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">Address</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              {userData.data.address ? userData.data.address : 'N/A'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">City</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              {userData.data.city ? userData.data.city : 'N/A'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info-1">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">State</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              {userData.data.country_state ? userData.data.country_state : 'N/A'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">Country</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                                {userData.data.country_name ? userData.data.country_name : 'N/A'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info-1">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">Post code</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              {userData.data.postcode ? userData.data.postcode : 'N/A'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">Currency</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              {userData.data.default_currency ? userData.data.default_currency : 'N/A'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="profile-info-1">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="">KYC Status</h5>
                          </div>
                          <div className="col-md-8">
                            <h6>
                              <span className="p-dots">:</span>
                              <span
                                className={
                                  userData.data.is_kyc_document_approved == 2
                                    ? "p-status text-success"
                                    : "p-status"
                                }
                              >
                                {userData.data.kyc_status_formatted}
                              </span>
                              {userData.data.is_kyc_document_approved != 2 ? (
                                <span>
                                  <Link to={"/verify-kyc"} className="u-link">
                                    Update KYC
                                  </Link>
                                </span>
                              ) : (
                                <span>
                                  <Link to={"/view-kyc"} className="u-link">
                                    View KYC
                                  </Link>
                                </span>
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-center space-center-1"></div>
          </div>
          <AddAccountModel
            bankAccount={this.props.bankAccount}
            handleChangeBankAccount={this.handleChangeBankAccount}
            handleSubmitBankAccount={this.handleSubmitBankAccount}
            displayAccountAccountModel={this.state.displayAccountAccountModel}
          />
        </div>
      );
    }
  }
}

const mapStateToPros = (state) => ({
  userData: state.users,
  bankAccount: state.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);

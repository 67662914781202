import {
  SAVE_INVOICE_DETAILS_START,
  SAVE_INVOICE_DETAILS_SUCCESS,
  SAVE_INVOICE_DETAILS_FAILURE,
  SEND_INVOICE_START,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_FAILURE,
  SAVE_INVOICE_ITEMS_START,
  SAVE_INVOICE_ITEMS_SUCCESS,
  SAVE_INVOICE_ITEMS_FAILURE,
  DELETE_INVOICE_ITEMS_START,
  DELETE_INVOICE_ITEMS_SUCCESS,
  DELETE_INVOICE_ITEMS_FAILURE,
  FETCH_SINGLE_INVOICE_DETAILS_START,
  FETCH_SINGLE_INVOICE_DETAILS_SUCCESS,
  FETCH_SINGLE_INVOICE_DETAILS_FAILURE,
  FETCH_INVOICE_ITEMS_START,
  FETCH_INVOICE_ITEMS_SUCCESS,
  FETCH_INVOICE_ITEMS_FAILURE,
  UPDATE_INVOICE_ITEM,
  SAVE_INVOICE_ID,
} from "./ActionConstant";

// save invoice details actions.

export function saveInvoiceDetailsStart(data) {
  return {
    type: SAVE_INVOICE_DETAILS_START,
    data,
  };
}

export function saveInvoiceDetailsSuccess(data) {
  return {
    type: SAVE_INVOICE_DETAILS_SUCCESS,
    data,
  };
}

export function saveInvoiceDetailsFailure(error) {
  return {
    type: SAVE_INVOICE_DETAILS_FAILURE,
    error,
  };
}

export function sendInvoiceStart(data) {
  return {
    type: SEND_INVOICE_START,
    data,
  };
}

export function sendInvoiceSuccess(data) {
  return {
    type: SEND_INVOICE_SUCCESS,
    data,
  };
}

export function sendInvoiceFailure(error) {
  return {
    type: SEND_INVOICE_FAILURE,
    error,
  };
}

export function saveInvoiceItemsStart(data) {
  return {
    type: SAVE_INVOICE_ITEMS_START,
    data,
  };
}

export function saveInvoiceItemsSuccess(data) {
  return {
    type: SAVE_INVOICE_ITEMS_SUCCESS,
    data,
  };
}

export function saveInvoiceItemsFailure(error) {
  return {
    type: SAVE_INVOICE_ITEMS_FAILURE,
    error,
  };
}

export function deleteInvoiceItemsStart(data) {
  return {
    type: DELETE_INVOICE_ITEMS_START,
    data,
  };
}

export function deleteInvoiceItemsSuccess(data) {
  return {
    type: DELETE_INVOICE_ITEMS_SUCCESS,
    data,
  };
}

export function deleteInvoiceItemsFailure(error) {
  return {
    type: DELETE_INVOICE_ITEMS_FAILURE,
    error,
  };
}

export function fetchSingleInvoiceDetailsStart(data) {
  return {
    type: FETCH_SINGLE_INVOICE_DETAILS_START,
    data,
  };
}

export function fetchSingleInvoiceDetailsSuccess(data) {
  return {
    type: FETCH_SINGLE_INVOICE_DETAILS_SUCCESS,
    data,
  };
}

export function fetchSingleInvoiceDetailsFailure(error) {
  return {
    type: FETCH_SINGLE_INVOICE_DETAILS_FAILURE,
    error,
  };
}

export function fetchInvoiceItemStart(data) {
  return {
    type: FETCH_INVOICE_ITEMS_START,
    data,
  };
}

export function fetchInvoiceItemSuccess(data) {
  return {
    type: FETCH_INVOICE_ITEMS_SUCCESS,
    data,
  };
}

export function fetchInvoiceItemFailure(error) {
  return {
    type: FETCH_INVOICE_ITEMS_FAILURE,
    error,
  };
}

export function updateInvoiceItemsArray(index, data) {
  return {
    type: UPDATE_INVOICE_ITEM,
    index,
    data,
  };
}

export function saveInvoiceId(data) {
  return {
    type: SAVE_INVOICE_ID,
    data,
  };
}

import React, { Component } from "react";
import { connect } from "react-redux";
import CreatePaymentLoader from "../../Loader/CreatePaymentLoader";
import {
  
  createPaymentRequestStart
} from "../../../store/actions/HTPAction";

import { fetchUserDetailsStart } from "../../../store/actions/UserAction";

class CreatedPaymentRequestIndex extends Component {
  state = {
    loading: true,
    inputData: {},
  };

  componentDidMount() {
    this.props.dispatch(fetchUserDetailsStart());
  }

  paymentInputData = ({ currentTarget: input }) => {
    const paymentData = { ...this.state.paymentData };
    paymentData[input.name] = input.value;
    this.setState({ paymentData });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    document.getElementById('div_loading').className = 'd-block';
    const paymentData = { ...this.state.paymentData };
    this.props.dispatch(createPaymentRequestStart(this.state.paymentData));
  }

  render() {
   
    const htpPaymentResponse = this.props.htp.paymentData.successData? this.props.htp.paymentData.successData:'';
    const userData  = this.props.userData.profile;
    const default_currency = userData.data.default_currency;

    let paymentLink ='';
    if (htpPaymentResponse.success == true) {
      paymentLink = htpPaymentResponse.data.create_payment.data.payment_link;
      window.location.replace(paymentLink);
    }

    return (
      <div className="main-wrapper add-money xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Add Money</h2>
            </div>
          </div>
              <div className="row">
              <div className="col-md-12">
                <div className="add-money-box">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-xl-7">
                      <div className="row">
                        <div className="col-md-12">
                          <form className="theme-form" autoComplete="off">
                            <h4>
                              <label>Enter Amount</label>
                            </h4>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text">{ default_currency }</span>
                              </div>
                              <input
                                type="text"
                                className="form-control input-form-1"
                                placeholder="Amount"
                                aria-label="Enter Amount"
                                name="amount"
                                autoComplete="off"
                                onChange={this.paymentInputData}
                              />
                            </div>
                            <div id="div_loading" class="d-none">
                              Process Loading.....
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5"></div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-xl-7">
                      <div className="space-lg"></div>
                      <div className="row">
                        <div className="col-md-12 resp-align-center">
                          <button
                            className="btn btn-quaternary width-120 res-md-mrg-btm resp-sm-mrg-btm"
                            onClick={this.handleSubmit}
                          >Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  wallet: state.wallet,
  htp: state.htp,
  userData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreatedPaymentRequestIndex);

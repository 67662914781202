import {
  SAVE_BUSINESS_DETAILS_START,
  SAVE_BUSINESS_DETAILS_SUCCESS,
  SAVE_BUSINESS_DETAILS_FAILURE,
  FETCH_BUSINESS_DETAILS_START,
  FETCH_BUSINESS_DETAILS_SUCCESS,
  FETCH_BUSINESS_DETAILS_FAILURE,
} from "./ActionConstant";

// Get Wallet Details actions.

export function saveBusinessDetailsStart(data) {
  return {
    type: SAVE_BUSINESS_DETAILS_START,
    data,
  };
}

export function saveBusinessDetailsSuccess(data) {
  return {
    type: SAVE_BUSINESS_DETAILS_SUCCESS,
    data,
  };
}

export function saveBusinessDetailsFailure(error) {
  return {
    type: SAVE_BUSINESS_DETAILS_FAILURE,
    error,
  };
}

export function fetchBusinessDetailsStart(data) {
  return {
    type: FETCH_BUSINESS_DETAILS_START,
    data,
  };
}

export function fetchBusinessDetailsSuccess(data) {
  return {
    type: FETCH_BUSINESS_DETAILS_SUCCESS,
    data,
  };
}

export function fetchBusinessDetailsFailure(error) {
  return {
    type: FETCH_BUSINESS_DETAILS_FAILURE,
    error,
  };
}

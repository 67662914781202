import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { displayLogin } from "../../store/actions/CommonAction";
import {
  forgotPasswordStart,
  getForgotPasswordInputData,
} from "../../store/actions/UserAction";
import configuration from "react-global-configuration";
const $ = window.$;

class ForgotPassword extends Component {
  state = {};
  showLogin = (event) => {
    $('#forgotModal').modal('hide');
    this.props.dispatch(displayLogin());
  };
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getForgotPasswordInputData(input.name, input.value));
  };
  handleForgotPasswordSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(forgotPasswordStart());
  };
  render() {
    const inputData = this.props.inputData.forgotPasswordInputData;
    return (
      <div className="modal forgot-password forgot-password-sec" id="forgotModal" role="dialog">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close btn-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className="c-box">
                <div className="row">
                  <div className="col-md-6">
                    <Link to="/" className="l-logo">
                      <img
                        src={configuration.get("configData.site_logo")}
                        alt={configuration.get("configData.site_name")}
                      />
                    </Link>
                    <div className="login-content">
                      <h4 className="title">Forgot Password</h4>
                      <p className="desc">
                        Lorem ipsum suspendisse ultrices gravida. Risus commodo
                        viverra maecenas accumsan
                      </p>
                      <form autoComplete="off">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control input-form"
                            placeholder="Email"
                            autoComplete="off"
                            name="email"
                            value={inputData.data.email}
                            onChange={this.handleChange}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </div>
                        </div>
                      </form>
                      <button
                        className="btn btn-primary mb-3"
                        onClick={this.handleForgotPasswordSubmit}
                        disabled={this.props.inputData.buttonDisable}
                      >
                        {this.props.inputData.loadingButtonContent != null
                          ? this.props.inputData.loadingButtonContent
                          : "Submit"}
                      </button>
                      <h6>
                        Did you remember password?
                        <span>
                          <a href="#" 
                          data-toggle="modal"
                          data-target=".login"
                          onClick={this.showLogin}>
                            Try logging in!
                          </a>
                        </span>
                        <br />
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="login-right-info">
                      <div className="l-main-img-sec">
                        <img
                          src={
                            window.location.origin + "/assets/img/login-img.svg"
                          }
                          className="l-main-img"
                        />
                      </div>
                      <div className="l-bg-img-sec">
                        <img
                          src={
                            window.location.origin + "/assets/img/lock-icon.svg"
                          }
                          className="l-bg-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.users,
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ForgotPassword);

import React, { Component } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import LanguageSelector from "../../LanguageSwitcher/LanguageSwitcher";
import { useTranslation, Translation } from "react-i18next";

import {
  displayLogin,
  displaySignup,
} from "../../../store/actions/CommonAction";

const $ = window.$;

function MyAuthHeader () {
  const { t } = useTranslation('translation');
  return (
  <Translation>
    { (t, { i18n }) =>
      <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
          <div class="col-12">
            <nav className="navbar navbar-expand-lg top-0 position-absolute blur blur-rounded shadow start-0 end-0 my-3 py-2 z-index-fixed ">
              <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                  <img
                    src={configuration.get("configData.site_logo")}
                    className="logo"
                    alt={configuration.get("configData.site_name")}
                  />
                </Link>
                
                <button
                  className="navbar-toggler shadow-none ms-2"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navigation"
                  aria-controls="#navigation"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <span className="navbar-toggler-bar bar1"></span>
                    <span className="navbar-toggler-bar bar2"></span>
                    <span className="navbar-toggler-bar bar3"></span>
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="navigation">
                  <ul className="navbar-nav navbar-nav-hover ms-lg-3 ps-lg-2 ">
                    {localStorage.getItem("userLoginStatus") == "true" ? (
                      <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                          {t('menu.Dashboard')}
                        </Link>
                      </li>
                    ) : (
                      <li className="nav-item dropdown dropdown-hover mx-2 active">
                        <Link className="nav-link" to="/">
                          {t('menu.Product')}
                        </Link>
                      </li>
                    )}
                    <li className="nav-item dropdown dropdown-hover mx-2">
                      <a href="#features" class="nav-link">{t('menu.Features')}</a>
                    </li>
                    <li className="nav-item dropdown dropdown-hover mx-2">
                      <Link className="nav-link" to="/page/about-us">
                        {t('menu.AboutUs')}
                      </Link>
                    </li>
                    <li className="nav-item dropdown dropdown-hover mx-2">
                      <Link className="nav-link" to="/page/contact">
                        {t('menu.Contact')}
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown dropdown-hover mx-2">
                      <Link className="nav-link" to="/page/download">
                        {t('Download')}
                      </Link>
                    </li> */}
                    {/* <li className="nav-item dropdown dropdown-hover mx-2">
                      <Link className="nav-link" to="/page/my-wallet">
                        {t('menu.MyWallet')}
                      </Link>
                    </li> */}
                    < LanguageSelector />
                    {localStorage.getItem("userLoginStatus") == "true" ? (
                      <li className="nav-item">
                        <Link className="nav-link" to="/profile">
                          {t('Welcome')}, {localStorage.getItem("username")}
                        </Link>
                      </li>
                    ) : (
                      <>
                        <li className="nav-item ms-lg-auto">
                          <a
                            className="nav-link nav-link-icon me-2 pl-4"
                            data-toggle="modal"
                            data-target=".login"
                            href="#"
                            // onClick={this.handleLogin}
                          >
                            <p class="d-inline text-sm z-index-1 font-weight-bold pl-5 link-login">{t('menu.Login')}</p>
                          </a>
                        </li>
                        <li className="nav-item my-auto ms-2 ms-lg-0">
                          <a
                            className="btn btn-sm bg-gradient-primary"
                            data-toggle="modal"
                            data-target=".signup"
                            href="#"
                            // onClick={this.handleSignup}
                          >
                            <span class="nav-link-inner--text">{t('menu.Register')}</span>
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    }
  </Translation>
  )
}

class AuthHeader extends Component {

  handleLogin = (event) => {
    event.preventDefault();
    this.props.dispatch(displayLogin());
  };
  handleSignup = (event) => {
    event.preventDefault();
    this.props.dispatch(displaySignup());
  };

  render() {
    return (
        < MyAuthHeader />
                   
    );
  }
}

const mapStateToPros = (state) => ({
  common: state.common,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default  (connect(mapStateToPros, mapDispatchToProps)(AuthHeader));

import React, { Component } from "react";
import { connect } from "react-redux";
import { addBankAccountStart } from "../../../store/actions/BankAccountAction";

class AddBillingAccount extends Component {
  state = {
    inputData: {},
  };

  handleChange = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    inputData[input.name] = input.value;
    this.setState({ inputData });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(addBankAccountStart(this.state.inputData));
  };

  render() {
    const { inputData } = this.state;

    return (
      <div className="main-wrapper change-password xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-title">Add Bank Account</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="change-password-box">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <form
                          className="theme-form cp-box"
                          autoComplete="off"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label>Nick Name</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nick Name"
                                name="nickname"
                                value={inputData.name}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label>Account Name</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Account Holder Name"
                                name="account_holder_name"
                                value={inputData.account_holder_name}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label for="new-pass">Account Number</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                name="account_number"
                                placeholder="Account Number"
                                value={inputData.account_number}
                                className="form-control"
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label for="new-pass">Bank Name</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                name="bank_name"
                                placeholder="Bank Name"
                                value={inputData.title}
                                className="form-control"
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label for="new-pass">IFSC Code</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                name="ifsc_code"
                                placeholder="IFSC Code"
                                value={inputData.ifsc_code}
                                className="form-control"
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form-group row flex-data">
                            <div className="col-md-4">
                              <label for="new-pass">SWIFT Code</label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                name="swift_code"
                                placeholder="Swift Code"
                                value={inputData.swift_code}
                                className="form-control"
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-8 resp-align-center">
                              <button
                                className="btn btn-quaternary"
                                onClick={this.handleSubmit}
                                disabled={this.props.bankAccount.buttonDisable}
                              >
                                {this.props.bankAccount.loadingButtonContent !=
                                null
                                  ? this.props.bankAccount.loadingButtonContent
                                  : "Submit"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      src={
                        window.location.origin + "/assets/img/send-money.svg"
                      }
                      className="full-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-center"></div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddBillingAccount);

import React from "react";
import { Link } from "react-router-dom";

const DashboardInvoiceSec = () => {
  return (
    <div className="col-md-6">
      <div className="dash-box">
        <div className="dash-header">
          <h3>Invoices & Estimate</h3>
          <Link to={"/manage-invoice"} className="link">
            More<i className="fas fa-chevron-right ml-1"></i>
          </Link>
        </div>
        <div className="dash-status">
          <h4>Last 6 Months</h4>
        </div>
        <div className="dash-amount-difference-1">
          <h5>Unpaid Invoice</h5>
          <h6>0</h6>
        </div>
        <div className="dash-amount-difference-1">
          <h5>Draft Invoices</h5>
          <h6>0</h6>
        </div>
        <Link to={"/create-invoice"} className="btn btn-teritory mr-3">
          Create Invoices
        </Link>
        <Link to={"/manage-invoice"} className="btn btn-teritory">
          Manage Invoices
        </Link>
        {/* <hr />
        <p className="conv-desc-1 desc">Send Estimate to grow your business</p>
        <a className="btn btn-quaternary mt-2" href="#">
          Create an Estimate
        </a> */}
      </div>
    </div>
  );
};

export default DashboardInvoiceSec;
